import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '../Link';
import assemblyLogo from '../../../img/logos/assemblyColor@1x.png';
import assemblyLogo3x from '../../../img/logos/assemblyColor@3x.png';
import assemblyLogoWeb1x from '../../../img/logos/Logo_Assembly_Employee_Recognition_1x.webp';
import assemblyLogoWeb3x from '../../../img/logos/Logo_Assembly_Employee_Recognition_3x.webp';
import { APP_NAME } from '../../../Utils/constants';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    border: 'none',
    boxShadow: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  logoStyle: {
    width: 150,
  },
  progress: {
    background: 'transparent',
    marginBottom: '-4px',
    overflow: 'visible',
    '& .MuiLinearProgress-bar': {
      height: '6px',
      borderRadius: '0px 3px 3px 0px',
    },
  },
});

interface NavbarProps {
  slackOnboardStep?: boolean | string;
}

const Navbar = (props: NavbarProps) => {
  const { slackOnboardStep } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div style={{ flex: 1 }}>
            <Link to="/">
              <picture>
                <source
                  srcSet={`${assemblyLogoWeb1x} 1x, ${assemblyLogoWeb3x} 2x`}
                  type="image/webp"
                />
                <img
                  src={`${assemblyLogo}`}
                  srcSet={`${assemblyLogo} 1x, ${assemblyLogo3x} 3x`}
                  alt={APP_NAME}
                  className={classes.logoStyle}
                />
              </picture>
            </Link>
          </div>
        </Toolbar>
        {!!slackOnboardStep && (
          <LinearProgress
            variant="determinate"
            className={classes.progress}
            value={Number(slackOnboardStep)}
          />
        )}
      </AppBar>
    </div>
  );
};

export default Navbar;
