import React from 'react';
import classnames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import ThemeV2 from '../../theme';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    fontFamily: ThemeV2.typography.fontFamily,
    fontWeight: ThemeV2.typography.fontWeightMedium,
    color: ThemeV2.palette.darkGray3,
    lineHeight: '15px',
    fontSize: '11px',
    '&.disabled': {
      opacity: '0.24',
      userSelect: 'none',
    },
    '&.error': {
      color: ThemeV2.palette.red2,
    },
  },
});

interface HelperTextProps {
  disabled?: boolean;
  error?: string;
  children: React.ReactNode;
  id?: string;
}

const HelperText = (props: HelperTextProps) => {
  const { disabled, error, children, id, ...rest } = props;
  const classes = useStyles();
  return (
    <FormHelperText
      className={classnames(
        classes.root,
        disabled ? 'disabled' : '',
        error ? 'error' : '',
      )}
      id={id || 'helperText'}
      {...rest}
    >
      {children}
    </FormHelperText>
  );
};

export default HelperText;
