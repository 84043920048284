import axios from 'axios';
import { API_URL_WITH_V2 } from '../../config';
import { AuthHeaders } from '../shared/HeaderToken';
import { adminActions } from '../Admin';
import history from '../../history';
import {
  showSuccessMessage,
  showInfoMessage,
  showErrorMessage,
} from '../../Utils/flashHandler';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  ACTIVATE_REWARDS_PAYMENT: `${API_URL_WITH_V2}/employer/rewards/setup/activate`,
  DEACTIVATE_REWARDS_PAYMENT: `${API_URL_WITH_V2}/employer/rewards/setup/deactivate`,
  REWARDS_PAYMENT_DETAILS: `${API_URL_WITH_V2}/employer/rewards/setup/details`,
  REWARDS_PAYMENT_SETUP_OVERVIEW: `${API_URL_WITH_V2}/employer/rewards/setup/overview`,
  REWARDS_PAYMENT_REVIEW: `${API_URL_WITH_V2}/employer/rewards/payment/card/review`,
  TOTALS_REPORT: `${API_URL_WITH_V2}/employer/employee/report`,
  REPORTS: `${API_URL_WITH_V2}/employer/employee/rewardsReport`,
  GET_TOTAL_EARNED_CARROTS: `${API_URL_WITH_V2}/employer/rewards/setup/totalEarned`,
  CLEAR_TOTAL_EARNED_CARROTS: `${API_URL_WITH_V2}/employer/rewards/setup/clearTotalEarned`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const PROCESS_PAYMENT_SUCCESS = 'paymentSuccess';
export const PROCESS_PAYMENT_ERROR = 'paymentFailure';
export const PROCESS_PAYMENT_RESET = 'paymentReset';
export const REWARDS_PAYMENT_REVIEW_SUCCESS = 'rewards_payment_review_success';
export const REWARDS_PAYMENT_SETUP_OVERVIEW_SUCCESS =
  'rewards_payment_setup_overview_success';
export const REWARDS_BALANCE_SUCCESS = 'rewards_balance_success';
export const STORE_REWARDS_CREDIT_CARD = 'store_rewards_credit_card';
export const STORE_REWARDS_ALLOWANCE_CREDIT_CARD =
  'store_rewards_allowance_credit_card';
export const GOT_TOTALS_REPORT = 'got_totals_report';
export const GOT_REWARDS_REPORT = 'GOT_REWARDS_REPORT';
export const GOT_TOTAL_EARNED_CARROTS = 'got_total_earned_carrots';
export const REQUEST_START_FRESH_SUCCESS = 'request_start_fresh_success';

// ------------------------------------
// Actions
// ------------------------------------

const paymentSuccess = () => ({
  type: PROCESS_PAYMENT_SUCCESS,
});

const paymentFailure = (error) => ({
  type: PROCESS_PAYMENT_ERROR,
  message: error.message,
});

const paymentReset = () => ({
  type: PROCESS_PAYMENT_RESET,
});
const rewardsPaymentsReviewSuccess = (paymentsReview) => ({
  type: REWARDS_PAYMENT_REVIEW_SUCCESS,
  paymentsReview,
});
const gotTotalsReportSuccess = (report) => ({
  type: GOT_TOTALS_REPORT,
  report,
});

const gotRewardsReportSuccess = (reports) => ({
  type: GOT_REWARDS_REPORT,
  reports,
});
// const storeRewardsCreditCard = (props) => {
//   const { billingAddress, creditCard, brand } = props;
//   return {
//     type: STORE_REWARDS_CREDIT_CARD,
//     billingAddress,
//     creditCard,
//     brand,
//   };
// };
const storeRewardsAllowanceCreditCard = (props) => {
  const { allowanceInfo } = props;
  return {
    type: STORE_REWARDS_ALLOWANCE_CREDIT_CARD,
    allowanceInfo,
  };
};

const gotPaymentSetupOverview = (overviewData) => ({
  type: REWARDS_PAYMENT_SETUP_OVERVIEW_SUCCESS,
  overviewData,
});

const gotTotalEarnedCarrots = (totalCarrotsEarned) => ({
  type: GOT_TOTAL_EARNED_CARROTS,
  totalCarrotsEarned,
});

const requestStartFreshSuccess = (success) => ({
  type: REQUEST_START_FRESH_SUCCESS,
  success,
});

export const rewardsActions = {
  paymentSuccess,
  paymentFailure,
  paymentReset,
  gotPaymentSetupOverview,
  rewardsPaymentsReviewSuccess,
  // storeRewardsCreditCard,
  storeRewardsAllowanceCreditCard,
  gotTotalsReportSuccess,
  gotTotalEarnedCarrots,
  gotRewardsReportSuccess,
  requestStartFreshSuccess,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

// export const proceedToReview = (props) => async (dispatch) => {
//   dispatch(rewardsActions.storeRewardsCreditCard(props));
//   history.push('/admin/billing/rewards/review');
// };
// export const proceedToSetAllowance = (props) => async (dispatch) => {
//   dispatch(rewardsActions.storeRewardsCreditCard(props));
//   history.push('/admin/billing/rewards/allowance');
// };
export const proceedToReviewFromAllowance = (props) => async (dispatch) => {
  dispatch(rewardsActions.storeRewardsAllowanceCreditCard(props));
  history.push('/billing/rewards/review');
};
export const proceedToHandleCurrentCarrotsFromAllowance =
  (props) => async (dispatch) => {
    dispatch(rewardsActions.storeRewardsAllowanceCreditCard(props));
    history.push('/billing/rewards/gold');
  };

export const activateRewardsPayment = (props) => async (dispatch) => {
  try {
    dispatch(showInfoMessage('Turning on Paid Allowance...'));

    const res = await axios.post(
      apiEndpoints.ACTIVATE_REWARDS_PAYMENT,
      props,
      AuthHeaders(),
    );

    const result = res.data;
    if (result.success) {
      dispatch(showSuccessMessage('Paid allowance has been turned on.'));
      const { employer } = result.data;
      dispatch(adminActions.gotMyEmployer(employer));
      // history.push('/admin/billing/rewards');
    } else {
      // dispatch(actions.emailExists());
    }
  } catch (error) {
    dispatch(
      showErrorMessage(
        'Whoops, looks like we’re experiencing network issues -- Try again.',
      ),
    );
  }
};
export const deactivateRewardsPayment = (props?: any) => async (dispatch) => {
  try {
    dispatch(showInfoMessage('Turning off paid allowance...'));

    const res = await axios.post(
      apiEndpoints.DEACTIVATE_REWARDS_PAYMENT,
      props,
      AuthHeaders(),
    );

    const result = res.data;
    if (result.success) {
      dispatch(showInfoMessage('Paid allowance has been turned off.'));
      const { employer } = result.data;
      dispatch(adminActions.gotMyEmployer(employer));
      // history.push('/admin/billing/rewards');
    } else {
      // dispatch(actions.emailExists());
    }
  } catch (error) {
    dispatch(
      showErrorMessage(
        'Whoops, looks like we’re experiencing network issues -- Try again.',
      ),
    );
  }
};
export const getRewardsPaymentDetails =
  () =>
  // const {isActive} = props;
  async (dispatch) => {
    try {
      const res = await axios.get(
        apiEndpoints.REWARDS_PAYMENT_DETAILS,
        AuthHeaders(),
      );
      const result = res.data;
      if (result.success) {
        dispatch(adminActions.gotPaymentDetails(result.data));
      }
    } catch (error) {
      return;
    }
  };
export const getRewardsSetupOverview =
  (expectedDefaultAllowance = null) =>
  // const {isActive} = props;
  async (dispatch) => {
    let url = apiEndpoints.REWARDS_PAYMENT_SETUP_OVERVIEW;
    if (expectedDefaultAllowance) {
      url = `${apiEndpoints.REWARDS_PAYMENT_SETUP_OVERVIEW}?expectedRegularAllowance=${expectedDefaultAllowance}`;
    }
    try {
      const res = await axios.get(url, AuthHeaders());
      const result = res.data;
      if (result.success) {
        dispatch(rewardsActions.gotPaymentSetupOverview(result.data));
      }
    } catch (error) {
      return;
    }
  };

export const getRewardsTotalsReportAction = (props) => {
  const { startDate, endDate } = props;
  // const {isActive} = props;
  return async (dispatch) => {
    dispatch(showInfoMessage('Fetching total reports...'));
    try {
      const res = await axios.get(
        `${apiEndpoints.TOTALS_REPORT}?startDate=${startDate}&endDate=${endDate}`,
        AuthHeaders(),
      );
      const result = res.data;
      if (result.success) {
        dispatch(showSuccessMessage('Got the reports'));
        dispatch(rewardsActions.gotTotalsReportSuccess(result.data));
      }
    } catch (error) {
      return;
    }
  };
};

export const getRewardsReportAction = () => {
  // const { startDate, endDate } = props;
  // const {isActive} = props;
  return async (dispatch) => {
    dispatch(showInfoMessage('Fetching Rewards Report...'));
    try {
      const res = await axios.get(apiEndpoints.REPORTS, AuthHeaders());
      const result = res.data;
      if (result.success) {
        dispatch(showSuccessMessage('Got the reports'));
        dispatch(rewardsActions.gotRewardsReportSuccess(result.data));
      }
    } catch (error) {
      return;
    }
  };
};

export const getRewardsPaymentReview = () => async (dispatch) => {
  try {
    const res = await axios.get(
      apiEndpoints.REWARDS_PAYMENT_REVIEW,
      AuthHeaders(),
    );
    const result = res.data;
    if (result.success) {
      const paymentsReview = {
        estPrePaymentNextMonth: result.data.estPrePaymentNextMonth,
        firstMonthPayment: result.data.firstMonthPayment,
        monthlyBalance: result.data.monthlyBalance,
        numberOfEmployees: result.data.numberOfEmployees,
      };
      dispatch(rewardsActions.rewardsPaymentsReviewSuccess(paymentsReview));
    }
  } catch (error) {
    return;
  }
};
export const getTotalEarnedCarrots = () => async (dispatch) => {
  try {
    const res = await axios.get(
      apiEndpoints.GET_TOTAL_EARNED_CARROTS,
      AuthHeaders(),
    );
    const result = res.data;
    if (result.success) {
      dispatch(rewardsActions.gotTotalEarnedCarrots(result.data.totalEarned));
    }
  } catch (error) {
    return;
  }
};
export const requestStartFresh = (props) => async (dispatch) => {
  try {
    dispatch(showInfoMessage('Clearing earned carrots for all users...'));
    const res = await axios.post(
      apiEndpoints.CLEAR_TOTAL_EARNED_CARROTS,
      props,
      AuthHeaders(),
    );
    const result = res.data;
    if (result.success) {
      dispatch(rewardsActions.requestStartFreshSuccess('success'));
      dispatch(showSuccessMessage('Cleared all carrots'));
    }
  } catch (error) {
    return;
  }
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state: Record<string, any> = {}, action) => {
  switch (action.type) {
    case PROCESS_PAYMENT_SUCCESS:
      return { ...state, payment_status: true, error: '' };
    case PROCESS_PAYMENT_ERROR:
      return {
        ...state,
        payment_status: false,
        error: action.message || 'Card Error',
      };
    case PROCESS_PAYMENT_RESET:
      return { ...state, payment_status: false, error: '' };
    case REWARDS_PAYMENT_REVIEW_SUCCESS:
      return { ...state, paymentsReview: action.paymentsReview };
    case GOT_TOTALS_REPORT:
      return { ...state, totalsReport: action.report };
    case GOT_REWARDS_REPORT:
      return { ...state, reports: action.reports };
    case STORE_REWARDS_CREDIT_CARD:
      return {
        ...state,
        billingAddress: action.billingAddress,
        creditCard: action.creditCard,
        brand: action.brand,
      };
    case STORE_REWARDS_ALLOWANCE_CREDIT_CARD:
      return { ...state, allowanceInfo: action.allowanceInfo };
    case GOT_TOTAL_EARNED_CARROTS: {
      //  asvk hack to handle resetCarrots
      const existingTotalCarrotsEarned = state.totalCarrotsEarned;
      if (existingTotalCarrotsEarned > 0) {
        if (action.totalCarrotsEarned === 0) {
          return { ...state };
        }
      }
      return { ...state, totalCarrotsEarned: action.totalCarrotsEarned };
    }
    case REWARDS_PAYMENT_SETUP_OVERVIEW_SUCCESS:
      return { ...state, overviewData: action.overviewData };
    case REQUEST_START_FRESH_SUCCESS:
      return { ...state, startFreshRequestStatus: action.success };
    default:
      return state;
  }
};
