import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

// Material UI
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = () => ({
  root: {
    borderRadius: 2,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    marginTop: 16,
    marginBottom: 16,
  },
});

type SearchBarProps = {
  searchTextFieldValue: string;
  handleSearchChange: (e?: any) => void;
  onSearchClick: () => void;
  onClearClick: () => void;
  onExportClick: () => void;
  classes: {
    root: string;
  };
};

class SearchBar extends Component<SearchBarProps, any> {
  render() {
    const { classes } = this.props;
    return (
      <div className={`redeem-container__div ${classes.root}`}>
        <div className="redeem-search-bar__div">
          <SearchIcon style={{ marginRight: 10 }} />
          <TextField
            id="search-account"
            placeholder="Search account by name, email, employerId or ownerId"
            // className={classes.textField}
            value={this.props.searchTextFieldValue}
            onChange={this.props.handleSearchChange}
            margin="normal"
            fullWidth
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.onSearchClick}
            style={{
              width: 200,
              marginLeft: 20,
            }}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.onClearClick}
            style={{
              width: 200,
              marginLeft: 20,
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.onExportClick}
            style={{
              width: 250,
              marginLeft: 20,
            }}
          >
            Export Data
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
  // accounts: getAccountsSelector(state),
});

export default connect(mapStateToProps, {})(withStyles(styles)(SearchBar));
