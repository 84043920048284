import axios from 'axios';
import { API_URL_WITH_V2 } from '../config';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import { AuthHeaders } from './shared/HeaderToken';
import { showSuccessMessage, showInfoMessage } from '../Utils/flashHandler';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  PLATFORM_PAYMENT: `${API_URL_WITH_V2}/employer/subscription/payment/card`,
  PLATFORM_PAYMENT_V2: `${API_URL_WITH_V2}/employer/subscription/v2/payment/card`,
  PLATFORM_PAYMENT_DETAILS: `${API_URL_WITH_V2}/employer/subscription/v2/payment/card/details`,
  PLATFORM_PROMO_CODE: `${API_URL_WITH_V2}/employer/subscription/payment/coupon`,
  ADD_CREDIT_CARD: `${API_URL_WITH_V2}/employer/billing/cards/add`,
};

// ------------------------------------
// Constants
// ------------------------------------

const PROCESS_PAYMENT_SUCCESS = 'paymentSuccess';
const PROCESS_PAYMENT_ERROR = 'paymentFailure';
const PROMO_CODE_ERROR = 'promo_code_failure';
const PROMO_CODE_SUCCESS = 'promo_code_success';
const PROCESS_PAYMENT_RESET = 'paymentReset';

// ------------------------------------
// Actions
// ------------------------------------

const paymentSuccess = () => ({
  type: PROCESS_PAYMENT_SUCCESS,
});

const paymentFailure = (error) => ({
  type: PROCESS_PAYMENT_ERROR,
  message: error,
});
const promoCodeFailure = (error) => ({
  type: PROMO_CODE_ERROR,
  message: error,
});
const promoCodeSuccess = (success, code) => ({
  type: PROMO_CODE_SUCCESS,
  message: success,
  code,
});

const paymentReset = () => ({
  type: PROCESS_PAYMENT_RESET,
});

const actions = {
  paymentSuccess,
  paymentFailure,
  paymentReset,
  promoCodeFailure,
  promoCodeSuccess,
};

const processError = (
  error: {
    response: any;
  },
  dispatch,
) => {
  // Logger('processError');
  // Logger(error.response);
  // Logger(error);
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(actions.paymentFailure(ErrorMessages.serverError));
      break;
    case 401: // Not authorised
      // (Log out user)
      break;
    case 403: // Forbidden
      // redirect to home
      break;
    case 404: // Page not found
      dispatch(actions.paymentFailure(ErrorMessages.serverError));
      break;
    // mostly my error
    case 422:
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        if (responseData.code === 102) {
          dispatch(actions.paymentFailure(responseData.error));
        } else {
          dispatch(actions.paymentFailure(msg));
        }
      }
      break;
    default:
      dispatch(actions.paymentFailure(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const applyPromoCodeAction = (code) => async (dispatch) => {
  dispatch(actions.promoCodeFailure(null));
  try {
    dispatch(showInfoMessage('Applying Promo Code...'));
    const res = await axios.get(
      `${apiEndpoints.PLATFORM_PROMO_CODE}/${code}`,
      AuthHeaders(),
    );
    const result = res.data;
    if (result.success) {
      const successMessage = result.message.join(' ');
      dispatch(showSuccessMessage('Promo Code has been applied successfully'));
      dispatch(actions.promoCodeSuccess(successMessage, code));
      // dispatch(gotMyEmployer(employer));
    }
  } catch (error: any) {
    if (error && error.response) {
      const responseData = error.response.data;
      if (responseData.code === 102) {
        dispatch(actions.promoCodeFailure(responseData.message));
      } else {
        processError(error, dispatch);
      }
    }
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case PROCESS_PAYMENT_SUCCESS:
      return { ...state, payment_status: true, error: '' };
    case PROCESS_PAYMENT_ERROR:
      return {
        ...state,
        payment_status: false,
        error: action.message || 'Card Error',
      };
    case PROCESS_PAYMENT_RESET:
      return { ...state, payment_status: false, error: '' };
    case PROMO_CODE_ERROR:
      return { ...state, promoCodeError: action.message };
    case PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeSuccess: action.message,
        promoCode: action.code,
      };
    default:
      return state;
  }
};
