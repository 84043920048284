import './vendors/polyfills';
import { datadogLogs } from '@datadog/browser-logs';
import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { DATA_DOG } from './config';
import rootSaga from './modules/sagas';
import { authenticateUser, clearLocalStorage } from './modules/Session';
import store from './store';
import { initAnalytics } from './Utils/analytics';
import { bootIntercom } from './Utils/Intercom';
import { makeAPICall } from './queries/utils';
import { GET_USER_INFO } from './constants/endpoints';

const dataDogOptions = {
  ...DATA_DOG,
  sampleRate: 0,
  silentMultipleInit: true,
  sessionReplaySampleRate: 0,
  useCrossSiteSessionCookie: true,
  trackSessionAcrossSubdomains: true,
  version: process.env.REACT_APP_VERSION,
  useSecureSessionCookie: process.env.REACT_APP_STAGE !== 'local',
};

loadProgressBar({ showSpinner: false }, axios);

datadogLogs.init({
  ...dataDogOptions,

  forwardConsoleLogs: ['error', 'warn'],
  forwardReports: ['csp_violation', 'deprecation', 'intervention'],
});

// @ts-ignore
store.runSaga(rootSaga);

initAnalytics();

makeAPICall(GET_USER_INFO)
  .then((res) => {
    authenticateUser(res.data.data.user, store.dispatch);
  })
  .catch(() => {
    clearLocalStorage();
    bootIntercom();
  })
  .finally(() => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root'),
    );
  });
