enum SplitNames {
  PAGINATED_INTEGRATION_DATA = 'PAGINATED_INTEGRATION_DATA',
  NEW_PAGE_PARTICIPATION_VIEW = 'NEW_PAGE_PARTICIPATION_VIEW',
  ACTIVITY_REPORT_EXTRA_FIELD = 'ACTIVITY_REPORT_EXTRA_FIELD',
  MultiAssembly = 'MULTI_ASSEMBLY',
  FLAG_POINT_SWAPPING = 'FLAG_POINT_SWAPPING',
  EnableUserActivitiesTracking = 'ENABLE_USER_ACTIVITIES_TRACKING',
  LocationCriteria = 'LOCATION_CRITERIA',
  SHARESHEET_V3 = 'SHARESHEET_V3',
  CLOSE_ACCOUNT = 'CLOSE_ACCOUNT',
  FLOW_ADMIN_INSIGHTS = 'FLOW_ADMIN_INSIGHTS',
  FLOW_BOOST_PERMISSION = 'FLOW_BOOST_PERMISSION',
  EnableAssemblyMagicLink = 'ASSEMBLY_MAGIC_LINK',
  CHALLENGES = 'CHALLENGES',
  AmazonRewards = 'AMAZON_REWARDS',
  ManualSync = 'IM_MANUAL_SYNC_BTN',
}

enum TreatmentTypes {
  CONTROL = 'control',
  ON = 'on',
  OFF = 'off',
  NOT_YET_SET = 'NOT_YET_SET',
}

export { SplitNames, TreatmentTypes };
