export const CUSTOM_ALLOWANCE_REQUEST_STATUSES = {
  BLANK: 'blank',
  UPDATING: 'updating',
};

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_CUSTOM_ALLOWANCE_SUCCESS =
  'UPDATE_CUSTOM_ALLOWANCE_SUCCESS';
export const CUSTOM_ALLOWANCE_REQUEST_STATUS =
  'CUSTOM_ALLOWANCE_REQUEST_STATUS';

export default (
  state = { requestStatus: CUSTOM_ALLOWANCE_REQUEST_STATUSES.BLANK },
  action,
) => {
  switch (action.type) {
    case UPDATE_CUSTOM_ALLOWANCE_SUCCESS: {
      return { ...state };
    }
    case CUSTOM_ALLOWANCE_REQUEST_STATUS:
      return { ...state, requestStatus: action.status };
    default:
      return state;
  }
};
