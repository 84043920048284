import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Image from '../../atoms/Image';
import ThemeV2 from '../../../componentsV2/theme';
import SVGIcon from '../../atoms/SVGIcon';
import Body from '../../atoms/Body';
import { PropsClasses } from '../../../interfaces/ClassesProps';
import Avatar from '../../atoms/Avatar';
import { AvatarProps } from '../../atoms/Avatar/interfaces';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '2px',
    cursor: 'pointer',
    transition: 'all .3s',
    backgroundColor: ThemeV2.palette.white,
    '&:hover': {
      backgroundColor: ThemeV2.palette.geekBlue1,
    },
    '&:hover $listDisplayText': {
      backgroundColor: ThemeV2.palette.geekBlue3,
    },
  },
  listDisplayText: {
    backgroundColor: ThemeV2.palette.geekBlue1,
    borderRadius: '2px',
    padding: '0 4px',
    margin: '0 8px 0 4px',
    transition: 'all .3s',
  },
  listOption: {
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: ThemeV2.palette.geekBlue1,
    },
  },
  listThumbnail: {
    borderRadius: '100%',
  },
  listSvgWrapper: {
    display: 'flex',
    width: '24px',
    height: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ThemeV2.palette.geekBlue6,
    borderRadius: '100%',
  },
});

interface DDListItemProps {
  displayText: string;
  id?: string;
  secondaryText?: string;
  img?: string;
  hideImage?: boolean;
  classes?: PropsClasses;
  avatar?: AvatarProps;
}

const DropdownListItem = (props: DDListItemProps) => {
  const classes = useStyles(props);
  const { displayText, img, secondaryText, hideImage, avatar } = props;
  const [image, setImage] = useState<string | undefined>(img);

  const handleImageError = useCallback(() => {
    setImage(undefined);
  }, []);

  const renderImg = useCallback(() => {
    if (image !== undefined && image !== '') {
      return (
        <Image
          src={image}
          srcset={image}
          alt={displayText}
          height="24px"
          width="24px"
          className={classes.listThumbnail}
          onError={handleImageError}
        />
      );
    }
    if (avatar) {
      return <Avatar {...avatar} />;
    }
    return (
      <div className={classes.listSvgWrapper}>
        <SVGIcon icon="user" size="12px" color={ThemeV2.palette.gray1} />
      </div>
    );
  }, [
    classes.listSvgWrapper,
    classes.listThumbnail,
    image,
    displayText,
    handleImageError,
    avatar,
  ]);

  if (hideImage) {
    return (
      <div className={classes.root}>
        <Body variant="body2" color="gray8" className={classes.listOption}>
          {displayText}
        </Body>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {renderImg()}
      <Body
        variant="body2"
        color="geekBlue6"
        className={classes.listDisplayText}
      >
        {displayText}
      </Body>
      {secondaryText !== undefined && (
        <Body variant="body2" color="gray9">
          {secondaryText}
        </Body>
      )}
    </div>
  );
};

export default DropdownListItem;
