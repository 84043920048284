import React, { useCallback, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { Helmet } from 'react-helmet-async';
import ThemeV2 from '../../../componentsV2/theme';
import AdminBreadCrumps from '../../../screens/admin/shared/AdminBreadCrumps';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import AnalyticsUpgradeFreePlan from '../../molecules/AnalyticsUpgradeFreePlan';
import Dropdown from '../../atoms/Dropdown';
import LinearGraphTemplate from '../../templates/LinearGraphTemplate';
import AnalyticsInfoPanel from '../../organism/AnalyticsInfoPanel';
import Modal from '../../atoms/Modal';
import BenchMarkInfoModal from '../../molecules/BenchmarkInfoModal';

import {
  PEOPLE_SUMMARY_GRAPH_HEADER,
  PEOPLE_SUMMARY_GRAPH_SUB_HEADER,
  PEOPLE_SUMMARY_INFO_HEADER,
  PEOPLE_SUMMARY_INFO_SUB_HEADER,
  PROFILE_INFO_HEADER,
  PROFILE_INFO_SUB_HEADER,
  RECOGNITION_SUMMARY_INFO_HEADER,
  RECOGNITION_SUMMARY_INFO_SUB_HEADER,
  RECOGNITION_SUMMARY_GRAPH_HEADER,
  RECOGNITION_SUMMARY_GRAPH_SUB_HEADER,
  CORE_VALUES_BADGES_GRAPH_HEADER,
  CORE_VALUES_BADGES_GRAPH_SUB_HEADER,
  PEOPLE,
  RECOGNITION,
} from '../../../Utils/data/analytics/common';
import PlanUpgradeModalContainer from '../../../containersV2/PlanUpgradeModalContainer';
import { DropdownItem } from '../../../controllers/admin/settings/insights/interfaces';
import { DataFormat } from '../../molecules/LinearGraph';
import SingleAccordion from '../../organism/SingleAccordion';
import SVGIcon from '../../atoms/SVGIcon';
import Divider from '../../atoms/Divider';
import {
  ACCORDING_SUMMARY_HEADER,
  ACCORDING_SUMMARY_SUBHEADER,
  TOP_PERFORMER_HEADER,
  BENCHMARK_TOP_PERFORMER_COMMENT,
  BENCHMARK_DOING_GREAT_COMMENT,
  BENCHMARK_NEED_A_BOOST_COMMENT,
  BENCHMARK_NEED_A_BOOST_BUTTON,
  BENCHMARK_ERROR_COMMENT,
  BENCHMARK_ERROR_HEADER,
  BENCHMARK_KEY_HEADER,
  BENCHMARK_KEY_CONTENT,
  BENCHMARK_KEYS,
  DOING_GREAT_HEADER,
  NEED_A_BOOST_HEADER,
  BENCHMARK_TALK_WITH_MANAGER,
} from '../../../constants/analytics/benchmark/constants';

import {
  AdminAnalyticsContainer,
  InfoTypeContainer,
  Breadcrumbs,
  GraphContainer,
  GraphDropdownContainer,
  IconWrapper,
  GraphTitleContainer,
  InfoTypeHeader,
  MultiDropdownContainer,
  BenchmarkContainer,
  KeyTypeContainer,
  BenchmarkContent,
  TypeContainer,
  BookCallButton,
  BenchmarkContentHeading,
  BookCallModal,
} from './styles';
import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';
import IconButton from '../../atoms/IconButton_V2';
import { Flex } from '../../../Utils/styles/display';

type SummaryItem = {
  dataNumber: number;
  headerText: string;
  subTextLink?: string;
  percentChange: string;
  comparisonString: string;
  subText: string;
  headerTooltip?: string;
  isLoading: boolean;
  error: boolean;
  engagementRank: BenchmarkStatus;
};

interface GraphLineData {
  id: string;
  data: DataFormat[];
}

interface AdminAnalyticsPageProps {
  peopleSummaryData: SummaryItem[];
  selectedPeopleTimelineId: number;
  peopleGraphData: GraphLineData[] | null;
  peopleDataError?: Error;
  profileInfoData: SummaryItem[];
  recognitionSummaryData: SummaryItem[];
  selectedRecognitionTimelineId: number;
  recognitionGraphData: GraphLineData[] | null;
  recognitionDataError?: Error;
  selectedValueId: number;
  onValueDropdownChange: (optionId: number) => void;
  selectedValueTimelineId: number;
  selectedChartData: GraphLineData[] | null;
  selectedChartError?: Error;
  adminAnalyticsAccess: boolean;
  toggleUpgradeModalOpen: () => void;
  isUpgradeModalOpen: boolean;
  isSuperAdmin?: boolean;
  assemblyName?: string;
  onViewDropdownChange: (type: string, optionId: number) => void;
  onTimelineDropdownChange: (type: string, optionId: number) => void;
  selectedValueViewId: number;
  selectedPeopleViewId: number;
  selectedRecognitionViewId: number;
  monthlyTimelineDropdownItems: DropdownItem[];
  weeklyTimelineDropdownItems: DropdownItem[];
  viewDropdownItems: DropdownItem[];
  valueDropdownItems: DropdownItem[];
  overallEngagementStatus?: BenchmarkStatus;
  overallEngagementError?: Error;
  overallEngagementRequestLoading?: boolean;
  showBookCallModal: boolean;
  toggleBookCallModal: () => void;
  showTipsModal: boolean;
  toggleTipsModal: () => void;
}

const AdminAnalyticsPage = ({
  adminAnalyticsAccess,
  peopleDataError,
  peopleSummaryData,
  peopleGraphData,
  selectedPeopleTimelineId,
  profileInfoData,
  onValueDropdownChange,
  monthlyTimelineDropdownItems,
  weeklyTimelineDropdownItems,
  selectedValueTimelineId,
  selectedChartData,
  selectedChartError,
  selectedRecognitionTimelineId,
  selectedValueId,
  recognitionSummaryData,
  recognitionGraphData,
  recognitionDataError,
  toggleUpgradeModalOpen,
  isUpgradeModalOpen,
  isSuperAdmin,
  assemblyName,
  onViewDropdownChange,
  selectedPeopleViewId,
  selectedRecognitionViewId,
  selectedValueViewId,
  onTimelineDropdownChange,
  viewDropdownItems,
  valueDropdownItems,
  overallEngagementStatus,
  overallEngagementError,
  overallEngagementRequestLoading,
  showBookCallModal,
  toggleBookCallModal,
  showTipsModal,
  toggleTipsModal,
}: AdminAnalyticsPageProps) => {
  const handleBookCallModalClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      toggleBookCallModal();
    },
    [toggleBookCallModal],
  );
  const PEOPLE_SUMMARY_HEADER = useMemo(
    () =>
      PEOPLE_SUMMARY_INFO_HEADER.replace(':assemblyName', assemblyName || ''),
    [assemblyName],
  );
  const RECOGNITION_SUMMARY_HEADER = useMemo(
    () =>
      RECOGNITION_SUMMARY_INFO_HEADER.replace(
        ':assemblyName',
        assemblyName || '',
      ),
    [assemblyName],
  );
  const RECOGNITION_GRAPH_HEADER = useMemo(
    () =>
      RECOGNITION_SUMMARY_GRAPH_HEADER.replace(
        ':assemblyName',
        assemblyName || '',
      ),
    [assemblyName],
  );
  const CORE_VALUES_BADGES_HEADER = useMemo(
    () =>
      CORE_VALUES_BADGES_GRAPH_HEADER.replace(
        ':assemblyName',
        assemblyName || '',
      ),
    [assemblyName],
  );
  const [
    onPeopleViewDropdownChange,
    onRecognitionViewDropdownChange,
    onValueViewDropdownChange,
  ] = useMemo(
    () => [
      (optionId: number) => onViewDropdownChange('people', optionId),
      (optionId: number) => onViewDropdownChange('recognition', optionId),
      (optionId: number) => onViewDropdownChange('value', optionId),
    ],
    [onViewDropdownChange],
  );
  const [
    onPeopleTimelineDropdownChange,
    onRecognitionTimelineDropdownChange,
    onValueTimelineDropdownChange,
  ] = useMemo(
    () => [
      (optionId: number) => onTimelineDropdownChange('people', optionId),
      (optionId: number) => onTimelineDropdownChange('recognition', optionId),
      (optionId: number) => onTimelineDropdownChange('value', optionId),
    ],
    [onTimelineDropdownChange],
  );
  const peopleTimelineItems = useMemo(
    () =>
      selectedPeopleViewId === viewDropdownItems[0].id
        ? weeklyTimelineDropdownItems
        : monthlyTimelineDropdownItems,
    [
      monthlyTimelineDropdownItems,
      selectedPeopleViewId,
      viewDropdownItems,
      weeklyTimelineDropdownItems,
    ],
  );
  const recognitionTimelineItems = useMemo(
    () =>
      selectedRecognitionViewId === viewDropdownItems[0].id
        ? weeklyTimelineDropdownItems
        : monthlyTimelineDropdownItems,
    [
      monthlyTimelineDropdownItems,
      selectedRecognitionViewId,
      viewDropdownItems,
      weeklyTimelineDropdownItems,
    ],
  );
  const valueTimelineItems = useMemo(
    () =>
      selectedValueViewId === viewDropdownItems[0].id
        ? weeklyTimelineDropdownItems
        : monthlyTimelineDropdownItems,
    [
      monthlyTimelineDropdownItems,
      selectedValueViewId,
      viewDropdownItems,
      weeklyTimelineDropdownItems,
    ],
  );
  const [
    overallEngagementIcon,
    overallEngagementHeader,
    overallEngagementComment,
    showBookCallButton,
  ] = useMemo(() => {
    switch (overallEngagementStatus) {
      case BenchmarkStatus.TOP_PERFORMER: {
        return [
          'benchmark-top-performer',
          TOP_PERFORMER_HEADER,
          BENCHMARK_TOP_PERFORMER_COMMENT,
          false,
        ];
      }
      case BenchmarkStatus.NEED_A_BOOST: {
        return [
          'benchmark-need-boost',
          NEED_A_BOOST_HEADER,
          BENCHMARK_NEED_A_BOOST_COMMENT,
          true,
        ];
      }
      case BenchmarkStatus.DOING_GREAT:
      default: {
        return [
          'benchmark-doing-great',
          DOING_GREAT_HEADER,
          BENCHMARK_DOING_GREAT_COMMENT,
          false,
        ];
      }
    }
  }, [overallEngagementStatus]);
  const AccordionSummaryContent = useCallback(() => {
    const benchmarkingError = Boolean(overallEngagementError);
    if (overallEngagementRequestLoading) {
      return (
        <BenchmarkContainer>
          <ContentLoader
            speed={2}
            width={800}
            height={52}
            viewBox="0 0 800 52"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="24" cy="24" r="24" />
            <rect x="64" y="0" rx="0" ry="0" width="200" height="28" />
            <rect x="64" y="32" rx="0" ry="0" width="700" height="20" />
          </ContentLoader>
        </BenchmarkContainer>
      );
    }
    return (
      <BenchmarkContainer>
        <IconWrapper error={benchmarkingError}>
          <SVGIcon
            icon={benchmarkingError ? 'error-warning' : overallEngagementIcon}
            size={benchmarkingError ? '32px' : '48px'}
          />
        </IconWrapper>
        <BenchmarkContent>
          <BenchmarkContentHeading variant="h6" weight="medium">
            {benchmarkingError
              ? BENCHMARK_ERROR_HEADER
              : overallEngagementHeader}
          </BenchmarkContentHeading>
          <Body variant="body3">
            {benchmarkingError
              ? BENCHMARK_ERROR_COMMENT
              : overallEngagementComment}
          </Body>
          {showBookCallButton && (
            <BookCallButton
              color="secondary"
              size="small"
              isFullWidth={false}
              onClick={handleBookCallModalClick}
            >
              {BENCHMARK_NEED_A_BOOST_BUTTON}
            </BookCallButton>
          )}
        </BenchmarkContent>
      </BenchmarkContainer>
    );
  }, [
    handleBookCallModalClick,
    overallEngagementComment,
    overallEngagementError,
    overallEngagementHeader,
    overallEngagementIcon,
    overallEngagementRequestLoading,
    showBookCallButton,
  ]);
  return (
    <AdminAnalyticsContainer>
      <Helmet>
        <title>{'Admin > Workspace > Insights'}</title>
      </Helmet>
      <BenchMarkInfoModal
        isOpen={showTipsModal}
        onModalClose={toggleTipsModal}
        onBookACallClick={toggleBookCallModal}
      />
      <Modal isOpen={showBookCallModal} handleClose={toggleBookCallModal}>
        <Helmet>
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          />
        </Helmet>
        <BookCallModal>
          <Flex margin="16px 8px 0 8px" justifyContent="space-between">
            <Heading variant="h6" weight="medium">
              {BENCHMARK_TALK_WITH_MANAGER}
            </Heading>
            <IconButton icon="round-close" onClick={toggleBookCallModal} />
          </Flex>
          <Divider
            isFullWidth
            color={ThemeV2.palette.gray5}
            marginBottom="16px"
            marginTop="16px"
          />
          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/ellen-sigman/book-a-call-with-account-management?embed=true"
          />
        </BookCallModal>
      </Modal>
      <Breadcrumbs>
        <AdminBreadCrumps primaryText="Workspace" secondaryText="Insights" />
      </Breadcrumbs>
      <AnalyticsUpgradeFreePlan
        isFreePlan={!isSuperAdmin && !adminAnalyticsAccess}
        onUpgradeClick={toggleUpgradeModalOpen}
        assemblyName={assemblyName || ''}
      />
      <SingleAccordion
        summaryContent={
          <div>
            <Heading variant="h6" weight="bold">
              {ACCORDING_SUMMARY_HEADER}
            </Heading>
            <Body variant="body2">{ACCORDING_SUMMARY_SUBHEADER}</Body>
            <AccordionSummaryContent />
          </div>
        }
        detailsContent={
          <div>
            <Body variant="body1Bold">{BENCHMARK_KEY_HEADER}</Body>
            <Body variant="body2">{BENCHMARK_KEY_CONTENT}</Body>
            <KeyTypeContainer>
              {BENCHMARK_KEYS.map((type) => (
                <TypeContainer key={type.icon}>
                  <SVGIcon icon={type.icon} size="40px" />
                  <div>
                    <Body variant="body2Medium">{type.header}</Body>
                    <Body variant="body3">{type.content}</Body>
                  </div>
                </TypeContainer>
              ))}
            </KeyTypeContainer>
          </div>
        }
      />
      <InfoTypeContainer>
        <InfoTypeHeader>{PEOPLE}</InfoTypeHeader>
        <AnalyticsInfoPanel
          subHeader={PEOPLE_SUMMARY_HEADER}
          statusUpdate={PEOPLE_SUMMARY_INFO_SUB_HEADER}
          data={peopleSummaryData}
          onShowTipsClick={toggleTipsModal}
        />
        <Divider
          isFullWidth
          color={ThemeV2.palette.gray5}
          marginBottom="40px"
          marginTop="40px"
        />
        <GraphContainer>
          <GraphTitleContainer>
            <Body variant="subHead2Bold">{PEOPLE_SUMMARY_GRAPH_HEADER}</Body>
            <Body variant="body2" color="gray7">
              {PEOPLE_SUMMARY_GRAPH_SUB_HEADER}
            </Body>
          </GraphTitleContainer>
          <GraphDropdownContainer>
            <Dropdown
              selectedMenuItemId={selectedPeopleTimelineId}
              menuItems={peopleTimelineItems}
              onChange={onPeopleTimelineDropdownChange}
              icon="calendar"
              width="200px"
              hasCalendar
            />
            <Dropdown
              selectedMenuItemId={selectedPeopleViewId}
              menuItems={viewDropdownItems}
              onChange={onPeopleViewDropdownChange}
              icon="graph"
              width="200px"
              hasCalendar
            />
          </GraphDropdownContainer>
          <LinearGraphTemplate
            chartHeight="442px"
            chartData={peopleGraphData}
            chartError={Boolean(peopleDataError)}
            timeDuration={selectedPeopleViewId === 1 ? 'weekly' : 'monthly'}
          />
        </GraphContainer>
        <Divider
          isFullWidth
          color={ThemeV2.palette.gray5}
          marginBottom="40px"
          marginTop="40px"
        />
        <AnalyticsInfoPanel
          subHeader={PROFILE_INFO_HEADER}
          statusUpdate={PROFILE_INFO_SUB_HEADER}
          data={profileInfoData}
          isPercentageIndicators
        />
      </InfoTypeContainer>
      <InfoTypeContainer>
        <InfoTypeHeader>{RECOGNITION}</InfoTypeHeader>
        <AnalyticsInfoPanel
          subHeader={RECOGNITION_SUMMARY_HEADER}
          statusUpdate={RECOGNITION_SUMMARY_INFO_SUB_HEADER}
          data={recognitionSummaryData}
          onShowTipsClick={toggleTipsModal}
        />
        <Divider
          isFullWidth
          color={ThemeV2.palette.gray5}
          marginBottom="40px"
          marginTop="40px"
        />
        <GraphContainer>
          <GraphTitleContainer>
            <Body variant="subHead2Bold">{RECOGNITION_GRAPH_HEADER}</Body>
            <Body variant="body2" color="gray7">
              {RECOGNITION_SUMMARY_GRAPH_SUB_HEADER}
            </Body>
          </GraphTitleContainer>
          <GraphDropdownContainer>
            <Dropdown
              selectedMenuItemId={selectedRecognitionTimelineId}
              menuItems={recognitionTimelineItems}
              onChange={onRecognitionTimelineDropdownChange}
              icon="calendar"
              width="200px"
              hasCalendar
            />
            <Dropdown
              selectedMenuItemId={selectedRecognitionViewId}
              menuItems={viewDropdownItems}
              onChange={onRecognitionViewDropdownChange}
              icon="graph"
              width="200px"
              hasCalendar
            />
          </GraphDropdownContainer>
          <LinearGraphTemplate
            chartHeight="442px"
            chartData={recognitionGraphData}
            chartError={Boolean(recognitionDataError)}
            timeDuration={
              selectedRecognitionViewId === 1 ? 'weekly' : 'monthly'
            }
          />
        </GraphContainer>
        <Divider
          isFullWidth
          color={ThemeV2.palette.gray5}
          marginBottom="40px"
          marginTop="40px"
        />
        <GraphContainer>
          <GraphTitleContainer>
            <Body variant="subHead2Bold">{CORE_VALUES_BADGES_HEADER}</Body>
            <Body variant="body2" color="gray7">
              {CORE_VALUES_BADGES_GRAPH_SUB_HEADER}
            </Body>
          </GraphTitleContainer>
          <GraphDropdownContainer>
            <MultiDropdownContainer>
              <Dropdown
                selectedMenuItemId={selectedValueTimelineId}
                menuItems={valueTimelineItems}
                onChange={onValueTimelineDropdownChange}
                icon="calendar"
                width="200px"
                hasCalendar
              />
              <Dropdown
                selectedMenuItemId={selectedValueId}
                menuItems={valueDropdownItems}
                onChange={onValueDropdownChange}
                icon="star"
                width="200px"
                hasCalendar
              />
            </MultiDropdownContainer>
            <Dropdown
              selectedMenuItemId={selectedValueViewId}
              menuItems={viewDropdownItems}
              onChange={onValueViewDropdownChange}
              icon="graph"
              width="200px"
              hasCalendar
            />
          </GraphDropdownContainer>
          <LinearGraphTemplate
            chartHeight="442px"
            chartData={selectedChartData}
            chartError={Boolean(selectedChartError)}
            timeDuration={selectedValueViewId === 1 ? 'weekly' : 'monthly'}
          />
        </GraphContainer>
        {isUpgradeModalOpen && (
          <PlanUpgradeModalContainer onModalClose={toggleUpgradeModalOpen} />
        )}
      </InfoTypeContainer>
    </AdminAnalyticsContainer>
  );
};

export default AdminAnalyticsPage;
