import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import ToastContent from '../../atomic/molecules/ToastContent';

const defaultToastOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_LEFT,
};

export const showErrorMessage = (message: string) => {
  toast.error(
    <ToastContent icon="warning-circle" message={message} />,
    defaultToastOptions,
  );
};

export const showInfoMessage = (message: string) => {
  toast.info(
    <ToastContent icon="info-filled" message={message} />,
    defaultToastOptions,
  );
};

export const showSuccessMessage = (message: string) => {
  toast.success(
    <ToastContent icon="checkmark-circle" message={message} />,
    defaultToastOptions,
  );
};

export const dismissAllToasts = () => {
  toast.dismiss();
};
