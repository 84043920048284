import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../componentsV2/theme';
import Heading from '../../atoms/Heading';
import Link from '../../atoms/Link';
import SVGIcon from '../../atoms/SVGIcon';
import Tooltip from '../../atoms/ToolTip';
import BenchmarkStatusIcon from '../BenchmarkStatusIcon';
import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
  },
  header: {
    color: ThemeV2.palette.gray9,
  },
  dataNumber: {
    height: '64px',
    lineHeight: '64px',
    fontSize: '56px',
    marginTop: '4px',
    marginBottom: '8px',
  },
  dataPercentage: {
    color: ThemeV2.palette.gray9,
    width: 'fit-content',
    marginRight: '8px',
  },
  timeComparisonText: {
    color: ThemeV2.palette.gray7,
    marginLeft: '4px',
  },
  link: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipContainer: {
    marginLeft: '8px',
    marginTop: '4px',
  },
});
interface InfoDataType {
  headerText: string;
  dataNumber: number;
  percentChange: string;
  subText: string;
  subTextLink?: string;
  comparisonString: string;
  isLoading?: boolean;
  error?: boolean;
  headerTooltip?: string;
  engagementRank: BenchmarkStatus;
}

interface HighlightAnalyticsNumberProps {
  infoData: InfoDataType;
  onShowTipsClick?: () => void;
}

const HighlightAnalyticsNumber = ({
  infoData: {
    headerText,
    dataNumber,
    percentChange,
    subText,
    subTextLink,
    comparisonString,
    error,
    isLoading,
    headerTooltip,
    engagementRank,
  },
  onShowTipsClick,
}: HighlightAnalyticsNumberProps) => {
  const classes = useStyles();
  const getChangeDetails = useMemo(() => {
    if (error) {
      return {
        changeValue: `${percentChange}%`,
        color: 'gray9',
      };
    }
    const number = parseFloat(percentChange);
    // CONVERTING STRINGS TO NUMBERS
    if (number > 0) {
      return {
        color: 'polarGreen7',
        changeValue: `+${number}%`,
      };
    }
    if (number < 0) {
      return {
        color: 'red6',
        changeValue: `${number}%`,
      };
    }
    return {
      color: 'gray9',
      changeValue: `${percentChange}%`,
    };
  }, [error, percentChange]);

  return (
    <div className={classes.root} data-testid="number-highlight">
      {isLoading ? (
        <ContentLoader
          speed={2}
          width={170}
          height={160}
          viewBox="0 0 170 160"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="highlights-loader"
        >
          <rect x="0" y="0" rx="0" ry="0" width="120" height="28" />
          <rect x="0" y="38" rx="0" ry="0" width="120" height="50" />
          <rect x="0" y="105" rx="0" ry="0" width="140" height="20" />
          <rect x="0" y="135" rx="0" ry="0" width="150" height="20" />
          <circle cx="160" cy="115" r="10" />
        </ContentLoader>
      ) : (
        <>
          <div className={classes.headerContainer}>
            <Body variant="subHead2" className={classes.header} inline>
              {headerText}
            </Body>
            {headerTooltip && (
              <Tooltip title={headerTooltip} data-testid="header-tooltip">
                <div className={classes.tooltipContainer}>
                  <SVGIcon
                    icon="info-icon"
                    color={ThemeV2.palette.gray6}
                    size="16px"
                  />
                </div>
              </Tooltip>
            )}
          </div>
          <Heading variant="h1" className={classes.dataNumber}>
            {error ? (
              <SVGIcon
                size="64px"
                icon="error-warning"
                data-testid="highlights-error"
                color={ThemeV2.palette.gray6}
              />
            ) : (
              dataNumber
            )}
          </Heading>
          {subTextLink ? (
            <Link
              data-testid="invite-link"
              data-qa-id="inviteLinkAdminAnalytics"
              to={subTextLink}
              className={classes.link}
            >
              {subText}
            </Link>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Body variant="body1" className={classes.dataPercentage}>
                {subText}
              </Body>
              <BenchmarkStatusIcon
                status={engagementRank}
                error={error}
                loading={isLoading}
                onShowTipsClick={onShowTipsClick}
              />
            </div>
          )}
          <div>
            <Body variant="body1" inline color={getChangeDetails.color}>
              {getChangeDetails.changeValue}
            </Body>
            <Body variant="body2" inline className={classes.timeComparisonText}>
              {` ${comparisonString}`}
            </Body>
          </div>
        </>
      )}
    </div>
  );
};

export default HighlightAnalyticsNumber;
