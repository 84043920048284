import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import themeV2 from '../../theme';
import { Variant } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles({
  root: {
    fontFamily: themeV2.typography.fontFamily,
    color: themeV2.palette.darkText,
  },
  adminRoot: {
    fontFamily: themeV2.typography.adminFontFamily,
    color: themeV2.palette.darkText,
  },
});

function getFontWeight(weight: string) {
  switch (weight) {
    case 'bold':
      return themeV2.typography.fontWeightBold;
    case 'medium':
      return themeV2.typography.fontWeightMedium;
    case 'semiBold':
      return themeV2.typography.fontWeightSemibold;
    default:
      return themeV2.typography.fontWeightRegular;
  }
}

interface CustomTypographyProps {
  children: React.ReactNode;
  weight?: string;
  style?: React.CSSProperties;
  className?: string;
  variant?: Variant | 'inherit';
  gutterBottom?: boolean;
  component?: string;
}

type AdminTypographyComponentProps = CustomTypographyProps;

export const AdminTypography = (props: AdminTypographyComponentProps) => {
  const { children, weight, style, className, gutterBottom, ...rest } = props;
  const classes = useStyles();
  return (
    <Typography
      className={classNames(classes.adminRoot, className)}
      style={{ ...style, fontWeight: getFontWeight(weight || '') }}
      gutterBottom={gutterBottom}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const CustomTypography = (props: CustomTypographyProps) => {
  const {
    children,
    weight = 'Regular',
    style = {},
    className,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Typography
      className={classNames(classes.root, className)}
      style={{ ...style, fontWeight: getFontWeight(weight) }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default CustomTypography;
