import React from 'react';
import { makeStyles } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import Body from '../../atoms/Body';
import CircularProgress from '../CircularProgress';
import ThemeV2 from '../../../componentsV2/theme';
import SVGIcon from '../../atoms/SVGIcon';
import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
  header: {
    color: ThemeV2.palette.gray9,
  },
  circleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
});

interface PercentageIndicatorProps {
  infoData: InfoDataType;
}

interface InfoDataType {
  dataNumber: number;
  headerText: string;
  isLoading: boolean;
  error: boolean;
  engagementRank: BenchmarkStatus;
}

const PercentageIndicator = ({
  infoData: { headerText, dataNumber, isLoading, error, engagementRank },
}: PercentageIndicatorProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-testid="percentage-indicator">
      {isLoading ? (
        <ContentLoader
          speed={2}
          width={160}
          height={160}
          viewBox="0 0 160 160"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="percentage-loader"
        >
          <rect x="0" y="0" rx="0" ry="0" width="160" height="28" />
          <circle cx="85" cy="95" r="50" />
        </ContentLoader>
      ) : (
        <>
          <Body variant="subHead2" className={classes.header}>
            {headerText || ''}
          </Body>
          <div className={classes.circleContainer}>
            {error ? (
              <SVGIcon
                icon="error-warning"
                size="100px"
                data-testid="percentage-error"
                color={ThemeV2.palette.gray4}
              />
            ) : (
              <CircularProgress
                showCircularShadow
                value={dataNumber}
                size="100px"
                showValue
                showBenchmarkStatus
                benchmarkStatus={engagementRank}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PercentageIndicator;
