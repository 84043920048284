import countriesData from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import getSymbolFromCurrency from 'currency-symbol-map';

countriesData.registerLocale(enLocale);

export const GIFT_CARD_TYPES = {
  FIXED: 'FIXED_VALUE',
  VARIABLE: 'VARIABLE_VALUE',
};

export const checkGiftCardItemType = (giftCard) => {
  const firstItem = giftCard.items[0];
  return firstItem.valueType;
};

const GIFT_CARD_DENOMINATIONS_US = [5, 10, 25, 50, 100];

const GIFT_CARD_DENOMINATIONS_EUR = [5, 10, 25, 50, 100];

const GIFT_CARD_DENOMINATIONS_INR = [500, 1000, 2000];

export const getDenomsForGiftcards = (
  currencyCode: string,
  minVal: number,
  maxVal: number,
) => {
  switch (currencyCode) {
    case 'USD':
    case 'CAD':
    case 'AUD':
      return GIFT_CARD_DENOMINATIONS_US;
    case 'EUR':
    case 'GDP':
    case 'GBP':
    case 'SGD':
    case 'SEK':
      return GIFT_CARD_DENOMINATIONS_EUR;
    case 'INR':
      return GIFT_CARD_DENOMINATIONS_INR;
    default: {
      const denoms: number[] = [];
      let valueToAdd = 0;
      const incrementBy = Math.floor(maxVal / 4);
      [1, 2, 3, 4, 5].forEach(() => {
        valueToAdd += incrementBy;
        denoms.push(valueToAdd);
      });
      return denoms;
    }
  }
};

export const getDenominationsForGiftCards = (
  minVal: number,
  maxVal: number,
) => {
  const defaultDenominationsArray = [5, 10, 15, 20, 25, 50, 75, 100];
  if (maxVal > 100) {
    for (let i = 150; i <= 1000; i = i + 50) {
      defaultDenominationsArray.push(i);
    }
  }
  if (maxVal > 1000) {
    for (let i = 1500; i <= 10000; i = i + 500) {
      defaultDenominationsArray.push(i);
    }
  }
  const computedMinValue = minVal <= 5 ? 5 : minVal;
  const denomsArray = defaultDenominationsArray.filter(
    (item) => item >= computedMinValue && item <= maxVal,
  );
  return denomsArray;
};

export const getDisplayAmount = (
  faceValue: number | string,
  currencyCode: string | undefined,
) => {
  let name;
  const countrySymbol = getSymbolFromCurrency(currencyCode || '');
  if (countrySymbol) {
    name = `${countrySymbol}${faceValue}`;
  } else {
    name = `${faceValue} ${currencyCode}`;
  }
  return name;
};

export const getCountryName = (code: string) => {
  if (code === 'US') {
    return 'United States';
  }
  return countriesData.getName(code, 'en');
};

export const sortByNumber = (array: any[], fieldName: string) =>
  array.sort((a, b) => {
    if (a[fieldName] > b[fieldName]) {
      return 1;
    }
    if (a[fieldName] < b[fieldName]) {
      return -1;
    }
    return 0;
  });
