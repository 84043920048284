export const CREATE_YOUR_PASSWORD = 'Create your password';
export const ERROR = 'Something went wrong. Please try again.';
export const REQUIRED_CONFIRM_PASSWORD = 'Confirm your password is required';
export const PASSWORD_DO_NOT_MATCH_ERROR = `Password did not match`;
export const CONFIRM_YOUR_PASSWORD = 'Confirm your password';
export const CREATE_YOUR_PASSWORD_AND_SIGN_IN =
  'Create your password and sign in';
export const RETURN_TO_SIGN_IN = 'Return to sign in';

export const VALIDATE_YOUR_EMAIL = {
  HEADING: 'Validate your email',
  BODY: `We sent you an email to verify your identity. Click the link in that
  email to come back and set a password.`,
};
export const signInWithText = {
  SLACK: 'Sign in with Slack',
  OFFICE365: 'Sign in with Office365',
  ADP: 'Sign in with ADP',
  GOOGLE: 'Sign in with Google',
  LIFION: 'Sign in with Lifion',
};

export const SIGN_IN_BUTTON_TEXT = 'Sign in';
export const SIGN_IN_LOADING_BUTTON_TEXT = 'Signing in...';
export const CREATE_PASSWORD = 'Create a password';

export const RETURN_TO_SIGN_IN_TEXT = 'Return to sign in';
export const IS_THIS_CORRECT_TEXT = 'Is this correct?';

export const VERIFYING_SAML = 'Verifying SAML';
export const CONNECTING_TO = 'Connecting to';
