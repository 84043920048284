import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: () => ({
    fontSize: '14px',
    lineHeight: '22px',
    color: ThemeV2.palette.gray9,
    marginBottom: '4px',
    boxSizing: 'border-box',
  }),
});

type LabelProps = {
  id?: string;
  className?: string;
  children: React.ReactNode | Array<React.ReactNode>;
};

const Label = (props: LabelProps) => {
  const { id, children, className: parentClassName } = props;
  const classes = useStyles({});
  const className = `${classes.root} ${parentClassName}`;
  return (
    <InputLabel htmlFor={id} className={className}>
      {children}
    </InputLabel>
  );
};

export default Label;
