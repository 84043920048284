import React from 'react';
import styled from 'styled-components';

import Modal from '@material-ui/core/Modal';

import ThemeV2 from '../../../componentsV2/theme';

const ChildWrapper = styled.div<{ showMobileFullScreen?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${ThemeV2.palette.gray1};
  border: 1px solid ${ThemeV2.palette.white3};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  @media (max-width: 768px) {
    top: ${({ showMobileFullScreen }) => (showMobileFullScreen ? '0' : '50%')};
    left: ${({ showMobileFullScreen }) => (showMobileFullScreen ? '0' : '50%')};
    bottom: ${({ showMobileFullScreen }) =>
      showMobileFullScreen ? '0' : 'unset'};
    transform: ${({ showMobileFullScreen }) =>
      showMobileFullScreen ? 'unset' : 'translate(-50%, -50%)'};
    max-width: ${({ showMobileFullScreen }) =>
      showMobileFullScreen && '100% !important'};
    border-radius: 0px;
  }
`;

interface ModalProps {
  isOpen: boolean;
  handleClose(): void;
  customClass?: string;
  children: React.ReactNode;
  className?: string;
  showMobileFullScreen?: boolean;
}

// Copied from src/componentsV2/core/Modal/index.jsx and TS Changes
const SimpleModal = (props: ModalProps) => {
  const { isOpen, handleClose, className, children, showMobileFullScreen } =
    props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={handleClose}
      BackdropProps={{
        style: {
          background: '#000000',
          mixBlendMode: 'normal',
          opacity: 0.6,
        },
      }}
    >
      <ChildWrapper
        className={className}
        showMobileFullScreen={showMobileFullScreen}
      >
        {children}
      </ChildWrapper>
    </Modal>
  );
};

export default SimpleModal;
