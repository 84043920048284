import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import RoutesList from '../../../../aV2/routes/routesList';

import SVGIcon from '../../../../atomic/atoms/SVGIcon';
import ThemeV2 from '../../../../componentsV2/theme';
import { getMainRoutePath } from '../../../../Utils/admin/urls';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  itemText: {
    fontSize: ThemeV2.typography.fontSize,
    color: ThemeV2.palette.darkGray9,
  },
  selectedText: {
    fontSize: ThemeV2.typography.fontSize,
    color: ThemeV2.palette.primary.main,
  },
}));

const AdminMenuCategory = {
  workspace: {
    id: 'workspace',
    display: 'Workspace',
  },
  recognition: {
    id: 'recognition',
    display: 'Recognition',
  },
  flows: {
    id: 'flows',
    display: 'Flows',
  },
  users: {
    id: 'users',
    display: 'Users',
  },
  celebration: {
    id: 'celebration',
    display: 'Bots',
  },
  rewards: {
    id: 'rewards',
    display: 'Rewards',
  },
  billing: {
    id: 'billing',
    display: 'Billing',
  },
  security: {
    id: 'security',
    display: 'Security & Login',
  },
  challenges: {
    id: 'challenges',
    display: 'Challenges',
  },
};

// YDOUBT
const AdminRouteIndex = {
  GENERAL: 2,
  ALLOWANCES: 3,
  INTEGRATIONS: 4,
  INSIGHTS: 5,
  RECOGNITION_SETTINGS: 7,
  RECOGNITION_ACTIVITY: 8,
  RECOGNITION_PARTICIPATION: 9,
  FLOW_PERMISSIONS: 11,
  FLOW_INSIGHTS: 12,
  USERS_MANAGE: 13,
  USERS_INVITE: 14,
  USERS_EARNINGS: 15,
  USERS_ACTIVITY: 16,
  CELEBRATION_BIRTHDAY: 17,
  CELEBRATION_ANNIVERSARY: 18,
  BOT_AUTO_DEPOSIT: 19,
  REWARDS_GIFT_CARDS: 20,
  REWARDS_CULTURE: 21,
  REWARDS_AMAZON: 22,
  REWARDS_SWAGS: 23,
  REWARDS_CHARITY: 24,
  REWARDS_DISCOUNTS: 25,
  REWARDS_REPORT: 26,
  BILLING_ACCOUNT: 27,
  BILLING_HISTORY: 28,
  BILLING_METHOD: 29,
  SECURITY_SAML: 30,
  CHALLENGES: 31,
};

const AdminRoutesList = {
  workspace: [
    {
      url: RoutesList.ADMIN_WORKSPACE_GENERAL,
      display: 'General',
      index: AdminRouteIndex.GENERAL,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_ALLOWANCES,
      display: 'Allowances',
      index: AdminRouteIndex.ALLOWANCES,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_INTEGRATIONS,
      display: 'Integrations',
      index: AdminRouteIndex.INTEGRATIONS,
    },
    {
      url: RoutesList.ADMIN_WORKSPACE_INSIGHTS,
      display: 'Insights',
      index: AdminRouteIndex.INSIGHTS,
    },
  ],
  recognition: [
    {
      url: RoutesList.ADMIN_RECOGNITION_SETTINGS,
      display: 'Settings',
      index: AdminRouteIndex.RECOGNITION_SETTINGS,
    },
    {
      url: RoutesList.ADMIN_RECOGNITION_PARTICIPATION,
      display: 'Participation',
      index: AdminRouteIndex.RECOGNITION_PARTICIPATION,
    },
  ],
  flows: [
    {
      url: RoutesList.ADMIN_FLOWS_PERMISSIONS,
      display: 'Permissions',
      index: AdminRouteIndex.FLOW_PERMISSIONS,
    },
    {
      url: RoutesList.ADMIN_FLOWS_INSIGHTS,
      display: 'Insights',
      index: AdminRouteIndex.FLOW_INSIGHTS,
    },
  ],
  challenges: [
    {
      url: RoutesList.ADMIN_CHALLENGES,
      display: 'Challenges',
      index: AdminRouteIndex.CHALLENGES,
    },
  ],
  users: [
    {
      url: RoutesList.ADMIN_USER_MANAGE,
      display: 'Manage',
      index: AdminRouteIndex.USERS_MANAGE,
    },
    {
      url: RoutesList.ADMIN_USER_EARNINGS,
      display: 'Earnings',
      index: AdminRouteIndex.USERS_EARNINGS,
    },
    {
      url: RoutesList.ADMIN_USER_ACTIVITY,
      display: 'Activity',
      index: AdminRouteIndex.USERS_ACTIVITY,
    },
  ],
  celebration: [
    {
      url: RoutesList.ADMIN_CELEBRATIONS_BIRTHDAYS,
      display: 'Birthday',
      index: AdminRouteIndex.CELEBRATION_BIRTHDAY,
    },
    {
      url: RoutesList.ADMIN_CELEBRATIONS_ANNIVERSARIES,
      display: 'Anniversary',
      index: AdminRouteIndex.CELEBRATION_ANNIVERSARY,
    },
    {
      url: RoutesList.ADMIN_BOTS_AUTO_DEPOSIT,
      display: 'Auto Deposit',
      index: AdminRouteIndex.BOT_AUTO_DEPOSIT,
    },
  ],
  rewards: [
    {
      url: RoutesList.ADMIN_REWARDS_GIFT_CARDS,
      display: 'Gift Cards',
      index: AdminRouteIndex.REWARDS_GIFT_CARDS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_CULTURE,
      display: 'Culture Rewards',
      index: AdminRouteIndex.REWARDS_CULTURE,
    },
    {
      url: RoutesList.ADMIN_REWARDS_AMAZON,
      display: 'Amazon Rewards',
      index: AdminRouteIndex.REWARDS_AMAZON,
    },
    {
      url: RoutesList.ADMIN_REWARDS_SWAGS,
      display: 'Axomo Swag',
      index: AdminRouteIndex.REWARDS_SWAGS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_CHARITY,
      display: 'Charitable Donations',
      index: AdminRouteIndex.REWARDS_CHARITY,
    },
    {
      url: RoutesList.ADMIN_REWARDS_DISCOUNTS,
      display: 'Discounts',
      index: AdminRouteIndex.REWARDS_DISCOUNTS,
    },
    {
      url: RoutesList.ADMIN_REWARDS_REPORT,
      display: 'Redemption Report',
      index: AdminRouteIndex.REWARDS_REPORT,
    },
  ],
  billing: [
    {
      url: RoutesList.ADMIN_BILLING_ACCOUNT,
      display: 'Account Plan',
      index: AdminRouteIndex.BILLING_ACCOUNT,
    },
    {
      url: RoutesList.ADMIN_BILLING_HISTORY,
      display: 'Payment History',
      index: AdminRouteIndex.BILLING_HISTORY,
    },
    {
      url: RoutesList.ADMIN_BILLING_METHOD,
      display: 'Payment Method',
      index: AdminRouteIndex.BILLING_METHOD,
    },
  ],
  security: [
    {
      url: RoutesList.ADMIN_SAML_AUTHENTICATION,
      display: 'SAML Authentication',
      index: AdminRouteIndex.SECURITY_SAML,
    },
  ],
};

const getUpdatedSelectedIndex = (path: string) => {
  let index = AdminRouteIndex.GENERAL;
  let category = AdminMenuCategory.workspace.id;
  const mainRoutePath = getMainRoutePath(path);
  switch (mainRoutePath) {
    // YTODO
    case RoutesList.ADMIN_WORKSPACE_GENERAL:
      index = AdminRouteIndex.GENERAL;
      break;
    case RoutesList.ADMIN_WORKSPACE_ALLOWANCES:
    case RoutesList.ADMIN_WORKSPACE_ALLOWANCES_MANAGE:
      index = AdminRouteIndex.ALLOWANCES;
      break;
    case RoutesList.ADMIN_WORKSPACE_INTEGRATIONS:
      index = AdminRouteIndex.INTEGRATIONS;
      break;
    case RoutesList.ADMIN_WORKSPACE_INSIGHTS:
      index = AdminRouteIndex.INSIGHTS;
      break;
    case RoutesList.ADMIN_RECOGNITION_SETTINGS:
      index = AdminRouteIndex.RECOGNITION_SETTINGS;
      category = AdminMenuCategory.recognition.id;
      break;
    case RoutesList.ADMIN_RECOGNITION_ACTIVITY:
      index = AdminRouteIndex.RECOGNITION_ACTIVITY;
      category = AdminMenuCategory.recognition.id;
      break;
    case RoutesList.ADMIN_RECOGNITION_PARTICIPATION:
      index = AdminRouteIndex.RECOGNITION_PARTICIPATION;
      category = AdminMenuCategory.recognition.id;
      break;
    case RoutesList.ADMIN_FLOWS_PERMISSIONS:
      index = AdminRouteIndex.FLOW_PERMISSIONS;
      category = AdminMenuCategory.flows.id;
      break;
    case RoutesList.ADMIN_FLOWS_INSIGHTS:
      index = AdminRouteIndex.FLOW_INSIGHTS;
      category = AdminMenuCategory.flows.id;
      break;
    case RoutesList.ADMIN_USER_MANAGE:
      index = AdminRouteIndex.USERS_MANAGE;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_USER_INVITE:
      index = AdminRouteIndex.USERS_INVITE;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_USER_EARNINGS:
      index = AdminRouteIndex.USERS_EARNINGS;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_USER_ACTIVITY:
      index = AdminRouteIndex.USERS_ACTIVITY;
      category = AdminMenuCategory.users.id;
      break;
    case RoutesList.ADMIN_CELEBRATIONS_ANNIVERSARIES:
      index = AdminRouteIndex.CELEBRATION_ANNIVERSARY;
      category = AdminMenuCategory.celebration.id;
      break;
    case RoutesList.ADMIN_CELEBRATIONS_BIRTHDAYS:
      index = AdminRouteIndex.CELEBRATION_BIRTHDAY;
      category = AdminMenuCategory.celebration.id;
      break;
    case RoutesList.ADMIN_BOTS_AUTO_DEPOSIT:
      index = AdminRouteIndex.BOT_AUTO_DEPOSIT;
      category = AdminMenuCategory.celebration.id;
      break;
    case RoutesList.ADMIN_REWARDS_GIFT_CARDS:
      index = AdminRouteIndex.REWARDS_GIFT_CARDS;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_CULTURE:
      index = AdminRouteIndex.REWARDS_CULTURE;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_CHARITY:
      index = AdminRouteIndex.REWARDS_CHARITY;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_DISCOUNTS:
      index = AdminRouteIndex.REWARDS_DISCOUNTS;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_REPORT:
      index = AdminRouteIndex.REWARDS_REPORT;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_AMAZON:
      index = AdminRouteIndex.REWARDS_AMAZON;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_REWARDS_SWAGS:
      index = AdminRouteIndex.REWARDS_SWAGS;
      category = AdminMenuCategory.rewards.id;
      break;
    case RoutesList.ADMIN_BILLING_ACCOUNT:
      index = AdminRouteIndex.BILLING_ACCOUNT;
      category = AdminMenuCategory.billing.id;
      break;
    case RoutesList.ADMIN_BILLING_HISTORY_INVOICE:
    case RoutesList.ADMIN_BILLING_HISTORY:
      index = AdminRouteIndex.BILLING_HISTORY;
      category = AdminMenuCategory.billing.id;
      break;
    case RoutesList.ADMIN_BILLING_METHOD:
      index = AdminRouteIndex.BILLING_METHOD;
      category = AdminMenuCategory.billing.id;
      break;
    case RoutesList.ADMIN_SAML_AUTHENTICATION:
      index = AdminRouteIndex.SECURITY_SAML;
      category = AdminMenuCategory.security.id;
      break;
    case RoutesList.ADMIN_CHALLENGES:
      index = AdminRouteIndex.CHALLENGES;
      category = AdminMenuCategory.challenges.id;
      break;
    default:
      break;
  }
  return {
    selectedItem: index,
    selectedCategory: category,
  };
};

const AdminMenu = () => {
  const { treatment: challengesTreatment } = useFeatureSplit(
    SplitNames.CHALLENGES,
  );

  const { treatment: amazonRewardsTreatment } = useFeatureSplit(
    SplitNames.AmazonRewards,
  );

  const adminRoutes = useMemo(() => {
    let filteredRoutes = AdminRoutesList;
    if (amazonRewardsTreatment !== TreatmentTypes.ON) {
      const filteredRewardsRoute = filteredRoutes.rewards.filter(
        (route) => route.index !== AdminRouteIndex.REWARDS_AMAZON,
      );
      filteredRoutes = { ...filteredRoutes, rewards: filteredRewardsRoute };
    }
    return filteredRoutes;
  }, [amazonRewardsTreatment]);

  const classes = useStyles();

  const [workspaceOpen, setWorkspaceOpen] = useState(false);
  const [recognitionOpen, setRecognitionOpen] = useState(false);
  const [flowsOpen, setFlowsOpen] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const [celebrationOpen, setCelebrationOpen] = useState(false);
  const [rewardsOpen, setRewardsOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [securityOpen, setSecurityOpen] = useState(false);

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    AdminRouteIndex.GENERAL,
  );

  const { pathname } = useLocation();
  useEffect(() => {
    const { selectedCategory, selectedItem } =
      getUpdatedSelectedIndex(pathname);
    setSelectedMenuItem(selectedItem);

    if (selectedCategory === AdminMenuCategory.workspace.id) {
      if (!workspaceOpen) {
        setWorkspaceOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.recognition.id) {
      if (!recognitionOpen) {
        setRecognitionOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.flows.id) {
      if (!flowsOpen) {
        setFlowsOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.users.id) {
      if (!usersOpen) {
        setUsersOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.celebration.id) {
      if (!celebrationOpen) {
        setCelebrationOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.rewards.id) {
      if (!rewardsOpen) {
        setRewardsOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.billing.id) {
      if (!billingOpen) {
        setBillingOpen(true);
      }
    } else if (selectedCategory === AdminMenuCategory.security.id) {
      if (!securityOpen) {
        setSecurityOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, selectedMenuItem]);

  const userNavList = adminRoutes.users;

  const botsList = adminRoutes.celebration;

  const recognitionNavList = adminRoutes.recognition;

  const flowList = adminRoutes.flows;

  const handleWorkspaceClick = () => {
    setWorkspaceOpen(!workspaceOpen);
  };

  const handleRecognitionClick = () => {
    setRecognitionOpen(!recognitionOpen);
  };

  const handleFlowsClick = () => {
    setFlowsOpen(!flowsOpen);
  };
  const handleUsersClick = () => {
    setUsersOpen(!usersOpen);
  };

  const handleCelebrationClick = () => {
    setCelebrationOpen(!celebrationOpen);
  };

  const handleRewardsClick = () => {
    setRewardsOpen(!rewardsOpen);
  };

  const handleBillingClick = () => {
    setBillingOpen(!billingOpen);
  };

  const handleSecurityClick = () => {
    setSecurityOpen(!securityOpen);
  };

  return (
    <List
      component="nav"
      className={classes.root}
      data-test="admin-sidebar-nav"
    >
      <ListItem
        button
        onClick={handleWorkspaceClick}
        data-qa-id="sidenavWorkspaceButton"
      >
        <ListItemIcon>
          <SVGIcon icon="setting-filled" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Workspace" />
        {workspaceOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={workspaceOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.workspace.map((route) => {
            return (
              <Link key={route.url} to={route.url}>
                <ListItem
                  button
                  className={classes.nested}
                  data-qa-id={`workspaceNavChildren${route.display.replaceAll(
                    ' ',
                    '',
                  )}`}
                >
                  <ListItemText
                    disableTypography
                    className={
                      route.index === selectedMenuItem
                        ? classes.selectedText
                        : classes.itemText
                    }
                    primary={route.display}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleRecognitionClick}
        data-qa-id="sidenavRecognitionButton"
      >
        <ListItemIcon>
          <SVGIcon icon="trophy" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Recognition" />
        {recognitionOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={recognitionOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {recognitionNavList.map((route) => {
            return (
              <Link key={route.url} to={route.url}>
                <ListItem
                  button
                  className={classes.nested}
                  data-qa-id={`recognitionNavChildren${route.display.replaceAll(
                    ' ',
                    '',
                  )}`}
                >
                  <ListItemText
                    disableTypography
                    className={
                      route.index === selectedMenuItem
                        ? classes.selectedText
                        : classes.itemText
                    }
                    primary={route.display}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleFlowsClick}
        data-qa-id="sidenavFlowsButton"
      >
        <ListItemIcon>
          <SVGIcon icon="routine" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Flows" />
        {flowsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={flowsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {flowList.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem
                button
                className={classes.nested}
                data-qa-id={`flowsNavChildren${route.display.replaceAll(
                  ' ',
                  '',
                )}`}
              >
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      {challengesTreatment === TreatmentTypes.ON && (
        <Link
          to={RoutesList.ADMIN_CHALLENGES}
          key={RoutesList.ADMIN_CHALLENGES}
        >
          <ListItem
            button
            onClick={handleFlowsClick}
            data-qa-id="sidenavChallengesButton"
          >
            <ListItemIcon>
              <SVGIcon
                icon="bounty"
                color={
                  AdminRouteIndex.CHALLENGES === selectedMenuItem
                    ? ThemeV2.palette.primary.main
                    : ThemeV2.palette.darkGray1
                }
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary="Challenges"
              className={
                AdminRouteIndex.CHALLENGES === selectedMenuItem
                  ? classes.selectedText
                  : classes.itemText
              }
            />
          </ListItem>
        </Link>
      )}
      <ListItem
        button
        onClick={handleUsersClick}
        data-qa-id="sidenavUsersButton"
      >
        <ListItemIcon>
          <SVGIcon icon="users" color={ThemeV2.palette.darkGray1} />
        </ListItemIcon>
        <ListItemText disableTypography primary="Users" />
        {usersOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={usersOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {userNavList.map((route) => {
            return (
              <Link key={route.url} to={route.url}>
                <ListItem
                  button
                  className={classes.nested}
                  data-qa-id={`usersNavChildren${route.display.replaceAll(
                    ' ',
                    '',
                  )}`}
                >
                  <ListItemText
                    disableTypography
                    className={
                      route.index === selectedMenuItem
                        ? classes.selectedText
                        : classes.itemText
                    }
                    primary={route.display}
                  />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleCelebrationClick}
        data-qa-id="sidenavBotsButton"
      >
        <ListItemIcon>
          <SVGIcon icon="bots" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Bots" />
        {celebrationOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={celebrationOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {botsList.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem
                button
                className={classes.nested}
                data-qa-id={`botsNavChildren${route.display.replaceAll(
                  ' ',
                  '',
                )}`}
              >
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleRewardsClick}
        data-qa-id="sidenavRewardsButton"
      >
        <ListItemIcon>
          <SVGIcon icon="reward" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Rewards" />
        {rewardsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={rewardsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.rewards.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem
                button
                className={classes.nested}
                data-qa-id={`rewardsNavChildren${route.display.replaceAll(
                  ' ',
                  '',
                )}`}
              >
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleBillingClick}
        data-qa-id="sidenavBillingButton"
      >
        <ListItemIcon>
          <SVGIcon icon="billing" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Billing" />
        {billingOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={billingOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.billing.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem
                button
                className={classes.nested}
                data-qa-id={`billingNavChildren${route.display.replaceAll(
                  ' ',
                  '',
                )}`}
              >
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleSecurityClick}
        data-qa-id="sidenavSecurityButton"
      >
        <ListItemIcon>
          <SVGIcon icon="shield" />
        </ListItemIcon>
        <ListItemText disableTypography primary="Security & Login" />
        {securityOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={securityOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {adminRoutes.security.map((route) => (
            <Link key={route.url} to={route.url}>
              <ListItem
                button
                className={classes.nested}
                data-qa-id={`securityNavChildren${route.display.replaceAll(
                  ' ',
                  '',
                )}`}
              >
                <ListItemText
                  disableTypography
                  className={
                    route.index === selectedMenuItem
                      ? classes.selectedText
                      : classes.itemText
                  }
                  primary={route.display}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default AdminMenu;
