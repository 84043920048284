import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface DividerProps {
  color?: string;
  marginTop?: string;
  className?: string;
  marginBottom?: string;
  isFullWidth?: boolean;
}

const useStyles = makeStyles({
  root: ({ color, isFullWidth, marginTop, marginBottom }: DividerProps) => ({
    height: '1px',
    width: isFullWidth ? '100%' : '150px',
    background: color,
    marginTop,
    marginBottom,
    boxSizing: 'border-box',
  }),
});

const Divider = (props: DividerProps) => {
  const { className: parentClassName } = props;
  const { root } = useStyles(props);
  const className = `${root} ${parentClassName}`;
  return <div className={className} />;
};

export default Divider;
