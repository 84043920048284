/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Loadable from 'react-loadable';
import Hidden from '@material-ui/core/Hidden';
import { Collapse } from 'react-collapse';
import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import withWidth from '@material-ui/core/withWidth';

import { checkAdmin } from '../../Utils/admin';
import { uiActions } from '../../modules/UI-Reducers/UIReducers';
import {
  getEmployerCustomLogoSelector,
  employerSelector,
} from '../../modules/admin/selector';
// TO DO: Sumedha -  Admin app - clean up
// import history from '../../history';
import assemblyLogo from '../../img/assembly-placeholder-logo.svg';
import AvatarDropdown from './AvatarDropdown';
import { APP_NAME } from '../../Utils/constants';
import { APP_URL, LANDER_URL, NEW_APP_URL } from '../../config';

const styles = () => ({
  headerRoot: {
    position: 'fixed' as const,
    right: 0,
    left: 0,
    top: 0,
    padding: 8,
    zIndex: 1030,
    height: 66,
    backgroundColor: '#FFF',
    border: 0,
    borderBottom: '1px solid #EEEEEE',
    borderRadius: 0,
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  containerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  rightDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonStyle: {
    margin: '0 30px 0 0',
  },
  logoStyle: {
    borderRadius: 4,
    height: 48,
  },
  homeButton: {
    color: 'rgb(49, 25, 0)',
    '&:focus': {
      outline: 'none',
    },
  },
  badgeRootStyle: {
    marginRight: 12,
  },
  badgeIconStyle: {
    color: 'rgb(49, 25, 0)',
    '&:focus': {
      outline: 'none',
    },
  },
  badgeStyle: {
    color: 'white',
  },
  badgeNoStyle: {
    color: 'transparent',
    backgroundColor: 'transparent',
  },
  linkStyle: {
    transition: 'all .15s ease-in-out',
    color: '#404040',
    marginRight: 10,
  },
  hamburger: {
    '&:focus': {
      outline: 'none',
    },
  },
  dropCollapseDiv: {
    background: '#232323',
    margin: 0,
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10,
    top: 66,
    left: 0,
    position: 'absolute' as const,
    display: 'none',
    width: '100%',
    border: 0,
    zIndex: 1330,
  },
  dropCollapseDivShow: {
    display: 'block !important',
  },
  notificationDropList: {
    cursor: 'pointer',
  },
  listItemStyle: {
    margin: '0px 30px',
    padding: 0,
    '&:hover': {
      color: '#0AD71C !important',
    },
  },
  listItemTextStyle: {
    color: '#ccc !important',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: '12px 0px',
    '&:hover': {
      color: '#0AD71C !important',
    },
  },
  divider: {
    margin: '0px 30px',
    backgroundColor: 'hsla(0,0%,100%,.15)',
  },
});
const LoadableNotification = Loadable({
  loader: () => import('./Notification'),
  loading() {
    return <div />;
  },
});

const AdapterLink = React.forwardRef((props, ref) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  <Link innerRef={ref} {...props} />
));

const renderListItemLink = (to) =>
  React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Link to={to} {...itemProps} innerRef={ref} />
  ));

type HeaderWithMenuProps = {
  shouldShowMenuToggle: boolean;
  toggleMenu?: () => void;
  dispatch: (data: any) => void;
  width: any;
  classes?: any;
  customLogo: any;
  employer: any;
  userLoggedIn: any;
  me: any;
  shouldRedirectToAssemblyV3?: boolean;
  workspaceSlug?: string | null;
};

type HeaderWithMenuState = {
  showMenu: boolean;
};

class HeaderWithMenu extends Component<
  HeaderWithMenuProps,
  HeaderWithMenuState
> {
  state = {
    showMenu: false,
  };

  onMenuClicked = () => {};

  onNotifClick = () => {
    this.props.dispatch(uiActions.toggleNotifications());
  };

  handleHomeClick = () => {
    const redirectURL = this.props.shouldRedirectToAssemblyV3
      ? `${NEW_APP_URL}/a/discover`
      : `${APP_URL}home`;
    window.location.href = redirectURL;
    // TO DO: Sumedha -  Admin app - clean up
    // APP_URL already has a / -> not altering as it might break in other places
    // const currentUrl = window.location.href;
    // history.push('/home');
    // window.history.pushState({ prevUrl: currentUrl }, null, '/home');
  };

  handleSettingsClick = () => {
    const redirectURL = this.props.shouldRedirectToAssemblyV3
      ? `${NEW_APP_URL}/a/${this.props.workspaceSlug}/settings/general`
      : `${APP_URL}settings/general`;
    window.location.href = redirectURL;
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  clickAway = () => {
    this.setState({
      showMenu: false,
    });
  };

  isSmallScreen = () => {
    const { width } = this.props;
    if (width === 'sm' || width === 'xs') {
      return true;
    }
    return false;
  };

  renderCompanyLogo = () => {
    const { classes, customLogo, employer } = this.props;
    if (employer) {
      if (customLogo) {
        return (
          <img
            src={customLogo.path}
            alt={customLogo.name}
            className={classes?.logoStyle}
          />
        );
      }
      return (
        <picture>
          <source src={assemblyLogo} type="image/webp" />
          <img
            src={`${assemblyLogo}`}
            alt={APP_NAME}
            className={classes?.logoStyle}
          />
        </picture>
      );
    }
    return <div />;
  };

  render() {
    const { classes } = this.props;
    const role = this.props.userLoggedIn ? this.props.me.role : null;
    const isAdmin = role ? checkAdmin(role) : false;
    const RenderRightDiv = () => {
      if (this.isSmallScreen()) {
        return <div />;
      }
      if (this.props.userLoggedIn) {
        return (
          <>
            <IconButton
              onClick={this.handleHomeClick}
              className={classes?.homeButton}
              aria-label="Home"
            >
              <HomeIcon />
            </IconButton>

            {/* <IconButton
              onClick={this.onNotifClick}
              className={classes?.badgeIconStyle}
              aria-label="Notifications"
            >
              <Badge
                classes={{
                  // root: classes?.badgeRootStyle,
                  badge:
                    this.props.badgeNumber && this.props.badgeNumber > 0
                      ? classes?.badgeStyle
                      : classes?.badgeNoStyle,
                }}
                badgeContent={this.props.badgeNumber || 0}
                color="primary"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

            <AvatarDropdown
              shouldRedirectToAssemblyV3={this.props.shouldRedirectToAssemblyV3}
              workspaceSlug={this.props.workspaceSlug}
            />
          </>
        );
      }

      return (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/* @ts-expect-error */}
          <Button
            component={AdapterLink}
            to="/signin"
            className={classes?.linkStyle}
          >
            SIGN IN
          </Button>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/* @ts-expect-error */}
          <Button
            component={AdapterLink}
            to="/onboarding"
            variant="contained"
            color="secondary"
          >
            GET STARTED
          </Button>
        </>
      );
    };
    const RenderDropDown = () => {
      if (this.props.userLoggedIn) {
        return (
          <>
            <ListItem
              className={classes?.listItemStyle}
              onClick={this.handleHomeClick}
            >
              <ListItemText
                className={classes?.listItemTextStyle}
                disableTypography
              >
                HOME
              </ListItemText>
            </ListItem>
            {/* <Divider className={classes?.divider} />
            <ListItem
              onClick={this.onNotifClick}
              className={classNames(
                classes?.notificationDropList,
                classes?.listItemStyle,
              )}
            >
              <ListItemText
                className={classes?.listItemTextStyle}
                disableTypography
              >
                {`NOTIFICATIONS (${this.props.badgeNumber || 0})`}
              </ListItemText>
            </ListItem> */}
            <Divider className={classes?.divider} />
            {isAdmin && (
              <>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/* @ts-expect-error */}
                <List className={classes?.listItemStyle} href="/settings">
                  <ListItemText
                    className={classes?.listItemTextStyle}
                    disableTypography
                  >
                    ADMIN
                  </ListItemText>
                </List>
                <Divider className={classes?.divider} />
              </>
            )}
            <ListItem
              className={classes?.listItemStyle}
              onClick={this.handleSettingsClick}
            >
              <ListItemText
                className={classes?.listItemTextStyle}
                disableTypography
              >
                SETTINGS
              </ListItemText>
            </ListItem>
            <Divider className={classes?.divider} />
            <ListItem
              component={renderListItemLink('/signout') as any}
              className={classes?.listItemStyle}
            >
              <ListItemText
                className={classes?.listItemTextStyle}
                disableTypography
              >
                SIGN OUT
              </ListItemText>
            </ListItem>
          </>
        );
      }

      return (
        <>
          <ListItem
            component={renderListItemLink('/signin') as any}
            className={classes?.listItemStyle}
          >
            <ListItemText
              className={classes?.listItemTextStyle}
              disableTypography
            >
              SIGN IN
            </ListItemText>
          </ListItem>
          <Divider className={classes?.divider} />
          <ListItem className={classes?.listItemStyle}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/* @ts-expect-error */}
            <Button
              component={AdapterLink}
              to="/onboarding"
              style={{ marginTop: 12 }}
              variant="contained"
              color="secondary"
            >
              GET STARTED
            </Button>
          </ListItem>
        </>
      );
    };
    return (
      <>
        <div className={classes?.headerRoot}>
          <div className={`container ${classes?.containerDiv}`}>
            <div className={classes?.leftDiv}>
              {this.props.userLoggedIn ? (
                <IconButton
                  disableRipple
                  className={classes?.buttonStyle}
                  onClick={this.handleHomeClick}
                >
                  {this.renderCompanyLogo()}
                </IconButton>
              ) : (
                <Button className={classes?.buttonStyle}>
                  <a href={LANDER_URL}>
                    <picture>
                      <source src={assemblyLogo} type="image/webp" />
                      <img
                        src={assemblyLogo}
                        alt={APP_NAME}
                        className={classes?.logoStyle}
                      />
                    </picture>
                  </a>
                </Button>
              )}
              {this.props.shouldShowMenuToggle && (
                <Fab aria-label="toggle-menu" onClick={this.props.toggleMenu}>
                  <i className="material-icons">format_indent_decrease</i>
                </Fab>
              )}
            </div>
            <div className={classes?.rightDiv}>
              <>
                <Hidden mdUp>
                  <IconButton
                    disableRipple
                    className={`${
                      classes?.hamburger
                    } hamburger hamburger--squeeze ${
                      this.state.showMenu && 'is-active'
                    }`}
                    onClick={this.toggleMenu}
                    component="button"
                    style={{ width: 50, height: 50 }}
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </IconButton>
                </Hidden>
                <Hidden smDown>
                  <RenderRightDiv />
                </Hidden>
              </>
            </div>
          </div>
          {this.props.userLoggedIn && <LoadableNotification />}
        </div>
        <Hidden mdUp>
          <Collapse isOpened={this.state.showMenu}>
            <div
              className={classNames(classes?.dropCollapseDiv, {
                [classes?.dropCollapseDivShow]: this.state.showMenu,
              })}
            >
              <List component="nav" onClick={this.clickAway}>
                <RenderDropDown />
              </List>
            </div>
          </Collapse>
        </Hidden>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLoggedIn: state.auth.authenticated,
    me: state.me.me,
    isContinueSetupOpen: state.ui.showIncompleteOnboarding,
    // badgeNumber: state.notifications.count,
    customLogo: getEmployerCustomLogoSelector(state),
    employer: employerSelector(state),
  };
}

export default connect(mapStateToProps)(
  withWidth()(withStyles(styles)(HeaderWithMenu)),
);
