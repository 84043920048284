import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChartToggle from '../../molecules/ChartToggle';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '8px',
  },
  chartToggle: {
    marginBottom: '4px',
    marginRight: '8px',
  },
});

interface ChartToggleGroupProps {
  toggleItems: Array<{
    id: string;
    backgroundColor: string;
    color: string;
    isActive: boolean;
  }>;
  toggleOnClick: (toggleId: string) => void;
  className?: string;
}

const ChartToggleGroup = (props: ChartToggleGroupProps) => {
  const { toggleItems, toggleOnClick, className: parentClassName } = props;
  const classes = useStyles(props);
  const styles = `${classes.root} ${parentClassName}`;
  return (
    <div className={styles}>
      {toggleItems.map((toggleItem) => (
        <ChartToggle
          id={toggleItem.id}
          text={toggleItem.id}
          color={{
            backgroundColor: toggleItem.backgroundColor,
            text: toggleItem.color,
          }}
          key={toggleItem.id}
          className={classes.chartToggle}
          onClick={toggleOnClick}
          isActive={toggleItem.isActive}
        />
      ))}
    </div>
  );
};

export default ChartToggleGroup;
