import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
// @ts-ignore : no type package available for module 'redux-flash'
import { middleware as flashMiddleware } from 'redux-flash';
import createSagaMiddleware, { END } from 'redux-saga';
// @ts-ignore : no type package available for module '@redux-saga/simple-saga-monitor'
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import { requestsPromiseMiddleware } from 'redux-saga-requests';

import allReducers from './modules/Index';
import { checkIfProduction } from './Utils/admin';

const loggerMiddleware = createLogger();
let sagaMiddleware;
if (checkIfProduction()) {
  sagaMiddleware = createSagaMiddleware();
} else {
  sagaMiddleware = createSagaMiddleware({ sagaMonitor });
}

const flashOptions = { timeout: false };

const middleware = [
  thunk,
  requestsPromiseMiddleware({ auto: true }),
  sagaMiddleware,
  flashMiddleware(flashOptions),
  !checkIfProduction() && loggerMiddleware,
].filter(Boolean);

let enhancer;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
    applyMiddleware(...middleware),
  );
} else {
  enhancer = compose(applyMiddleware(...middleware));
}

const store = createStore(
  allReducers,
  checkIfProduction() ? compose(applyMiddleware(...middleware)) : enhancer,
);

// @ts-ignore
store.runSaga = sagaMiddleware.run;
// @ts-ignore
store.close = () => store.dispatch(END);

export default store;
