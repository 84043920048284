import * as microsoftTeams from '@microsoft/teams-js';
import { get } from 'lodash';
import { parse } from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import RoutesList from '../../../aV2/routes/routesList';
import { APP_URL } from '../../../config';
import { SSO_SIGNIN, SSO_SIGNUP } from '../../../constants/endpoints';
import useRequest from '../../../hooks/useRequest/useRequest';

import {
  handleUserSignin,
  userSessionCreatedAction,
} from '../../../modules/Session';
import { SSOOptions, SSOTypes } from '../../../Utils/data/ssoManager/common';

const useVerifySSOCode = (
  sso: SSOOptions,
  ssoType: SSOTypes,
  authInModal: boolean,
) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const onSuccess = useCallback(
    (data) => {
      const token = get(data, ['data', 'jwtToken']);
      const user = get(data, ['data', 'user']);
      if (user && token) {
        dispatch(userSessionCreatedAction(user, token));
        handleUserSignin(user);
      }
      setIsVerifyingCode(false);
    },
    [dispatch],
  );

  const handleError = useCallback(
    (message = 'SERVER_ERROR') => {
      setIsVerifyingCode(false);
      const errorMessage = get(message, ['response', 'data', 'message']);
      if (errorMessage) {
        push(`/${RoutesList.SSO_ERRORS_FN(sso, ssoType, errorMessage)}`);
      }
    },
    [push, sso, ssoType],
  );
  const { fireRequest: signinRequest } = useRequest(SSO_SIGNIN, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    onSuccess,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    onError: handleError,
  });

  const { fireRequest: signupRequest } = useRequest(
    SSO_SIGNUP,

    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      onSuccess,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      onError: handleError,
    },
  );
  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isCodePresent = parsedParams && parsedParams.code;
    const hasError = parsedParams && parsedParams.error;
    if (isCodePresent) {
      const { code, from } = parsedParams;
      setIsVerifyingCode(true);
      const fromTab = from === 'tab';
      if (authInModal && !fromTab) {
        microsoftTeams.initialize();
        /* To notify app loaded to hide loading indicator */
        microsoftTeams.appInitialization.notifyAppLoaded();
        microsoftTeams.appInitialization.notifySuccess();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        microsoftTeams.authentication.notifySuccess({
          sso,
          code: `${code}`,
          ssoType: `${ssoType}`,
        });
      } else {
        if (ssoType === SSOTypes.SIGN_IN) {
          let REDIRECT_URI;
          if (fromTab) {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_AUTHORIZE_MODAL_FN(
              sso,
              ssoType,
            )}`;
          } else {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_SIGNIN_AUTHORIZE_FN(
              sso,
            )}`;
          }
          signinRequest({
            payload: { code, redirectUri: REDIRECT_URI },
            substitutions: { sso },
          });
        }
        if (ssoType === SSOTypes.SIGN_UP) {
          let REDIRECT_URI;
          if (fromTab) {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_AUTHORIZE_MODAL_FN(
              sso,
              ssoType,
            )}`;
          } else {
            REDIRECT_URI = `${APP_URL}${RoutesList.SSO_SIGNUP_AUTHORIZE_FN(
              sso,
            )}`;
          }
          signupRequest({
            payload: { code, redirectUri: REDIRECT_URI },
            substitutions: { sso },
          });
        }
      }
    }
    if (hasError) {
      if (authInModal) {
        microsoftTeams.initialize();
        /* To notify app loaded to hide loading indicator */
        microsoftTeams.appInitialization.notifyAppLoaded();
        microsoftTeams.appInitialization.notifySuccess();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        microsoftTeams.authentication.notifyFailure(parsedParams.error);
      } else {
        push(
          `/${RoutesList.SSO_ERRORS_FN(
            sso,
            ssoType,
            parsedParams.error?.toString(),
          )}`,
        );
      }
    }
  }, [sso, search, signinRequest, ssoType, signupRequest, push, authInModal]);

  return {
    models: {
      isVerifyingCode,
    },
  };
};

export default useVerifySSOCode;
