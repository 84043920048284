import { GifRatingsProps } from '../atomic/atoms/Giphy/interface';
import { ChallengePermission } from '../queries/Admin/Challenges/interface';

export enum AssemblyCurrencyType {
  REGULAR = 'REGULAR',
  CUSTOM = 'CUSTOM',
}

export enum PrivateMessageAndImpactLevelValues {
  MANAGER_ONLY = 'MANAGER_ONLY',
  ADMINS_ONLY = 'ADMINS_ONLY',
  MANAGER_AND_CUSTOM_ALLOWANCES = 'MANAGER_AND_CUSTOM_ALLOWANCES_ONLY',
  EVERYONE = 'EVERYONE',
}

export interface AssemblyCurrency {
  name: string;
  pluralName: string;
  type: AssemblyCurrencyType;
  value?: string;
}

export enum CurrencyLevel {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  HUGE = 'huge',
  ABOVE = 'above',
}

export enum CreditState {
  UNVERIFIED_BY_ADMIN = 'UNVERIFIED_BY_ADMIN',
  VERIFIED_BY_ADMIN = 'VERIFIED_BY_ADMIN',
  DENIED_BY_ADMIN = 'DENIED_BY_ADMIN',
  VERIFIED = 'VERIFIED',
}

export interface AssemblySettings {
  userManagement: {
    approvalAccess: {
      enabled: boolean;
      value: string[];
    };
    inviteAccess: {
      enabled: boolean;
      value: string[];
    };
    canLoginUsingLink: {
      enabled: boolean;
    };
  };
  coreValues: {
    enabled: boolean;
    value: string[];
  };
  postImpactLevel: {
    enabled: boolean;
    value: {
      customType: PrivateMessageAndImpactLevelValues;
      levels: { percentage: number; name: CurrencyLevel }[];
    };
  };
  privateMessage: {
    enabled: boolean;
    value: PrivateMessageAndImpactLevelValues;
  };
  minMessageChar: {
    enabled: boolean;
    value: number;
  };
  gifAccessibility: {
    enabled: boolean;
    value: GifRatingsProps;
  };
  blockWordsMessage: {
    enabled: boolean;
    value: string[];
  };
  canDeletePosts: {
    enabled: boolean;
    value: string;
  };
  activeSpecialMention: string;
  badgeAttachability: { enabled: boolean };
  imageAttachability: { enabled: boolean };
  collection: {
    sharing: {
      enabled: boolean;
      value: string;
    };
  };
  challenge: {
    creation: {
      enabled: boolean;
      value: ChallengePermission;
    };
  };
}

interface AssemblyAccounts {
  creditState: CreditState;
  rewards: {
    isChargeable: boolean;
  };
}

interface AssemblyFlow {
  creation: {
    enabled: boolean;
    value: string;
  };
  gifAccessibility: {
    value: GifRatingsProps;
    enabled: boolean;
  };
  participationCriteriaAccessibility: {
    everyone: Array<string>;
  };
  contentStackAccessibility: {
    GIVE_POINTS_STACK: Array<string>;
  };
  announcementPermission: {
    value: string;
    enabled: boolean;
  };
  flowOwnerPermissionForBoostAllowance: {
    value: string;
    enabled: boolean;
  };
}

type WorkspaceSlug = {
  name: string;
  shortCode: string;
};

export interface Assembly {
  assemblyId: string;
  domain: string;
  logo: string;
  name: string;
  exchangeRate: string;
  timeZone: string;
  currency: AssemblyCurrency;
  settings: AssemblySettings;
  accounts: AssemblyAccounts;
  flow?: AssemblyFlow;
  whitelistedDomains?: string[];
  workspaceSlug: WorkspaceSlug;
}
