import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';

import ThemeV2 from '../../../componentsV2/theme';
import Chip from '../Chip';
import DropdownListItem from '../DropdownListItem';
import { PropsClasses } from '../../../interfaces/ClassesProps';
import { AvatarProps } from '../../atoms/Avatar/interfaces';

const useStyles = makeStyles({
  autoCompleteStyle: {
    margin: '0',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    '& .MuiAutocomplete-listbox': {
      padding: '.5rem',
      backgroundColor: ThemeV2.palette.gray1,
    },
    '& li': {
      transition: 'all .3s',
      '&:hover, &:focus, &:visited': {
        backgroundColor: ThemeV2.palette.geekBlue1,
      },
      '&.MuiAutocomplete-option[data-focus="true"], &.MuiAutocomplete-option[aria-selected="true"]':
        {
          backgroundColor: ThemeV2.palette.geekBlue1,
        },
    },
    '& .MuiAutocomplete-option': {
      padding: 0,
      margin: 0,
    },
  },
  textFieldRoot: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 .75rem !important',
      color: ThemeV2.palette.gray8,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '2px',
      borderColor: ThemeV2.palette.gray5,
      transition: 'all .3s',
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          color: ThemeV2.palette.gray6,
          opacity: 1,
        },
      },
      '&:hover': {
        borderColor: ThemeV2.palette.geekBlue5,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiAutocomplete-input': {
        fontSize: '1rem',
        height: '2rem',
        lineHeight: '2rem',
        margin: '.25rem 0',
        padding: '0 !important',
      },
      '& .MuiAutocomplete-tag': {
        color: ThemeV2.palette.gray10,
        backgroundColor: ThemeV2.palette.gray3,
        height: '2rem',
        lineHeight: '2rem',
        borderRadius: '100px',
        fontSize: '.75rem',
        alignSelf: 'center',
        textAlign: 'center',
      },
    },
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
    '& .Mui-focused': {
      boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
      borderColor: ThemeV2.palette.geekBlue6,
    },
  },
  endAdornment: {
    display: 'none',
  },
});

export interface OptionsProps {
  displayText: string;
  id: string;
  secondaryText?: string;
  img?: string;
  avatar?: AvatarProps;
}

interface DropdownProps {
  name: string;
  value: OptionsProps[] | OptionsProps | null;
  classes?: PropsClasses;
  placeholder?: string;
  options: OptionsProps[];
  isLoading?: boolean;
  onChange: (value: OptionsProps[] | OptionsProps | null) => void;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputFocus?: () => void;
  noOptionsText?: string;
  limitTags?: number;
  disableLimit?: boolean;
  isMultiSelectEnable?: boolean;
}

const DropdownWithMultiSelect = (props: DropdownProps) => {
  const classes = useStyles(props);
  const {
    name,
    value,
    placeholder,
    options,
    isLoading = false,
    onChange,
    onInputChange,
    onInputFocus,
    noOptionsText,
    limitTags = 2,
    disableLimit = false,
    isMultiSelectEnable = true,
  } = props;

  const onAutoChange = useCallback(
    (
      event: React.ChangeEvent<unknown>,
      valueChanged: OptionsProps[] | OptionsProps | null,
    ) => {
      onChange(valueChanged);
      if (onInputChange) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        onInputChange({ target: { value: '' } });
      }
    },
    [onChange, onInputChange],
  );

  const renderOptionLabel = useCallback((option: OptionsProps) => {
    return option.displayText;
  }, []);

  const renderOptions = useCallback((option: OptionsProps) => {
    const { displayText, img, secondaryText, avatar } = option;
    return (
      <DropdownListItem
        key={displayText}
        displayText={displayText}
        img={img}
        secondaryText={secondaryText}
        avatar={avatar}
      />
    );
  }, []);

  const renderTags = useCallback(
    (tagValue: OptionsProps[], getTagProps: AutocompleteGetTagProps) => {
      return tagValue.map((option: OptionsProps, index: number) => (
        <Chip
          avatar={option.avatar}
          label={option.displayText}
          key={option.id}
          {...getTagProps({ index })}
        />
      ));
    },
    [],
  );

  const renderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField
        {...params}
        variant="outlined"
        placeholder={placeholder}
        classes={{ root: classes.textFieldRoot }}
        onChange={onInputChange}
        onFocus={onInputFocus}
      />
    ),
    [placeholder, classes.textFieldRoot, onInputChange, onInputFocus],
  );

  const getOptionSelected = useCallback(
    (option, { id }) => option.id === id,
    [],
  );

  return (
    <Autocomplete
      id={name}
      filterSelectedOptions
      multiple={isMultiSelectEnable}
      limitTags={disableLimit ? -1 : limitTags}
      autoHighlight
      value={value}
      onChange={onAutoChange}
      options={options}
      loading={isLoading}
      getOptionLabel={renderOptionLabel}
      renderOption={renderOptions}
      renderTags={renderTags}
      renderInput={renderInput}
      classes={{
        paper: classes.autoCompleteStyle,
        endAdornment: classes.endAdornment,
      }}
      noOptionsText={noOptionsText}
      getOptionSelected={getOptionSelected}
    />
  );
};

const MemoizedDropdownWithMultiSelect = React.memo(DropdownWithMultiSelect);
MemoizedDropdownWithMultiSelect.displayName = 'DropdownWithMultiSelect';

export default MemoizedDropdownWithMultiSelect;
