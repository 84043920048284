export enum ReduxActionTypes {
  API_CALL_BEGIN = '@api/API_CALL_BEGIN',
  API_CALL_SUCCESS = '@api/API_CALL_SUCCESS',
  API_CALL_ERROR = '@api/API_CALL_ERROR',
}

export const apiCallAction = ({
  reduxKey,
  url,
  method,
  params,
  data,
  headers,
}) => ({
  type: ReduxActionTypes.API_CALL_BEGIN,
  payload: {
    reduxKey,
    requestParams: {
      url,
      method,
      params,
      data,
      headers,
    },
  },
});

export const apiCallSuccessAction = ({ reduxKey, response }) => ({
  type: ReduxActionTypes.API_CALL_SUCCESS,
  payload: {
    reduxKey,
    response,
  },
});

export const apiCallErrorAction = ({ reduxKey, error }) => ({
  type: ReduxActionTypes.API_CALL_ERROR,
  payload: {
    reduxKey,
    error,
  },
});
