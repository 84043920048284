import { MemberRole } from '../interfaces/user';

import { capitalizeFirstLetter } from './text';
import { CurrentUser } from '../interfaces/currentUser';

export interface SerializeUserProps {
  profile?: {
    firstName: string;
    lastName: string;
  };
  firstName?: string;
  lastName?: string;
  id?: string | number;
}

export const serializeUser = (user: SerializeUserProps) => {
  const { profile } = user;
  if (profile) {
    return {
      ...user,
      profile: {
        ...profile,
        firstName: capitalizeFirstLetter(profile.firstName),
        lastName: capitalizeFirstLetter(profile.lastName),
      },
    };
  }
  return {
    ...user,
    firstName: capitalizeFirstLetter(user.firstName),
    lastName: capitalizeFirstLetter(user.lastName),
  };
};

export const isUserAdmin = ({ role }: { role: MemberRole[] }) =>
  role.some((_role) => {
    return _role === MemberRole.Admin || _role === MemberRole.Owner;
  });

export const isUserManager = (user: CurrentUser) => user.profile.isManager;

export const setCurrentUserFirstLogin = (isFirstLogin: boolean) => {
  localStorage.setItem('userFirstLogin', JSON.stringify(isFirstLogin));
};
