import React from 'react';
import Fade from '@material-ui/core/Fade';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import makeStyles from '@material-ui/styles/makeStyles';
import { ReferenceObject } from 'popper.js';

const useStyles = makeStyles({
  toggleOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: '99' as any,
  },
  paper: {
    background: '#fff',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    zIndex: 9999,
  },
});

interface DropdownMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl?: null | ReferenceObject | (() => ReferenceObject);
  children: React.ReactNode;
  placement?: PopperPlacementType;
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const { open, onClose, anchorEl, children, placement } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      {open && <div className={classes.toggleOverlay} onClick={onClose}></div>}
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 9999 }}
        placement={placement ? placement : 'bottom'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.paper}>{children}</div>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default DropdownMenu;
