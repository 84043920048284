import axios from 'axios';
import _ from 'lodash';
import { put } from 'redux-saga/effects';
import history from '../history';
import { API_URL_WITH_V2, IP_API_KEY } from '../config';
import { signOutAction } from './Session';
import processErrorResponse, {
  ErrorMessages,
} from '../containers/helpers/ErrorHelper';
import { AuthHeaders } from './shared/HeaderToken';
import { serializeUser, setCurrentUserFirstLogin } from '../Utils/user';
import { adminActions } from './Admin';
import { bootIntercom } from '../Utils/Intercom';
import {
  checkAdmin,
  getAllowanceTypeLiteralForUser,
  getUserStatusTypeLiteralForUser,
} from '../Utils/admin';
import * as Analytics from '../Utils/analytics';
import endpointInfo, { GET_IP } from '../constants/endpoints';
import { datadogLogs } from '@datadog/browser-logs';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  GET_USER_INFO: `${API_URL_WITH_V2}/auth/user/info`,
  UPDATE_USER_ACCOUNT_SETTINGS: `${API_URL_WITH_V2}/user/settings/updateaccount`,
  GET_MY_REWARDS_HISTORY: `${API_URL_WITH_V2}/user/settings/history`,
};

const IMAGE_UPLOAD_STATUS = {
  BLANK: 'BLANK',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

// ------------------------------------
// Constants
// ------------------------------------
const GOT_MY_POINTS_TO_REDEEM = 'got_my_points_to_redeem';
const GOT_MY_POINTS_TO_GIVE = 'got_my_points_to_give';
const GOT_MY_DETAILS = 'got_my_details';
const GOT_MY_IP_DETAILS = 'GOT_MY_IP_DETAILS';
const GOT_MY_REWARDS_HISTORY = 'got_my_rewards_history';
const PROFILE_IMAGE_UPLOAD_SUCCESS = 'PROFILE_IMAGE_UPLOAD_SUCCESS';
const PROFILE_IMAGE_UPLOAD_RESET = 'PROFILE_IMAGE_UPLOAD_RESET';
const PROFILE_IMAGE_UPLOAD_FAILURE = 'PROFILE_IMAGE_UPLOAD_FAILURE';
const REQUEST_FAILED = 'me_request_failed';
// ------------------------------------
// Actions
// ------------------------------------

const gotMyPointsToRedeem = (pointsEarned) => ({
  type: GOT_MY_POINTS_TO_REDEEM,
  pointsEarned,
});

const gotMyPointsToGive = (pointsToGive) => ({
  type: GOT_MY_POINTS_TO_GIVE,
  pointsToGive,
});

const gotMyDetails = (me) => ({
  me,
  type: GOT_MY_DETAILS,
});

const gotMyIPDetails = (details) => ({
  type: GOT_MY_IP_DETAILS,
  details,
});

const gotMyRewardsHistory = (myRewardsHistory) => ({
  myRewardsHistory,
  type: GOT_MY_REWARDS_HISTORY,
});

const profileImageUploadedSuccessfully = () => ({
  type: PROFILE_IMAGE_UPLOAD_SUCCESS,
});
const profileImageUploadFailed = () => ({
  type: PROFILE_IMAGE_UPLOAD_FAILURE,
});

const resetImageUpload = () => ({
  type: PROFILE_IMAGE_UPLOAD_RESET,
});

const requestFailed = (errorMessage) => ({
  type: REQUEST_FAILED,
  error: errorMessage,
});

export const meActions = {
  gotMyPointsToRedeem,
  gotMyPointsToGive,
  requestFailed,
  gotMyDetails,
  gotMyRewardsHistory,
  resetImageUpload,
  profileImageUploadedSuccessfully,
  profileImageUploadFailed,
  gotMyIPDetails,
};

const processError = (error: any = {}, dispatch) => {
  const statusCode = error.response ? error.response.status : 0;
  switch (statusCode) {
    case 400: // bad request
      dispatch(meActions.requestFailed(ErrorMessages.serverError));
      break;
    case 403: // Forbidden
      //  redirect to home
      history.push('/404');
      break;
    case 404: // Page not found
      dispatch(meActions.requestFailed(ErrorMessages.serverError));
      break;
    //  mostly my error
    case 401: // Not authorised
      dispatch(signOutAction());
      break;
    case 422:
    /* falls through */
    case 500:
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const msg = processErrorResponse(responseData);
        dispatch(meActions.requestFailed(msg));
      }
      break;
    default:
      dispatch(meActions.requestFailed(ErrorMessages.networkError));
      break;
  }
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const getUpdatedCurrencyAction = () => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.GET_USER_INFO, AuthHeaders());
    const result = res.data;
    if (result.success) {
      const { currency } = result.data;
      dispatch(adminActions.gotCompanyDetails({ currency }));
    }
  } catch (error) {
    processError(error, dispatch);
  }
};

export const updateMyDetails = (me, dispatch) => {
  localStorage.setItem('user', JSON.stringify(me));
  if (me && me.isFirstLogin !== undefined && me.isFirstLogin !== null) {
    setCurrentUserFirstLogin(me.isFirstLogin);
  }

  const firstName = _.get(me, ['profile', 'firstName'], '');
  const lastName = _.get(me, ['profile', 'lastName'], '');
  const name = `${firstName} ${lastName}`;
  const assemblyID = _.get(me, ['employerId'], '');

  if (me.user_hash) {
    bootIntercom({
      email: me.email,
      user_hash: me.user_hash,
      user_id: me._id,
      name,
      firstName,
      lastName,
      isAdmin: checkAdmin(me.role),
      externalId: me._id,
      userShoutoutStatus: getUserStatusTypeLiteralForUser(me),
      allowanceType: getAllowanceTypeLiteralForUser(me),
    });
  }

  Analytics.identifyUserForAnalytics(me._id, {
    email: me.email,
    name,
    isAdmin: checkAdmin(me.role),
    assemblyID,
  });

  if (dispatch) {
    dispatch(meActions.gotMyDetails(me));
  }
};

export const getMyIPDetails = () => async (dispatch) => {
  try {
    fetch(`${endpointInfo[GET_IP].endpointURL}?key=${IP_API_KEY}`)
      .then((response) => {
        response.json().then((result) => {
          if (result.country) {
            //  country_code
            dispatch(meActions.gotMyIPDetails({ countryCode: result.country }));
          }
        });
      })
      .catch((err) => {
        datadogLogs.logger.error(
          'error fetching country details',
          undefined,
          err,
        );
      });
  } catch (error) {
    processError(error, dispatch);
  }
};

export const getUserInfoAction = () => async (dispatch) => {
  try {
    // @ts-ignore
    const me = JSON.parse(localStorage.getItem('user'));

    updateMyDetails(me, dispatch);

    const res = await axios.get(apiEndpoints.GET_USER_INFO, AuthHeaders());
    const result = res.data;
    if (result.success) {
      const { user, employer, currency, intercomUserHash } = result.data;
      const updatedUser = { ...user, user_hash: intercomUserHash };
      dispatch(meActions.gotMyPointsToRedeem(user.pointsEarned));
      updateMyDetails(updatedUser, dispatch);
      dispatch(adminActions.gotMyEmployer(employer));
      dispatch(adminActions.gotCompanyDetails({ currency }));
    }
  } catch (error) {
    processError(error, dispatch);
  }
};

export function* userInfoSaga() {
  const res = yield axios.get(apiEndpoints.GET_USER_INFO, AuthHeaders());
  const result = res.data;
  if (result.success) {
    const { user, employer, currency } = result.data;
    yield put(meActions.gotMyPointsToRedeem(user.pointsEarned));
    updateMyDetails(user, null);
    yield put(meActions.gotMyDetails(user));
    yield put(adminActions.gotMyEmployer(employer));
    yield put(adminActions.gotCompanyDetails({ currency }));
  }
}

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case GOT_MY_POINTS_TO_REDEEM:
      return { ...state, pointsEarned: action.pointsEarned };
    case GOT_MY_POINTS_TO_GIVE:
      return { ...state, pointsToGive: action.pointsToGive };
    case GOT_MY_DETAILS: {
      const me = serializeUser(action.me);
      return { ...state, me };
    }
    case GOT_MY_IP_DETAILS:
      return {
        ...state,
        ipDetails: { countryCode: action.details.countryCode },
      };
    case GOT_MY_REWARDS_HISTORY:
      return { ...state, myRewardsHistory: action.myRewardsHistory };
    case PROFILE_IMAGE_UPLOAD_SUCCESS:
      return { ...state, imageUploadStatus: IMAGE_UPLOAD_STATUS.SUCCESS };
    case PROFILE_IMAGE_UPLOAD_FAILURE:
      return { ...state, imageUploadStatus: IMAGE_UPLOAD_STATUS.FAILED };
    case PROFILE_IMAGE_UPLOAD_RESET:
      return { ...state, imageUploadStatus: IMAGE_UPLOAD_STATUS.BLANK };
    case REQUEST_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
