import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Sidebar from './navbar/Sidebar';
import HeaderWithMenu from '../../header/HeaderWithMenu';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';

const useStyles = makeStyles({
  drawerPaper: {
    width: '250px',
    background: '#fafafa', //  this.props.colorSidebar.color,
    marginTop: '60px',
    zIndex: 1000,
    paddingLeft: '20px',
    boxShadow: '',
    borderRight: 'none',
  },
  fullList: {
    width: 'auto',
  },
});

const SidebarMenuRouters = () => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [shouldShowMenuToggle, setShouldShowMenuToggle] = useState(false);

  // TOGGLE HANDLE FROM PARENT TO CHILD ----------- START
  const alertParent = () => {
    // this.props.onChangeMenu(this.state.menuOpen);
  };

  // TOGGLE HANDLE FROM PARENT TO CHILD ----------- START
  const setMenuFalse = () => {
    setMenuOpen(false);
    setShouldShowMenuToggle(true);
    alertParent();
  };

  const setMenuTrue = () => {
    setMenuOpen(true);
    setShouldShowMenuToggle(false);
    alertParent();
  };

  // menu collapse when on mobile function
  const menuCollapseWithResize = () => {
    // this.props.dispatch(uiActions.resizeWindow(window.innerWidth));
    if (window.innerWidth < 991) {
      setMenuFalse();
    }
    if (window.innerWidth > 991) {
      setMenuTrue();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', menuCollapseWithResize);
    // this.props.dispatch(uiActions.resizeWindow(window.innerWidth));
    if (window.innerWidth < 991) {
      setMenuFalse();
    }

    // Unmount: cleanup
    return () => {
      window.removeEventListener('resize', menuCollapseWithResize);
    };
  }, []);

  // Sidebar toggle
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    alertParent();
  };
  // TOGGLE HANDLE FROM PARENT TO CHILD ----------- END

  const classes = useStyles();

  // Sidebar class based on bg color
  // const sidebarClass = classNames({
  //   'menu-drawer': true,
  //   'has-bg': true,
  // });

  const { data: profileData } = useProfileInfoFetchQuery();
  const workspaceSlug = useMemo(() => {
    if (!profileData?.assembly.workspaceSlug) return null;
    const { shortCode, name } = profileData?.assembly.workspaceSlug;
    return `${name}-${shortCode}`;
  }, [profileData]);

  return (
    <div>
      <HeaderWithMenu
        shouldRedirectToAssemblyV3={true}
        shouldShowMenuToggle={shouldShowMenuToggle}
        workspaceSlug={workspaceSlug}
        toggleMenu={toggleMenu}
      />
      <Drawer
        open={menuOpen}
        variant="persistent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div role="presentation">
          <Sidebar />
        </div>
      </Drawer>
    </div>
  );
};

export default SidebarMenuRouters;
