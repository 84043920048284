import type { History } from 'history';
import { Component } from 'react';
import { checkSuperAdmin } from '../../Utils/admin';

const SuperAdminAuthorization = () => (WrappedComponent: typeof Component) =>
  class WithSuperAdminAuthorization extends Component<{ history: History }> {
    componentDidMount() {
      const storedUserDetails = localStorage.getItem('user');
      if (storedUserDetails) {
        const user = JSON.parse(storedUserDetails);
        const isSuperAdmin = checkSuperAdmin(user);
        if (isSuperAdmin === false) {
          this.props.history.push('/404');
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default SuperAdminAuthorization;
