import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dropdown from '../../atoms/Dropdown';
import { Item } from '../../atoms/Dropdown/interfaces';

type OptionsListPropType = {
  placeholder: string;
  icon: string;
  menuItems: Item[];
  width: string;
  filterPlaceholder: string;
}[];

type DropdownGroupProps = {
  optionsList: OptionsListPropType;
  onChangeList: any;
  selectedOptions: string[] | number[];
  isFilter?: boolean;
  className?: string;
};

const useStyles = makeStyles(({ optionsList }: DropdownGroupProps) => ({
  root: {
    '&:first-child': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: optionsList.length === 1 ? '2px' : '2px 0px 0px 2px',
      },
    },
    '&:nth-child(2)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: optionsList.length === 3 ? '0px' : '0px 2px 2px 0px',
      },
    },
    '&:nth-child(3)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0px 2px 2px 0px',
      },
    },
  },
}));

const DropdownGroup = (props: DropdownGroupProps) => {
  const {
    selectedOptions: [],
    isFilter = false,
    className: parentClassName = '',
    optionsList,
    onChangeList,
    selectedOptions,
  } = props;
  const classes = useStyles(props);
  const className = `${classes.root} ${parentClassName}`;

  return (
    <>
      {optionsList.map((items, index) => {
        const { menuItems, placeholder, icon, width, filterPlaceholder } =
          items;
        return (
          <Dropdown
            key={`${placeholder}-${width}-${icon}`}
            menuItems={menuItems}
            onChange={onChangeList[index]}
            selectedMenuItemId={selectedOptions[index]}
            width={width}
            isFilter={isFilter}
            placeholder={placeholder}
            filterPlaceholder={filterPlaceholder}
            icon={icon}
            className={className}
          />
        );
      })}
    </>
  );
};

export default DropdownGroup;
