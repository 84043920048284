import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  GET_PROFILE_INFO,
  GET_CURRENT_PLAN,
  UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS,
  USER_VERIFY_TOKEN,
  UPDATE_COLLECTION_SETTINGS,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { Assembly as AssemblyType } from '../../interfaces/assembly';
import { CurrentUser } from '../../interfaces/currentUser';
import { AssemblyPlanType } from '../../interfaces/UserProfile';
import { ResponseError } from '../../interfaces/ResponseError';
import { GifRatingsProps } from '../../atomic/atoms/Giphy/interface';
import { setAssemblyFlowPermissionValue } from './utils';
import { showSuccessMessage } from '../../Utils/toast';
import { FLOWS_PERMISSIONS_TEXTS } from '../../languages/en/settings/admin/flows';
import {
  AuthSuccessResponse,
  AxiosAuthError,
} from '../../interfaces/Auth/ResponseTypes';
import { AxiosError } from 'axios';

export interface GetProfileInfoResponse {
  member: CurrentUser;
  assembly: AssemblyType;
}

export interface CurrentPlanResponse {
  name: AssemblyPlanType;
  features: {
    category: string;
    id: string;
    isPaymentRequired: boolean;
    status: string;
    value: number;
  }[];
  uniqueId?: string;
}

type UpdateFlowsPermissionsPayload = {
  creationPermission?: { value?: string };
  gifAccessibility?: { value: GifRatingsProps };
  criteriaAccessibilityForParticipation?: {
    everyone: Array<string | undefined>;
  };
  stackAccessibility?: {
    GIVE_POINTS_STACK: Array<string | undefined>;
  };
  flowOwnerPermissionForBoostAllowance?: {
    value: string;
  };
  announcementPermission?: { value: string };
  sharing?: { value: string };
};

export const useCurrentPlanFetchQuery = (makeRequest = true) => {
  return useQuery<CurrentPlanResponse>(
    GET_CURRENT_PLAN,
    () => makeAPICallWithDataReturn(GET_CURRENT_PLAN),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};

export const useProfileInfoFetchQuery = (makeRequest = true) => {
  return useQuery<GetProfileInfoResponse, ResponseError>(
    GET_PROFILE_INFO,
    () => makeAPICallWithDataReturn(GET_PROFILE_INFO),
    {
      staleTime: 60 * 30 * 1000,
      enabled: makeRequest,
    },
  );
};

export const useUserVerifyTokenQuery = (token?: string, makeRequest = true) => {
  return useQuery<AuthSuccessResponse, AxiosAuthError>(
    USER_VERIFY_TOKEN,
    () => makeAPICallWithDataReturn(USER_VERIFY_TOKEN, { token }),
    {
      staleTime: 60 * 30 * 1000,
      enabled: makeRequest,
    },
  );
};

export const useUpdateFlowSettings = (
  handleErrorCallback: (e: AxiosError) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateFlowsPermissionsPayload) =>
      makeAPICall(UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS, payload),
    {
      onSuccess: (data, payload) => {
        showSuccessMessage(
          FLOWS_PERMISSIONS_TEXTS.PERMISSION_CHANGED_SUCCESSFULLY,
        );
        const cacheObject: GetProfileInfoResponse | undefined =
          queryClient.getQueryData(GET_PROFILE_INFO);
        if (!cacheObject) {
          return;
        }
        if (!cacheObject.assembly.flow) {
          return;
        }
        const updatedCacheObject = { ...cacheObject };
        if (!updatedCacheObject.assembly.flow) {
          return;
        }

        updatedCacheObject.assembly.flow.creation.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.creation.value,
            payload.creationPermission?.value,
          );

        updatedCacheObject.assembly.flow.participationCriteriaAccessibility.everyone.splice(
          0,
          1,
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.participationCriteriaAccessibility
              .everyone[0],
            payload.criteriaAccessibilityForParticipation?.everyone[0],
          ),
        );

        updatedCacheObject.assembly.flow.contentStackAccessibility.GIVE_POINTS_STACK.splice(
          0,
          1,
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.contentStackAccessibility
              .GIVE_POINTS_STACK[0],
            payload.stackAccessibility?.GIVE_POINTS_STACK[0],
          ),
        );

        updatedCacheObject.assembly.flow.gifAccessibility.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.gifAccessibility.value,
            payload.gifAccessibility?.value,
          ) as GifRatingsProps;

        updatedCacheObject.assembly.flow.flowOwnerPermissionForBoostAllowance.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow
              .flowOwnerPermissionForBoostAllowance.value,
            payload.flowOwnerPermissionForBoostAllowance?.value,
          );

        updatedCacheObject.assembly.flow.announcementPermission.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.announcementPermission.value,
            payload.announcementPermission?.value,
          );
        queryClient.setQueryData(GET_PROFILE_INFO, updatedCacheObject);
      },
      onError: (e: AxiosError) => {
        handleErrorCallback(e);
        const cacheObject: GetProfileInfoResponse | undefined =
          queryClient.getQueryData(GET_PROFILE_INFO);
        queryClient.setQueryData(GET_PROFILE_INFO, cacheObject);
      },
    },
  );
};

export const useUpdateCollectionSettings = (
  handleErrorCallback?: (e: AxiosError) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateFlowsPermissionsPayload) =>
      makeAPICall(UPDATE_COLLECTION_SETTINGS, payload),
    {
      onSuccess: (data, payload) => {
        showSuccessMessage(
          FLOWS_PERMISSIONS_TEXTS.PERMISSION_CHANGED_SUCCESSFULLY,
        );
        const cacheObject: GetProfileInfoResponse | undefined =
          queryClient.getQueryData(GET_PROFILE_INFO);
        if (!cacheObject || !cacheObject.assembly.flow) {
          return;
        }

        const updatedCacheObject = { ...cacheObject };

        updatedCacheObject.assembly.settings.collection.sharing.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.settings.collection.sharing.value,
            payload.sharing?.value,
          );

        queryClient.setQueryData(GET_PROFILE_INFO, updatedCacheObject);
      },
      onError: (e: AxiosError) => {
        handleErrorCallback?.(e);
        const cacheObject: GetProfileInfoResponse | undefined =
          queryClient.getQueryData(GET_PROFILE_INFO);
        queryClient.setQueryData(GET_PROFILE_INFO, cacheObject);
      },
    },
  );
};
