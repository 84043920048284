import { v4 as uuidv4 } from 'uuid';

import { RangeFilterValue } from '../../../controllers/admin/users/ManageUsersController/types';

const INITIAL_STATE = {
  name: '',
  email: '',
  department: [],
  status: '',
  allowanceType: '',
  isAdmin: '',
};

// ------------------------------------
// Constants
// ------------------------------------

const ADMIN_TABLE_INITIALIZE_FILTER = '@@ADMIN_TABLE/INITIALIZE_FILTER';
const ADMIN_TABLE_APPLY_FILTER = '@@ADMIN_TABLE/APPLY_FILTER';
const ADMIN_TABLE_REMOVE_FILTER = '@@ADMIN_TABLE/REMOVE_FILTER';
const ADMIN_TABLE_REMOVE_ALL_FILTERS =
  '@@ADMIN_TABLE/ADMIN_TABLE_REMOVE_ALL_FILTERS';
const ADMIN_TABLE_UPDATE_INPUT = '@@ADMIN_TABLE/UPDATE_INPUT';
const ADMIN_TABLE_RESET_INPUTS = '@@ADMIN_TABLE/RESET_INPUTS';

// ------------------------------------
// Reducers
// ------------------------------------

export default (
  state: Record<string, any> = {},
  action: {
    type?: any;
    tableName: string;
    defaultValues?: any;
    field?: any;
    value?: any;
    filter?: any;
  },
) => {
  switch (action.type) {
    case ADMIN_TABLE_INITIALIZE_FILTER: {
      const { tableName, defaultValues = {} } = action;
      return {
        ...state,
        currentTable: tableName,
        [tableName]: {
          filters: {},
          values: INITIAL_STATE,
          defaultValues,
        },
      };
    }
    case ADMIN_TABLE_RESET_INPUTS: {
      const { tableName } = action;
      return {
        ...state,
        [tableName]: { ...state[tableName], values: INITIAL_STATE },
      };
    }
    case ADMIN_TABLE_UPDATE_INPUT: {
      const { tableName, field, value } = action;
      const values = state[tableName] ? state[tableName].values : {};
      return {
        ...state,
        [tableName]: {
          ...state[tableName],
          values: { ...values, [field]: value },
        },
      };
    }
    case ADMIN_TABLE_APPLY_FILTER: {
      const { tableName, filter } = action;
      const filters = state[tableName] ? state[tableName].filters : {};
      const id = uuidv4();
      return {
        ...state,
        [tableName]: {
          ...state[tableName],
          filters: { ...filters, ...filter },
          id,
        },
      };
    }
    case ADMIN_TABLE_REMOVE_FILTER: {
      const { tableName, filter } = action;
      const filters = state[tableName] ? state[tableName].filters : {};
      const { [filter]: value, ...rest } = filters;
      const id = uuidv4();
      return {
        ...state,
        [tableName]: { ...state[tableName], filters: rest, id },
      };
    }
    case ADMIN_TABLE_REMOVE_ALL_FILTERS: {
      const { tableName } = action;
      const id = uuidv4();
      return {
        ...state,
        [tableName]: { ...state[tableName], filters: {}, id },
      };
    }
    default:
      return state;
  }
};

export const initializeNewFilter = (tableName: string, defaultValues = {}) => ({
  type: ADMIN_TABLE_INITIALIZE_FILTER,
  tableName,
  defaultValues,
});

export const resetFilterInputValues = (tableName: string) => ({
  type: ADMIN_TABLE_RESET_INPUTS,
  tableName,
});

export const applyFilter = (tableName: string, filter: object) => ({
  type: ADMIN_TABLE_APPLY_FILTER,
  tableName,
  filter,
});

export const removeFilter = (tableName: string, filter: string) => ({
  type: ADMIN_TABLE_REMOVE_FILTER,
  tableName,
  filter,
});

export const removeAllFilters = (tableName: string) => ({
  type: ADMIN_TABLE_REMOVE_ALL_FILTERS,
  tableName,
});

export const updateInput = (
  tableName: string,
  field: string,
  value: string | string[] | RangeFilterValue | null | undefined,
) => ({
  type: ADMIN_TABLE_UPDATE_INPUT,
  tableName,
  field,
  value,
});
