import { CurrentPlanResponse, GetProfileInfoResponse } from '.';
import {
  AssemblyCurrency,
  AssemblyCurrencyType,
} from '../../interfaces/assembly';
import { isFeatureEnabled } from '../../Utils/admin/account';

export const defaultCurrency: AssemblyCurrency = {
  name: 'Trophy',
  pluralName: 'Trophies',
  type: AssemblyCurrencyType.REGULAR,
  value: '1F3C6',
};

export const getAssemblyCurrency = (profileData?: GetProfileInfoResponse) => {
  if (profileData) {
    return profileData.assembly.currency;
  }
  return defaultCurrency;
};

export const isFeatureEnabledInCurrentPlan = (
  feature: string,
  currentPlan?: CurrentPlanResponse,
) => {
  if (!currentPlan) {
    return false;
  }
  return isFeatureEnabled(currentPlan.features, feature);
};

export const setAssemblyFlowPermissionValue = (
  cacheValue: string,
  payloadValue: string | undefined,
) => {
  if (payloadValue) {
    if (cacheValue !== payloadValue) {
      return payloadValue;
    }
  }
  return cacheValue;
};
