import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import SignOutIcon from '@material-ui/icons/PowerSettingsNew';
import { getFullName } from '../../Utils/text';
import { checkAdmin } from '../../Utils/admin';
import { ProfilePic } from '../../Utils/profilePic';
import DropdownMenu from '../../componentsV2/custom/DropdownMenu';
import { APP_URL, NEW_APP_URL } from '../../config';

const styles = (theme: Record<string, any>) => ({
  typography: {
    margin: theme.spacing.unit * 2,
  },
  menuItem: {},
  menuPopoverStyle: {
    minWidth: 168,
  },
});

interface AvatarDropdownProps {
  classes: {
    menuItem: string;
    icon?: string;
  };
  me: { role: string; profile: any };
  shouldRedirectToAssemblyV3?: boolean;
  workspaceSlug?: string | null;
}

const AvatarDropdown = (props: AvatarDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: {
    preventDefault: () => void;
    currentTarget: any;
  }) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const { classes, me, shouldRedirectToAssemblyV3, workspaceSlug } = props;
  const { role, profile } = me;
  let fullName = '';
  if (profile) {
    fullName = getFullName(profile);
  } else {
    fullName = getFullName(me);
  }

  const handleSettingsClick = () => {
    if (workspaceSlug && shouldRedirectToAssemblyV3) {
      window.location.href = `${NEW_APP_URL}/${workspaceSlug}/settings/general`;
    } else {
      window.location.href = `${APP_URL}settings/general`;
    }
  };

  // @ts-ignore
  const isAdmin = checkAdmin(role);
  return (
    <div>
      <Button
        component="div"
        onClick={handleClick}
        className="user-drop-down__div"
      >
        <ProfilePic size={35} user={me} />
        <span className="username-drop-down__span">{`${fullName}`}</span>
        <DownArrowIcon />
      </Button>
      <DropdownMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuList>
          {isAdmin && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                history.push('/settings');
              }}
            >
              <ListItemIcon className={classes.icon}>
                <AccountCircleIcon />
              </ListItemIcon>
              <Typography variant="inherit">Admin</Typography>
            </MenuItem>
          )}
          <MenuItem className={classes.menuItem} onClick={handleSettingsClick}>
            <ListItemIcon className={classes.icon}>
              <SettingsIcon />
            </ListItemIcon>
            <Typography variant="inherit">Settings</Typography>
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              window.location.href = `${APP_URL}signout`;
            }}
          >
            <ListItemIcon className={classes.icon}>
              <SignOutIcon />
            </ListItemIcon>
            <Typography variant="inherit">Sign Out</Typography>
          </MenuItem>
        </MenuList>
      </DropdownMenu>
    </div>
  );
};
const mapStateToProps = (state: { me: { me: any } }) => ({
  me: state.me.me,
});
const StyledAvatarDropdown = withStyles(styles)(AvatarDropdown);

export default connect(mapStateToProps)(StyledAvatarDropdown);
