export enum IntegrationProvidersEnum {
  SLACK = 'SLACK',
  MSTEAM = 'MSTEAM',
}

export enum ConnectionStatusEnum {
  AUTHORIZED = 'AUTHORIZED',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  CONFIGURATION_COMPLETE = 'CONFIGURATION_COMPLETE',
  CONNECTED = 'CONNECTED',
}

export enum IntegrationStatusEnum {
  ACTIVE = 'ACTIVE',
  COMING_SOON = 'COMING_SOON',
}

export interface BotStatusResponse {
  id: IntegrationProvidersEnum;
  status: ConnectionStatusEnum;
  integrationStatus: IntegrationStatusEnum;
  metaData?: {
    welcomeMessageSent: boolean;
    activeChannel: {
      id: string;
      name: string;
    };
    slackTeam?: {
      id: string;
      name: string;
    };
    msteam?: {
      id: string;
      name: string;
    };
  };
}

export interface BotDetailsHelperLink {
  label: string;
  url: string;
}

export interface BotDetailsItem {
  id: IntegrationProvidersEnum;
  logo: string;
  displayText: string;
  buttonText: string;
  warningText: string;
  redirectURL?: string;
  isBotConnected: boolean;
  isWelcomeMessageSent: boolean;
  activeChannel?: {
    id: string;
    displayText: string;
  };
  slackTeam?: {
    id: string;
    name: string;
  };
  msteam?: {
    id: string;
    name: string;
  };
  disabled: boolean;
  helperLink?: BotDetailsHelperLink;
  isExternalRedirection?: boolean;
}

export interface IntegrationsPresentationalProps {
  botsDetails: [BotDetailsItem];
  isRequestLoading: boolean;
  onActionClick: (id: string) => void;
  errorMessage: string | null;
  onDisconnectSuccess: () => void;
  hideError: () => void;
  onKioskActionClick: () => void;
  isKioskTurnedOn: boolean;
}
