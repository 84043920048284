import * as EmailValidator from 'email-validator';

const isValidFullNameWithSpaces = (fullname: string) => {
  if (fullname) {
    const trimmedName = fullname.trim();
    if (trimmedName.match(/^.*\s.*$/)) {
      return true;
    }
  }
  return false;
};

const isValidEmail = (email: string) => EmailValidator.validate(email);

export { isValidEmail, isValidFullNameWithSpaces };
