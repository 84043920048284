import moment from 'moment-timezone';

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const BEGIN_YEAR = 1972;

export const getMonthFromDateObject = (dateObject) => {
  if (!dateObject.month && !dateObject.monthNumber) {
    return '';
  }
  if (dateObject.month) {
    return dateObject.month;
  }
  return moment.months(dateObject.monthNumber - 1);
};

export const getMonthsToShow = () => {
  return moment.months().map((mon) => {
    return {
      value: mon,
      label: mon,
    };
  });
};

const isFeb = (month) => moment().month(month).month() === 1;

export const getDaysForMonth = (month: string | number): any[] => {
  const monthMoment = moment([1971, moment().month(month).month()]);
  const daysInMonth = isFeb(month)
    ? monthMoment.daysInMonth() + 1
    : monthMoment.daysInMonth();
  return Array.from({ length: daysInMonth }, (v, k) => ({
    value: k + 1,
  }));
};

export const getDateFromDateObject = (dateObject): any => {
  if (!dateObject) {
    return null;
  }
  const { month, monthNumber, year = BEGIN_YEAR, date, day } = dateObject;

  if (day && month && year) {
    // this is to support the latest data structure[V3 API]
    return moment(`${year} ${month} ${day}`, 'YYYY MM DD');
  }
  if (month) {
    return moment(`${year} ${month} ${date ? date : day}`, 'YYYY MMMM DD');
  }
  return moment(`${year} ${monthNumber} ${date ? date : day}`, 'YYYY MM DD');
};

export const getDateObjectFromMomentDate = (momentDate) => {
  if (!momentDate) {
    return null;
  }
  const date = momentDate.date();
  const month = momentDate.format('MMMM');
  const monthNumber = Number(momentDate.format('M'));
  const year = Number(momentDate.format('YYYY'));
  return {
    date,
    month,
    monthNumber,
    year,
  };
};

export const validateMonthDayAndYear = (month, day, year = BEGIN_YEAR) => {
  const monthMoment = moment([year, moment().month(month).month(), day]);
  return monthMoment.isValid();
};

export const getMonthByNumber = (monthNumber) => {
  const monthsMap = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  return monthsMap[monthNumber];
};

export const changeTimezone = (date, ianatz = DEFAULT_TIMEZONE) => {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
};

export const getMonthNumbers = (months: string[]) => {
  const monthNumberMap = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  return months.map((month) => monthNumberMap[month]);
};

export const getAbbreviatedMonthByNumber = (monthNumber) => {
  const abbreviatedMonthsMap = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  return abbreviatedMonthsMap[monthNumber];
};

const convertTZ = (date: Date | string, tzString: string) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    }),
  );
};

export const getCurrentDateForTimeZone = (timeZone: string): Date => {
  const timeZoneCurrentDate = convertTZ(new Date().toISOString(), timeZone);
  return timeZoneCurrentDate;
};
