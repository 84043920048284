import { requestsReducer, success } from 'redux-saga-requests';
import { HeadersWithoutAuthV2 } from '../../../modules/shared/HeaderToken';
import { API_URL_WITH_V2 } from '../../../config';

const apiEndpoints = {
  CREATE_ACCOUNT: `${API_URL_WITH_V2}/auth/onboard/register`,
  VERIFY_CODE: `${API_URL_WITH_V2}/auth/onboard/verifyCode`,
  RESEND_CODE: `${API_URL_WITH_V2}/auth/onboard/resendCode`,
  COMPLETE_PROFILE: `${API_URL_WITH_V2}/auth/onboard/completeProfile`,
  INVITE_EMAILS: `${API_URL_WITH_V2}/employer/employee/invite/emails`,
};

const CREATE_ACCOUNT = '@sessions/V2/CREATE_ACCOUNT';
export const VERIFY_CODE = '@sessions/V2/VERIFY_CODE';
export const RESEND_CODE = '@sessions/V2/RESEND_CODE';
const SET_COMPANY_NAME = '@sessions/V2/SET_COMPANY_NAME';
export const COMPLETE_PROFILE = '@sessions/V2/COMPLETE_PROFILE';
export const INVITE_EMAILS = '@sessions/V2/INVITE_EMAILS';

export const verifyCode = ({ userId, codeToVerify }) => ({
  type: VERIFY_CODE,
  request: {
    url: apiEndpoints.VERIFY_CODE,
    data: {
      userId,
      verifyCode: codeToVerify,
    },
    method: 'post',
    ...HeadersWithoutAuthV2(),
  },
});

export default requestsReducer({
  actionType: CREATE_ACCOUNT,
  onSuccess: (state, action) => {
    switch (action.type) {
      case success(CREATE_ACCOUNT):
        return { ...state, email: action.meta.email, data: action.data };
      default:
        return state;
    }
  },
  mutations: {
    [VERIFY_CODE]: { updateData: false },
    [COMPLETE_PROFILE]: { updateData: false },
    [INVITE_EMAILS]: { updateData: false },
    [RESEND_CODE]: { updateData: false },
    [SET_COMPANY_NAME]: {
      updateData: (state, action) => {
        switch (action.type) {
          case SET_COMPANY_NAME: {
            return { ...state.data, companyName: action.companyName };
          }
          default:
            return state;
        }
      },
      local: true,
    },
  },
});
