import React from 'react';
import { number, node, func, shape, string, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactCrop, { Crop, PercentCrop } from 'react-image-crop';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

import 'react-image-crop/dist/ReactCrop.css';

import ImageCropperModal from '../ImageCropperModal_V2';
import { ClassNameMap } from '@material-ui/styles';

type DisplayImageUploaderProps = {
  onSelectFile: (e: React.ChangeEvent) => void;
  onInputClick: (event: React.MouseEvent) => boolean;
  src: string | undefined;
  cropModalOpen: boolean;
  onCancel: () => void;
  crop: Crop;
  onImageLoaded: (target: HTMLImageElement) => void;
  onDone: () => void;
  onCropChange: (crop: Crop, percentCrop: PercentCrop) => void;
  scaledMaxWidth: number;
  scaledMaxHeight: number;
  scaledMinHeight: number;
  scaledMinWidth: number;
  classes: ClassNameMap;
  children: React.ReactChild;
  overlayIcon: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'fit-content',

    '&.create-culture-reward-image-uploader': {
      width: 'auto',
      marginBottom: '8px',
    },
  },
  selectPictureContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  selectPictureOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    top: 0,
    left: 0,
    'background-color': 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  selectPictureImage: {
    width: '100%',
    height: '100%',
  },
  selectPictureInput: {
    display: 'none',
  },
});

const DisplayImageUploader = (props: DisplayImageUploaderProps) => {
  const {
    onSelectFile,
    onInputClick,
    src,
    cropModalOpen,
    onCancel,
    crop,
    onImageLoaded,
    onDone,
    onCropChange,
    scaledMinHeight,
    scaledMaxHeight,
    scaledMinWidth,
    scaledMaxWidth,
    classes: classNames,
    children,
    overlayIcon,
  } = props;
  const classes = useStyles({ classes: classNames });

  return (
    <div className={classes.root}>
      <label
        htmlFor="image-upload-display"
        className={classes.selectPictureContainer}
      >
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          id="image-upload-display"
          className={classes.selectPictureInput}
          onClick={onInputClick}
        />
        {children}
        {overlayIcon && (
          <div className={classes.selectPictureOverlay}>
            <SVGIcon icon={overlayIcon} color={ThemeV2.palette.white} />
          </div>
        )}
      </label>
      {src && (
        <ImageCropperModal
          isModalOpen={cropModalOpen}
          handleModalClose={onCancel}
          onSubmitClick={onDone}
          disabled={!(crop.width && crop.height)}
        >
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onChange={onCropChange}
            minHeight={scaledMinHeight}
            minWidth={scaledMinWidth}
            maxHeight={scaledMaxHeight}
            maxWidth={scaledMaxWidth}
            imageAlt="Cropped output"
            data-testid="imageCropper"
          />
        </ImageCropperModal>
      )}
    </div>
  );
};

DisplayImageUploader.propTypes = {
  classes: shape({}),
  children: node.isRequired,
  onSelectFile: func.isRequired,
  onInputClick: func.isRequired,
  cropModalOpen: bool.isRequired,
  src: string,
  onCancel: func.isRequired,
  onImageLoaded: func.isRequired,
  onDone: func.isRequired,
  onCropChange: func.isRequired,
  scaledMinHeight: number,
  scaledMaxHeight: number,
  scaledMinWidth: number,
  scaledMaxWidth: number,
  crop: shape({
    aspect: number,
    x: number,
    y: number,
    width: number,
    height: number,
  }).isRequired,
  overlayIcon: string,
};

DisplayImageUploader.defaultProps = {
  classes: {},
  src: '',
  scaledMinHeight: null,
  scaledMaxHeight: null,
  scaledMinWidth: null,
  scaledMaxWidth: null,
  overlayIcon: null,
};

export default DisplayImageUploader;
