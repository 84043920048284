import React, { ReactNode, useCallback, useMemo, MouseEvent } from 'react';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import Avatar from '../../atoms/Avatar';
import { DropdownItemContainer, PrefixContainer } from './styles';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { AvatarProps } from '../../atoms/Avatar/interfaces';
import ThemeV2 from '../../../componentsV2/theme';
import { Flex } from '../../../Utils/styles/display';

interface DropdownItemProps {
  value: string;
  id: string | number;
  disabled?: boolean;
  isSelected?: boolean;
  deleteItem?: boolean;
  prefixIcon?: string;
  prefixImg?: string;
  onItemClick?: (id: string | number) => void;
  assemblyCurrency?: AssemblyCurrency;
  emoji?: string;
  className?: string;
  getItemLabel?: (value: string) => ReactNode;
  avatar?: AvatarProps;
  disableHoverActions?: boolean;
  helperText?: string;
}

const DropdownItem = ({
  disabled,
  isSelected,
  deleteItem,
  value,
  prefixIcon,
  prefixImg,
  onItemClick,
  id,
  assemblyCurrency,
  emoji,
  className = '',
  getItemLabel,
  avatar,
  disableHoverActions,
  helperText,
}: DropdownItemProps) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (onItemClick && !disabled) {
        e.stopPropagation();
        onItemClick(id);
      }
    },
    [disabled, id, onItemClick],
  );

  const displayLabel = useMemo(() => {
    if (getItemLabel) {
      return getItemLabel(value);
    }
    return value;
  }, [getItemLabel, value]);

  const renderPrefix = useCallback(() => {
    if (avatar) {
      return (
        <PrefixContainer>
          <Avatar
            img={avatar.img}
            name={avatar.name}
            userId={avatar.userId}
            size="24px"
            icon={avatar.icon}
          />
        </PrefixContainer>
      );
    }
    if (prefixImg) {
      return (
        <PrefixContainer>
          <Avatar img={prefixImg} name={value} size="24px" />
        </PrefixContainer>
      );
    }
    if (prefixIcon) {
      return (
        <PrefixContainer prefixIcon={Boolean(prefixIcon)}>
          <SVGIcon icon={prefixIcon} size="15px" data-testid="prefixIcon" />
        </PrefixContainer>
      );
    }
    if (assemblyCurrency) {
      return (
        <PrefixContainer>
          <AssemblyCurrencyIcon
            assemblyCurrency={assemblyCurrency}
            id="id"
            currencyName="currencyName"
            // size="14px"
            data-testid="prefixCurrency"
          />
        </PrefixContainer>
      );
    }
    if (emoji) {
      return <PrefixContainer>{emoji}</PrefixContainer>;
    }
    return null;
  }, [assemblyCurrency, avatar, emoji, prefixIcon, prefixImg, value]);
  return (
    <DropdownItemContainer
      disabled={disabled}
      isSelected={isSelected}
      deleteItem={deleteItem}
      isPrefixIconRequired={Boolean(prefixIcon)}
      role="option"
      aria-selected={isSelected ? 'true' : undefined}
      onClick={handleClick}
      className={className}
      disableHoverActions={disableHoverActions}
    >
      {renderPrefix()}
      <Flex flexDirection="column" alignItems="align-start">
        <Flex>
          <Body variant="body2">{displayLabel}</Body>
          {isSelected && !disabled && (
            <SVGIcon
              icon="check-rounded"
              size="12px"
              data-testid="selectedIcon"
            />
          )}
        </Flex>
        <Body variant="body3" color={ThemeV2.palette.gray8}>
          {helperText}
        </Body>
      </Flex>
    </DropdownItemContainer>
  );
};

export default DropdownItem;
