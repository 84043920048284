export enum HttpsStatus {
  CONFLICT = 409,
  FORBIDDEN = 403,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
}

export type ResponseError = {
  response: {
    status: HttpsStatus | number;
    data: {
      message: string;
    };
  };
};
