import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import { MEDIUM } from '../../../../App';

type CustomTableCellProps = {
  deleted?: boolean;
} & TableCellProps;

const styles = () => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#6E6E6E',
    fontSize: 12,
    fontWeight: MEDIUM,
  },
  body: {
    color: '#737373',
    fontSize: 12,
  },
  root: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
});

const CustomTableCell = (props: CustomTableCellProps) => {
  const { deleted, classes, ...rest } = props;
  return (
    <TableCell
      classes={{
        head: classes?.head,
        body: deleted ? classes?.root : classes?.body,
      }}
      {...rest}
    />
  );
};

export default withStyles(styles)(CustomTableCell);
