const getMainRoutePath = (path: string) => {
  // To match sub page route
  const subPagePattern = /(\/admin\/.*?\/.*?\/).+/;
  const matchedSubPagePath = path.match(subPagePattern);
  let formattedPath = path;

  // If current route is a sub-page then return a respective main route path
  if (matchedSubPagePath) {
    const mainRoute = matchedSubPagePath[1];
    formattedPath = mainRoute;
  }

  // To handle if path contains "/" at the end
  if (formattedPath.endsWith('/')) {
    formattedPath = formattedPath.substring(0, formattedPath.length - 1);
  }

  return formattedPath;
};

export { getMainRoutePath };
