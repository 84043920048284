import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from 'classnames';
import { SyntheticEvent } from 'react';
import ThemeV2 from '../../theme';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '&.disabled': {
      opacity: '0.24',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${ThemeV2.palette.lightGray1}`,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'inherit',
    fontFamily: ThemeV2.typography.fontFamily,
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontSize: '14px',
    '&:focus': {
      borderColor: ThemeV2.palette.primary.main,
    },
  },
});

interface BootstrapInputProps {
  disabled?: boolean;
  placeholder?: string;
  customClasses?: {
    root?: string;
    input?: string;
    channelSearchBar?: string;
  };
  value: string;
  onChange?: (e: SyntheticEvent) => void;
}

const BootstrapInput = (props: BootstrapInputProps) => {
  const { disabled = false, placeholder, customClasses, ...rest } = props;
  const classes = useStyles();
  return (
    <InputBase
      classes={{
        root: classnames(
          classes.root,
          disabled ? 'disabled' : '',
          customClasses?.root,
        ),
        input: classnames(classes.input, customClasses?.input),
      }}
      disabled={disabled}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default BootstrapInput;
