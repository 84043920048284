import { IntegrationProvidersEnum } from '../../../screens/admin/content/settings/Integrations/interfaces';

const integrationProviderText = {
  [IntegrationProvidersEnum.SLACK]:
    'Want to use Assembly without leaving Slack? Easy, install our Slack app and assign a channel!',
  [IntegrationProvidersEnum.MSTEAM]:
    'Use Assembly in Microsoft Teams by directly installing the Assembly app.',
};
export enum BotTypeOptions {
  SLACK = 'slack',
  MSTEAMS = 'msteam',
}
export const BotTypeManager = {
  [BotTypeOptions.MSTEAMS]: IntegrationProvidersEnum.MSTEAM,
  [BotTypeOptions.SLACK]: IntegrationProvidersEnum.SLACK,
};
export const BotManager = {
  [IntegrationProvidersEnum.SLACK]: {
    ssoParam: BotTypeOptions.SLACK,
    uri: 'slack',
    logo: 'slack-logo',
    displayName: 'Slack',
    displayText: integrationProviderText[IntegrationProvidersEnum.SLACK],
    helperLink:
      'http://joinassembly.com/articles/install-the-assembly-slack-chat-app',
  },
  [IntegrationProvidersEnum.MSTEAM]: {
    ssoParam: BotTypeOptions.MSTEAMS,
    uri: 'teams',
    logo: 'ms-teams',
    displayText: integrationProviderText[IntegrationProvidersEnum.MSTEAM],
    displayName: 'MS Teams',
    helperLink:
      'https://www.joinassembly.com/articles/install-the-assembly-ms-teams-chat-app',
  },
};

export const BotAnnouncementRadioValues = {
  ANNOUNCE: '1',
  DONT_ANNOUNCE: '2',
};

export const BotAnnouncementRadioOptions = [
  {
    value: BotAnnouncementRadioValues.ANNOUNCE,
    label: `Yes, please 🔔 (recommended)`,
  },
  {
    value: BotAnnouncementRadioValues.DONT_ANNOUNCE,
    label: `No announcement right now, please`,
  },
];

export const BotAnnouncementContent = {
  HEADING: 'Want to announce Assembly in your default channel?',
  SUB_HEADING: `The announcement message is helpful to let your team know
  Assembly has been added. The message also has a tutorial on how to start
  giving customCurrency’s!`,
  BACK_BUTTON_TEXT: `Back`,
  NEXT_BUTTON_TEXT: 'Next',
  SEE_WELCOME_MESSAGE: 'See the welcome message',
  SEE_ANNOUNCEMENT: 'See my announcement in my Slack channel',
  ANNOUNCEMENT_SNEAK_PEAK: 'Here’s a sneak peek at the announcement message',
};

export const BotFlowContent = {
  HEADING:
    'Want to use Assembly without leaving :displayName? Easy, install our :displayName app and assign a channel!',
  UPGRADE_TEXT: 'Upgrade now to change',
  LINK_TEXT: 'Not now, head to my feed',
  GIF_LOADING_TEXT:
    'One moment, you have a big team! Feel free to spin in your chair...',
};

export const buttonText = {
  FINISH_STEP: 'Finish Setup',
  GET_STARTED: 'Get Started',
  COMING_SOON: 'Coming Soon',
  NEXT: 'Next',
  CANCEL: 'Cancel',
  BACK: 'Back',
  SKIP_CUSTOMIZING_CURRENCY: 'Skip customizing currency',
  UPGRADE_NOW_TO_CHANGE: 'Upgrade now to change',
  HEAD_TO_MY_FEED: 'Head to my feed',
  INVITE_MEMBERS_NOW: 'Invite members now',
  NOT_NOW: 'Not now, head to my feed',
  GO_TO_SLACK_CHANNEL: 'Go to my Slack channel',
  CLOSE: 'Close',
  SAVE_CHANGES: 'Save Changes',
  DISCONNECT_SLACK: 'Disconnect Slack',
};

export const warningText = {
  SETUP_NOT_COMPLETE: 'Setup not complete',
  NOT_ANNOUNCED: 'Not announced, open ‘Quick Settings’ to announce',
};

export const crumbText = {
  SETTINGS: 'Settings',
  INTEGRATIONS: 'Integrations',
  SLACK: 'Slack',
  TEAMS: 'MS Teams',
};

export const errorText = {
  SELECT_CHANNEL: 'Please select a channel',
  ENTER_CHANNEL_NAME: 'Please enter channel name',
  CHANNEL_NAME_TOO_LONG: 'Sorry, that is too long , 80 characters max!',
  INVALID_CHARACTERS: 'Only lowercase, numbers and - in between',
  GENERIC_ERROR: 'Whoops, looks like something went wrong. Please try again.',
};

export const quickSettingsText = {
  [IntegrationProvidersEnum.SLACK]: {
    SEND_ANNOUNCEMENT_QUESTION: 'Send Slack announcement?',
    NOT_ANNOUNCED_YET: `You haven’t announced Assembly in Slack. Announcing can help
    inform and educate your members – `,
    GET_STARTED: 'Get started with announcement',
    WHICH_TEAM: '',
    WHERE_ASSEMBLY_POSTS: 'Where do you want Assembly posts to go in Slack?',
    HELPER_TEXT: 'We will invite all existing members for you to this channel',
    DISCONNECT: 'Disconnect Slack',
    // RECOMMENDATION: 'We recommend a public and dedicated channel',
  },
  [IntegrationProvidersEnum.MSTEAM]: {
    SEND_ANNOUNCEMENT_QUESTION: '',
    NOT_ANNOUNCED_YET: '',
    GET_STARTED: '',
    WHICH_TEAM: 'Which team do you want Assembly to be in? ',
    WHERE_ASSEMBLY_POSTS:
      'Which channel do you want Assembly posts to go into?',
    HELPER_TEXT: '',
    DISCONNECT: 'Disconnect MS Teams',
  },
};

export const defaultSlackChannelPage = {
  SELECT_DEFAULT_CHANNEL: 'Select a default #channel',
  CREATE_CHANNEL_PLACEHOLDER: 'Example: recognition, praise, etc...',
};

export const teamsSelectTeamsPage = {
  DROPDOWN_PLACEHOLDER: 'Select a team',
  DROPDOWN_HELPER_TEXT:
    '👉 Only approved users of this team will be able to use Assembly',
  HEADER: 'First, select a team',
  // eslint-disable-next-line max-len
  SUBHEADER: `If you already have a general team, we recommend selecting it. The more people that have access to use Assembly the more successful your recognition initiative will be.`,
};

export const teamsChannelPage = {
  SELECT_DEFAULT_CHANNEL: 'Select a default channel',
  CREATE_CHANNEL_HELPER_TEXT:
    '👉 No spaces or periods and no more than 80 characters',
  DROPDOWN_HELPER_TEXT:
    '👉 We will invite all existing members for you to this channel',
  CREATE_CHANNEL_PLACEHOLDER: 'Example: shoutouts, praise, etc...',
  HEADER: 'Select a default channel to keep all recognition in one channel',
  SUB_HEADER:
    // eslint-disable-next-line max-len
    'If you already have a public recognition channel, we recommend selecting it. If not, we recommend creating a new public channel. You’ll see an Assembly tab in this channel, where you’ll be giving all of your recognition.',
};

export const AnnounceAssemblyContent = {
  HEADING: 'Looking to announce Assembly in Slack, right now?',
  SUB_HEADING: `Let your team know that Assembly has been added to Slack!
  This announcement message tells them how to start giving out
  recognition.`,
  LABEL: 'Choose a channel to announce',
  HELPER_TEXT: `👉 We recommend announcing in #general or #shoutouts`,
  ANNOUNCE_BUTTON_TEXT: 'Announce Assembly Now',
  SUCCESS_HEADING: 'Success! You let everyone know about Assembly',
  SUCCESS_BODY: `You’re all done here. Click “Thanks” to close this modal and keep
  exploring on Assembly or click “Go to my Slack channel” to head on
  over to Slack.`,
  SUCCESS_BUTTON_TEXT: 'Thanks',
};

export const TabChannelOrganismText = {
  DROPDOWN_HELPER_TEXT: 'Please select the channel',
  DROPDOWN_PLACEHOLDER: 'Select a default #channel',
  INPUT_HELPER_TEXT: `🚨 Go to slack, create your channel, come back and refresh this page`,
  INPUT_PLACEHOLDER: 'Example: recognition, praise, etc...',
};
