import { Method } from 'axios';
import { API_URL_WITH_V2, API_URL_WITH_V3 } from '../config';
import { PAGINATED_INTEGRATION_DATA_ITEMS_MIN_LIMIT } from './im';

export const GET_BOT_OAUTH_CONFIG = 'GET_BOT_OAUTH_CONFIG';
export const AUTHORIZE_BOT = 'AUTHORIZE_BOT';
export const SLACK_BOT_INTEGRATION_DATA = 'SLACK_BOT_INTEGRATION_DATA';
export const MSTEAMS_BOT_INTEGRATION_DATA = 'MSTEAMS_BOT_INTEGRATION_DATA';
export const SLACK_SET_ACTIVE_CHANNEL = 'SLACK_SET_ACTIVE_CHANNEL';
export const SLACK_CREATE_ACTIVE_CHANNEL = 'SLACK_CREATE_ACTIVE_CHANNEL';
export const MSTEAMS_SET_ACTIVE_CHANNEL = 'MSTEAMS_SET_ACTIVE_CHANNEL';
export const MSTEAMS_CREATE_ACTIVE_CHANNEL = 'MSTEAMS_CREATE_ACTIVE_CHANNEL';
export const GET_CUSTOM_EMOJI = 'GET_CUSTOM_EMOJI';
export const SET_CUSTOM_CURRENCY = 'SET_CUSTOM_CURRENCY';
export const SKIP_CUSTOM_CURRENCY = 'SKIP_CUSTOM_CURRENCY';
export const SEND_WELCOME_MESSAGE = 'SEND_WELCOME_MESSAGE';
export const GET_IDENTITY_MANAGER_DATA = 'GET_IDENTITY_MANAGER_DATA';
export const GET_INTEGRATION_STATUS = 'GET_INTEGRATION_STATUS';
export const SYNC_INTEGRATION = 'SYNC_INTEGRATION';
export const INTEGRATION_STATUS = 'INTEGRATION_STATUS';

export const GET_SSO_SIGNIN_CONFIG = 'GET_SSO_SIGNIN_CONFIG';
export const GET_SSO_SIGNUP_CONFIG = 'GET_SSO_SIGNUP_CONFIG';
export const GET_SSO_OAUTH_CONFIG = 'GET_SSO_OAUTH_CONFIG';
export const SSO_SIGNIN = 'SSO_SIGNIN';
export const SSO_SIGNUP = 'SSO_SIGNUP';
export const GET_JOB_STATUS = 'GET_JOB_STATUS';
export const GET_MULTIPLE_JOBS_STATUS = 'GET_MULTIPLE_JOBS_STATUS';
export const ANALYTICS_PEOPLE_SUMMARY = 'ANALYTICS_PEOPLE_SUMMARY';
export const ANALYTICS_PROFILE_INFORMATION = 'ANALYTICS_PROFILE_INFORMATION';
export const ANALYTICS_RECOGNITION_SUMMARY = 'ANALYTICS_RECOGNITION_SUMMARY';
export const ANALYTICS_PEOPLE_GRAPH_DATA = 'ANALYTICS_PEOPLE_GRAPH_DATA';
export const ANALYTICS_RECOGNITION_GRAPH_DATA =
  'ANALYTICS_RECOGNITION_GRAPH_DATA';
export const ANALYTICS_CORE_VALUES_GRAPH_DATA =
  'ANALYTICS_CORE_VALUES_GRAPH_DATA';
export const ANALYTICS_BADGES_GRAPH_DATA = 'ANALYTICS_BADGES_GRAPH_DATA';
export const ANALYTICS_OVERALL_ENGAGEMENT = 'ANALYTICS_OVERALL_ENGAGEMENT';
export const GET_PREFERRED_SSO = 'GET_PREFERRED_SSO';
export const GET_INTEGRATION_DATA = 'GET_INTEGRATION_DATA';
export const GET_PAGINATED_INTEGRATION_DATA = 'GET_PAGINATED_INTEGRATION_DATA';
export const GET_PAGINATED_INTEGRATION_DATA_MAIN =
  'GET_PAGINATED_INTEGRATION_DATA_MAIN';
export const GET_IM_ENTITY_COUNT = 'GET_IM_ENTITY_COUNT';
export const GET_ASSEMBLY_UNIQUE_SHORT_CODE = 'GET_ASSEMBLY_UNIQUE_SHORT_CODE';
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const USER_VERIFY_TOKEN = 'USER_VERIFY_TOKEN';
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS =
  'UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS';
export const UPDATE_COLLECTION_SETTINGS = 'UPDATE_COLLECTION_SETTINGS';
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';
export const GET_CURRENT_PLAN = 'GET_CURRENT_PLAN';
export const GET_REDEEM_INFO = 'GET_REDEEM_INFO';
export const QUICK_SETUP_GUIDE = 'QUICK_SETUP_GUIDE';

export const GET_PAGINATED_MEMBERS = 'GET_PAGINATED_MEMBERS';
export const GET_MEMBERS_COUNT = 'GET_MEMBERS_COUNT';
export const MEMBERS_BULK_ACTIVATE = 'MEMBERS_BULK_ACTIVATE';
export const MEMBERS_DOWNLOAD_REPORT = 'MEMBERS_DOWNLOAD_REPORT';

// Rewards
export const GET_CUSTOM_REWARD = 'GET_CUSTOM_REWARD';
export const GET_GIFT_CARDS = 'GET_GIFT_CARDS';
export const GET_DONATIONS = 'GET_DONATIONS';
export const GET_CHEAPEST_REWARD = 'GET_CHEAPEST_REWARD';
export const GET_MY_REWARDS = 'GET_MY_REWARDS';
export const GET_MY_REWARDS_HISTORY = 'GET_MY_REWARDS_HISTORY';
export const GET_AXOMO_SHIPPING_DETAILS = 'GET_AXOMO_SHIPPING_DETAILS';
export const RESEND_EMAIL_FOR_MY_REWARDS = 'RESEND_EMAIL_FOR_MY_REWARDS';
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';
export const GET_IP = 'GET_IP';

export const GET_USER_INFO = 'GET_USER_INFO';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';

export const CULTURE_REWARD_CASHOUT = 'CULTURE_REWARD_CASHOUT';
export const REWARD_CASHOUT = 'REWARD_CASHOUT';

// REWARDS SWAGS
export const GET_AXOMO_INFO = 'GET_AXOMO_INFO';
export const ESTABLISH_AXOMO_CONNECTION = 'ESTABLISH_AXOMO_CONNECTION';
export const BREAK_AXOMO_CONNECTION = 'BREAK_AXOMO_CONNECTION';
export const FETCH_INVENTORY_SWAGS = 'FETCH_INVENTORY_SWAGS';
export const TOGGLE_REWARD_ITEMS = 'TOGGLE_REWARD_ITEMS';
export const TOGGLE_REWARDS_VISIBILITY = 'TOGGLE_REWARDS_VISIBILITY';
export const AXOMO_REWARD_CATALOGUE = 'AXOMO_REWARD_CATALOGUE';
export const AXOMO_REWARD_CATEGORY = 'AXOMO_REWARD_CATEGORY';
export const GET_AXOMO_REWARD_ITEM = 'GET_AXOMO_REWARD_ITEM';
export const GET_AXOMO_SHIPPING_COUNTRIES = 'GET_AXOMO_SHIPPING_COUNTRIES';
export const AXOMO_PLACE_ORDER = 'AXOMO_PLACE_ORDER';
export const UPDATE_REWARD_ITEM = 'UPDATE_REWARD_ITEM';
export const UPDATE_SHIPPING_COST = 'UPDATE_SHIPPING_COST';
export const GET_GENERAL_REWARDS_INFO = 'GET_GENERAL_REWARDS_INFO';

// REWARDS_DISCOUNTS
export const TOGGLE_REWARDS_DISCOUNTS = 'TOGGLE_REWARDS_DISCOUNTS';

//REWARDS AMAZON BUSINESS
export const CONNECT_AMAZON_BUSINESS = 'CONNECT_AMAZON_BUSINESS';
export const LIST_ALL_AMAZON_CONNECTIONS = 'LIST_ALL_AMAZON_CONNECTIONS';
export const TOGGLE_AMAZON_REWARDS_VISIBILITY =
  'TOGGLE_AMAZON_REWARDS_VISIBILITY';
export const STORE_AMAZON_BUSINESS_CONFIG = 'STORE_AMAZON_BUSINESS_CONFIG';
export const DISCONNECT_AMAZON_BUSINESS = 'DISCONNECT_AMAZON_BUSINESS';

// SAML
export const GET_SAML_DETAILS = 'GET_SAML_DETAILS';
export const GET_INTEGRATION_SAML_DETAILS = 'GET_INTEGRATION_SAML_DETAILS';
export const UPDATE_SAML_DETAILS = 'UPDATE_SAML_DETAILS';
export const ENABLE_SAML_DETAILS = 'ENABLE_SAML_DETAILS';
export const DISABLE_SAML_DETAILS = 'DISABLE_SAML_DETAILS';
export const UPDATE_SAML_QUICK_SETTINGS = 'UPDATE_SAML_QUICK_SETTINGS';
// Admin Rewards
export const ADMIN_CUSTOM_REWARD = 'ADMIN_CUSTOM_REWARD';
export const ADMIN_CUSTOM_REWARD_EDIT = 'ADMIN_CUSTOM_REWARD_EDIT';

//  SAML AUTH
export const AUTHENTICATE_SAML = 'AUTHENTICATE_SAML';
export const GET_SAML_AUTH_CONFIG = 'GET_SAML_AUTH_CONFIG';

//  GOOGLE ONE TAP
export const GOOGLE_ONE_TAP_AUTH = 'GOOGLE_ONE_TAP_AUTH';

// FLOWS
export const FLOWS_ONBOARDING_JOB = 'FLOWS_ONBOARDING_JOB';

export const GET_LOGO_UPLOAD_SIGNED_URL = 'GET_LOGO_UPLOAD_SIGNED_URL';
export const GET_MEMBER_NOTIFICATION_PREFERENCES =
  'GET_MEMBER_NOTIFICATION_PREFERENCES';
export const UPDATE_MEMBER_NOTIFICATION_PREFERENCES =
  'SET_MEMBER_NOTIFICATION_PREFERENCES';

// IM
export const GET_IM_SETUP_JOB = 'GET_IM_SETUP_JOB';
export const GET_IM_CONFIG = 'GET_IM_CONFIG';
export const IM_AUTHORIZE = 'IM_AUTHORIZE';
export const GET_IM_INTEGRATION_DATA = 'GET_IM_INTEGRATION_DATA';

//  ADMIN MERGE.DEV
export const GET_MERGE_LINK_TOKEN = 'GET_MERGE_LINK_TOKEN';
export const AUTHORIZE_MERGE_PUBLIC_TOKEN = 'AUTHORIZE_MERGE_PUBLIC_TOKEN';

// ADMIN_GENERAL_SETTINGS
export const UPDATE_GENERAL_SETTINGS = 'UPDATE_GENERAL_SETTINGS';

export const GET_POINTS_ACTIVITY = 'GET_POINTS_ACTIVITY';
export const GENERATE_ACTIVITY_DATA = 'GENERATE_ACTIVITY_DATA';
export const GENERATE_ACTIVITY_OLD_DATA = 'GENERATE_ACTIVITY_OLD_DATA';

export const GET_USER_LOCATION = 'GET_USER_LOCATION';

//  ADMIN BOT AUTO DEPOSIT
export const GET_AUTO_DEPOSIT_INFO = 'GET_AUTO_DEPOSIT_INFO';
export const SET_AUTO_DEPOSIT_INFO = 'SET_AUTO_DEPOSIT_INFO';

//  ADMIN FLOW INSIGHTS
export const GET_SISENSE_FLOW_INSIGHTS_LINK = 'GET_SISENSE_FLOW_INSIGHTS_LINK';

// CHALLENGES
export const UPDATE_CHALLENGES_SETTINGS = 'UPDATE_CHALLENGES_SETTINGS';

type EndpointDetails = Record<
  string,
  { requestType: Method; endpointURL: string }
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default <EndpointDetails>{
  [GET_INTEGRATION_STATUS]: {
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/botManagerData`,
    requestType: 'GET',
  },
  [QUICK_SETUP_GUIDE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/guide`,
  },
  [GET_PROFILE_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/info`,
  },
  [UPDATE_PROFILE_INFO]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/info`,
  },
  [UPDATE_PROFILE_PICTURE]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/profile/updateAccount`,
  },
  [UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/flow`,
  },
  [UPDATE_COLLECTION_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/collection`,
  },
  [GET_CURRENT_PLAN]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/billing/planFeatures`,
  },
  [ANALYTICS_OVERALL_ENGAGEMENT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/overallEngagement`,
  },
  [ANALYTICS_CORE_VALUES_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/coreValues`,
  },
  [ANALYTICS_BADGES_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/badges`,
  },
  [ANALYTICS_PEOPLE_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/people`,
  },
  [ANALYTICS_RECOGNITION_GRAPH_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/recognition`,
  },
  [ANALYTICS_PROFILE_INFORMATION]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/profileInformation`,
  },
  [ANALYTICS_RECOGNITION_SUMMARY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/recognitionSummary`,
  },
  [ANALYTICS_PEOPLE_SUMMARY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/analytics/peopleSummary`,
  },
  [GET_JOB_STATUS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/monitoring/job/:jobId/status`,
  },
  [GET_MULTIPLE_JOBS_STATUS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/monitoring/job/status?ids=:jobIds`,
  },
  [SEND_WELCOME_MESSAGE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/sendWelcomeMessage`,
  },
  [GET_IDENTITY_MANAGER_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/identityManagerData`,
  },
  [SET_CUSTOM_CURRENCY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/setCustomCurrency`,
  },
  [SKIP_CUSTOM_CURRENCY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/skipCustomCurrency`,
  },
  [GET_CUSTOM_EMOJI]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/integrations/slack/employer/customEmojis`,
  },
  [MSTEAMS_BOT_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/msteam/integrationData`,
  },
  [MSTEAMS_SET_ACTIVE_CHANNEL]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/msteam/setActiveChannel`,
  },
  [MSTEAMS_CREATE_ACTIVE_CHANNEL]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/msteam/createActiveChannel`,
  },
  [SLACK_SET_ACTIVE_CHANNEL]: {
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/setActiveChannel`,
    requestType: 'POST',
  },
  [SLACK_CREATE_ACTIVE_CHANNEL]: {
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/createActiveChannel`,
    requestType: 'POST',
  },
  [GET_BOT_OAUTH_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/:sso/oauth2Config`,
  },
  [AUTHORIZE_BOT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/:sso/authorize`,
  },
  [SLACK_BOT_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/bot/slack/integrationData`,
  },
  [GET_SSO_SIGNIN_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/signin`,
  },
  [GET_SSO_SIGNUP_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/signup`,
  },
  [GET_SSO_OAUTH_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/oauth2Config/im`,
  },
  [SSO_SIGNIN]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/signin`,
  },
  [SSO_SIGNUP]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/identity/:sso/signup`,
  },
  [GET_PREFERRED_SSO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/identity/preferredSSO`,
  },
  [GET_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/:sso/integrationData`,
  },
  [GET_PAGINATED_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/:sso/pullData`,
  },
  [GET_PAGINATED_INTEGRATION_DATA_MAIN]: {
    // Todo: Suren, remove this once the new api updated
    requestType: 'GET',
    // eslint-disable-next-line max-len
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/merge/pullData?kind=Employee&limit=${PAGINATED_INTEGRATION_DATA_ITEMS_MIN_LIMIT}`,
  },
  [GET_IM_ENTITY_COUNT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/membersCountByAccountManagementType`,
  },
  [GET_ASSEMBLY_UNIQUE_SHORT_CODE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/generateUniqueShortCode`,
  },
  [GET_REDEEM_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/billing/checkRedeem`,
  },
  [GET_CUSTOM_REWARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/rewards/getreward/custom`,
    // Todo: sort should be dynamic
  },
  [GET_GIFT_CARDS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/rewards/getreward/tango?category=gift%20card`,
  },
  [GET_CHEAPEST_REWARD]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/cheapestReward?country=:countryCode`,
  },
  [GET_AXOMO_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/axomo/info`,
  },
  [ESTABLISH_AXOMO_CONNECTION]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/axomo/connect`,
  },
  [BREAK_AXOMO_CONNECTION]: {
    requestType: 'DELETE',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/axomo/disconnect`,
  },
  [FETCH_INVENTORY_SWAGS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/rewardItems`,
  },
  [TOGGLE_REWARD_ITEMS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/rewards/toggle/:status`,
  },
  [TOGGLE_REWARDS_VISIBILITY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/visibility/:status`,
  },
  [AXOMO_REWARD_CATALOGUE]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/rewardCatalogue`,
  },
  [AXOMO_REWARD_CATEGORY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/rewardCategory`,
  },
  [GET_AXOMO_REWARD_ITEM]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/:storeUID/:itemUID`,
  },
  [GET_AXOMO_SHIPPING_COUNTRIES]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/shippingCountries`,
  },
  [UPDATE_REWARD_ITEM]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/:storeUID/:rewardItemUID`,
  },
  [UPDATE_SHIPPING_COST]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/shippingCost`,
  },
  [GET_GENERAL_REWARDS_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/info`,
  },
  [AXOMO_PLACE_ORDER]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/AXOMO/placeOrder`,
  },
  [GET_DONATIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/rewards/getreward/tango?category=donation`,
  },
  [GET_EXCHANGE_RATES]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/rewards/exchangeRates`,
  },
  [GET_IP]: {
    requestType: 'GET',
    endpointURL: 'https://ipapi.co/json/',
  },
  [GET_MY_REWARDS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/user/settings/rewards`,
  },
  [GET_AXOMO_SHIPPING_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/integrations/order/:orderId/shippingDetails`,
  },
  [GET_MY_REWARDS_HISTORY]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/user/settings/history`,
  },
  [GET_PAGINATED_MEMBERS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/a`,
  },
  [GET_MEMBERS_COUNT]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/count`,
  },
  [MEMBERS_BULK_ACTIVATE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/bulkActivate`,
  },
  [MEMBERS_DOWNLOAD_REPORT]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/members/downloadReport`,
  },
  [RESEND_EMAIL_FOR_MY_REWARDS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/user/settings/resendEmail`,
  },
  [GET_USER_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/auth/user/info`,
  },
  [USER_VERIFY_TOKEN]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/verifyToken`,
  },
  [UPDATE_USER_SETTINGS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/user/settings/updateaccount`,
  },
  [CULTURE_REWARD_CASHOUT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/rewards/cashout/custom`,
  },
  [REWARD_CASHOUT]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/rewards/cashout`,
  },
  [GET_SAML_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [GET_INTEGRATION_SAML_DETAILS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml/integration-fetch`,
  },
  [UPDATE_SAML_DETAILS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [ENABLE_SAML_DETAILS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [DISABLE_SAML_DETAILS]: {
    requestType: 'DELETE',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml`,
  },
  [UPDATE_SAML_QUICK_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/auth/saml/update`,
  },
  [AUTHENTICATE_SAML]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/saml/authentication`,
  },
  [GET_SAML_AUTH_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/saml/config`,
  },
  [GOOGLE_ONE_TAP_AUTH]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/users/identity/gsuite/oneTap`,
  },
  [ADMIN_CUSTOM_REWARD]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V2}/employer/rewards/custom`,
  },
  [ADMIN_CUSTOM_REWARD_EDIT]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V2}/employer/rewards/custom/:rewardId`,
  },
  [GET_IM_SETUP_JOB]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/setup`,
  },
  [GET_IM_CONFIG]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/config`,
  },
  [IM_AUTHORIZE]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/authorize`,
  },
  [GET_IM_INTEGRATION_DATA]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/integrationData`,
  },
  [FLOWS_ONBOARDING_JOB]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/onboardingJob`,
  },
  [UPDATE_GENERAL_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V2}/employer/employer_info/settings`,
  },
  [GET_LOGO_UPLOAD_SIGNED_URL]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/logoUploadLink`,
  },
  [GET_MEMBER_NOTIFICATION_PREFERENCES]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/settings/notification`,
  },
  [UPDATE_MEMBER_NOTIFICATION_PREFERENCES]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/feed/flows/settings/notification`,
  },
  [GET_MERGE_LINK_TOKEN]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/merge/linkToken`,
  },
  [AUTHORIZE_MERGE_PUBLIC_TOKEN]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/merge/authorize`,
  },
  [GET_POINTS_ACTIVITY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/monitoring/pointsActivity`,
  },
  [GENERATE_ACTIVITY_DATA]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/monitoring/pointsActivity/downloadData`,
  },
  [GENERATE_ACTIVITY_OLD_DATA]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/monitoring/pointsActivity/downloadRecognitionData`,
  },
  [GET_AUTO_DEPOSIT_INFO]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V2}/employer/rewards/deposit`,
  },
  [SET_AUTO_DEPOSIT_INFO]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V2}/employer/rewards/deposit`,
  },
  [GET_USER_LOCATION]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/profileData`,
  },
  [GET_SISENSE_FLOW_INSIGHTS_LINK]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/users/sisense/webAccessToken`,
  },
  [TOGGLE_REWARDS_DISCOUNTS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/discounts/:status`,
  },
  [UPDATE_CHALLENGES_SETTINGS]: {
    requestType: 'PUT',
    endpointURL: `${API_URL_WITH_V3}/assembly/settings/challenge`,
  },
  [CONNECT_AMAZON_BUSINESS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/amazon/connect`,
  },
  [LIST_ALL_AMAZON_CONNECTIONS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/rewards/amazon/connections`,
  },
  [TOGGLE_AMAZON_REWARDS_VISIBILITY]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/amazon/visibility`,
  },
  [STORE_AMAZON_BUSINESS_CONFIG]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/amazon/:connectionId/storeConfig`,
  },
  [DISCONNECT_AMAZON_BUSINESS]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/rewards/amazon/:connectionId/disconnect`,
  },
  [SYNC_INTEGRATION]: {
    requestType: 'POST',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/manualSync`,
  },
  [INTEGRATION_STATUS]: {
    requestType: 'GET',
    endpointURL: `${API_URL_WITH_V3}/assembly/integration/im/status`,
  },
};
