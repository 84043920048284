export const MERGE = 'MERGE';
export const IdentitySSO: Record<string, any> = {
  GSUITE: {
    ssoParam: 'gSuite',
    logo: 'gSuite-logo',
    displayName: 'G Suite',
    helperLink:
      'https://www.joinassembly.com/articles/g-suite-connect-my-assembly-account-to-google-suite',
  },
  OFFICE365: {
    ssoParam: 'office365',
    logo: 'office365-logo',
    displayName: 'Office 365',
    helperLink:
      'https://www.joinassembly.com/articles/office-365-connect-my-assembly-account-to-office-365',
  },
  ADP: {
    ssoParam: 'adp',
    logo: 'adp-logo',
    displayName: 'ADP',
    isUpgradeNeeded: true,
    helperLink:
      'https://www.joinassembly.com/articles/adp-connect-my-assembly-account-to-adp',
  },
  SLACK: {
    ssoParam: 'slack',
    logo: 'slack-logo',
    displayName: 'Slack',
    helperLink:
      'https://www.joinassembly.com/articles/slack-connect-my-assembly-account-to-slack-identity-provider',
  },
  MSTEAM: { ssoParam: 'msteam', logo: 'ms-teams', displayName: 'MS Teams' },
  BAMBOO: {
    ssoParam: 'bamboo',
    logo: 'bambooHR-logo',
    displayName: 'BambooHR',
    isUpgradeNeeded: true,
    helperLink:
      'https://www.joinassembly.com/articles/bamboo-hr-connect-my-assembly-account-to-bamboo-hr',
  },
  [MERGE]: {
    ssoParam: 'merge',
    logo: '',
    displayName: '',
    isUpgradeNeeded: true,
    helperLink:
      'https://www.joinassembly.com/articles/connect-my-assembly-to-an-hris',
  },
};

export const getIdentityManagerDetails = (
  im: string,
  mergeDetails?: { displayName?: string; logo?: string; name?: string },
) => {
  if (im === 'MERGE' && mergeDetails) {
    return {
      ...IdentitySSO[im],
      logo: mergeDetails.logo || '',
      displayName: mergeDetails.displayName || mergeDetails.name || '',
    };
  }
  return IdentitySSO[im];
};

export const isSSOAllowed = (sso: any) => {
  return Object.keys(IdentitySSO).some((key) => {
    const value = IdentitySSO[key];
    return value.ssoParam === sso;
  });
};

export const getSSOIDByParam = (ssoParam: string) => {
  return Object.keys(IdentitySSO).find((key) => {
    const value = IdentitySSO[key];
    if (value.ssoParam === ssoParam) {
      return key;
    }
  });
};

export const getSSOButtons = (identityManagers: string | any[]) => {
  const buttons: any[] = [];
  for (let j = 0; j < identityManagers.length; j += 1) {
    Object.keys(IdentitySSO).forEach((key) => {
      const sso = IdentitySSO[key];
      const identityManager = identityManagers[j];
      if (key === identityManager.id && key !== MERGE) {
        const { logo, ssoParam, ...rest } = sso;
        const buttonConfig = { id: key, logo, sso: ssoParam, ...rest };
        if (identityManager.integrationStatus === 'COMING_SOON') {
          buttonConfig.disabled = true;
        }
        buttons.push(buttonConfig);
      }
    });
  }
  return buttons;
};
