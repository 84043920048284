import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';
import { API_URL_WITH_V2 } from '../../config';
import { AuthHeaders } from '../shared/HeaderToken';

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  LOAD_ACCOUNTS: `${API_URL_WITH_V2}/superadmin/accounts/dashboard`,
  UPDATE_BILLING_STATUS: (type) =>
    `${API_URL_WITH_V2}/superadmin/accounts/update/${type}/status`,
  UPDATE_ACCOUNT_MEDIA: (employerId) =>
    `${API_URL_WITH_V2}/superadmin/accounts/${employerId}/media`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS';
export const UPDATE_BILLING_STATUS = 'UPDATE_BILLING_STATUS';
export const UPDATE_ACCOUNT_MEDIA = 'UPDATE_ACCOUNT_MEDIA';

// ------------------------------------
// Actions
// ------------------------------------

const parseUrl = ({ limit, page, search }) => {
  let url = `limit=${limit}&page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  return url;
};

export const getAccountsAction = ({ limit = 1, page = 1, search }) => ({
  type: LOAD_ACCOUNTS,
  request: {
    url: `${apiEndpoints.LOAD_ACCOUNTS}?${parseUrl({ limit, page, search })}`,
    method: 'get',
    ...AuthHeaders(),
  },
});

export const updateBillingStatusAction = ({ type, status, employerId }) => ({
  type: UPDATE_BILLING_STATUS,
  request: {
    url: `${apiEndpoints.UPDATE_BILLING_STATUS(type)}`,
    method: 'post',
    data: {
      status,
      employerId,
    },
    ...AuthHeaders(),
  },
  meta: {
    employerId,
  },
});

export const updateAccountMediaAction = (data, employerId) => ({
  type: UPDATE_ACCOUNT_MEDIA,
  request: {
    url: `${apiEndpoints.UPDATE_ACCOUNT_MEDIA(employerId)}`,
    method: 'put',
    data,
    ...AuthHeaders(),
  },
});

export const deleteAccountMediaAction = (employerId) => ({
  type: UPDATE_ACCOUNT_MEDIA,
  request: {
    url: `${apiEndpoints.UPDATE_ACCOUNT_MEDIA(employerId)}`,
    method: 'delete',
    ...AuthHeaders(),
  },
});

// ------------------------------------
// Reducers
// ------------------------------------

export default requestsReducer({
  actionType: LOAD_ACCOUNTS,
  getData: (state, action) => {
    switch (action.type) {
      case success(LOAD_ACCOUNTS): {
        const { employers, total } = _.get(action, ['data', 'data', 'results']);
        const accounts = {};
        employers.forEach((employer) => {
          if (!accounts[employer.employerId]) {
            accounts[employer.employerId] = employer;
          }
        });
        return { ...state, accounts, totalAccounts: total };
      }
      default:
        return state.data;
    }
  },
  onError: (state) => {
    return { ...state };
  },
  mutations: {
    [UPDATE_BILLING_STATUS]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(UPDATE_BILLING_STATUS): {
            const billingStatus = _.get(action, [
              'data',
              'data',
              'billingStatus',
            ]);
            const { accounts } = state.data;
            accounts[action.meta.employerId].billingStatus = billingStatus;
            return { ...state, accounts: { ...accounts } };
          }
          default:
            return state;
        }
      },
    },
    [UPDATE_ACCOUNT_MEDIA]: {
      updateData: (state, action) => {
        switch (action.type) {
          case success(UPDATE_ACCOUNT_MEDIA): {
            const employer = _.get(action, ['data', 'data']);
            const { accounts } = state.data;
            accounts[employer._id].logo = employer.logo;
            accounts[employer._id].customCurrency =
              employer.settings.customCurrency;
            return { ...state, accounts: { ...accounts } };
          }
          default:
            return state;
        }
      },
    },
  },
});
