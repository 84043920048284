import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

import LoadingPulse from '../../atomic/atoms/LoadingPulse';
import { checkIfAdmin } from '../../containers/Roles';

type ProtectedRouteProps = {
  adminCheck: boolean;
} & RouteProps;

export default function ProtectedRoute({
  adminCheck,
  ...props
}: ProtectedRouteProps) {
  const history = useHistory();
  const location = useLocation();

  const { user, authenticated, employer } = useSelector((state) => ({
    user: _.get(state, ['me', 'me'], null),
    authenticated: _.get(state, ['auth', 'authenticated'], false),
    employer: _.get(state, ['admin', 'employer'], null),
  }));

  if (!authenticated) {
    history.push({
      pathname: '/signin',
      state: { origin: location.pathname },
      search: location.search,
    });
  }

  if (adminCheck && user && !checkIfAdmin(user)) {
    history.push('/404');
  }

  if (user && employer) {
    return <Route {...props} />;
  }
  return <LoadingPulse hasPadding isLoading />;
}
