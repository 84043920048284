import React from 'react';
import { PulseLoader } from 'react-spinners';
import Grid from '@material-ui/core/Grid';

import themeV2 from '../../componentsV2/theme';

type LoadingPulseProps = {
  isLoading?: boolean;
  hasPadding?: boolean;
};

const LoadingPulse = (props: LoadingPulseProps) => (
  <Grid
    container
    justify="center"
    style={props.hasPadding ? { padding: 16 } : { margin: '16 0' }}
  >
    <PulseLoader
      size={15}
      color={themeV2.palette.primary.main}
      loading={props.isLoading}
    />
  </Grid>
);

export default LoadingPulse;
