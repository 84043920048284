export enum AllowanceType {
  REGULAR = 'regular',
  CUSTOM = 'custom',
  NO_ALLOWANCE = 'no',
}

export enum AllowanceCycle {
  MONTHLY = 'monthly',
}

export enum UserStatus {
  NORMAL = 'normal',
  RECEIVER = 'receiver',
  OBSERVER = 'observer',
  GIVER = 'giver',
}

export enum MemberRole {
  Admin = 'Admin',
  Employee = 'Employee',
  Owner = 'Owner',
}

export enum MemberState {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

export interface QuickSetupData {
  givenFirstRecognition: boolean;
  isAnniversarySet: boolean;
  isBirthdaySet: boolean;
  isCoreValuesSet: boolean;
  isRewardsSet: boolean;
  isUserInvited: boolean;
  isIntegrationConnected: boolean;
}
