import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';
import Textfield from '../NewTextfield';

import ThemeV2 from '../../../componentsV2/theme';
import Chip from '../Chip';
import { PropsClasses } from '../../../interfaces/ClassesProps';

const useStyles = makeStyles({
  autoCompleteStyle: {
    padding: '0',
    margin: '0',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    '& .MuiAutocomplete-listbox': {
      margin: '0',
      padding: '8px',
      backgroundColor: ThemeV2.palette.gray1,
    },
    '& li': {
      transition: 'all .3s',
      '&:hover, &:focus, &:visited': {
        backgroundColor: ThemeV2.palette.geekBlue1,
      },
      '&.MuiAutocomplete-option[data-focus="true"], &.MuiAutocomplete-option[aria-selected="true"]':
        {
          backgroundColor: ThemeV2.palette.geekBlue1,
        },
    },
    '& .MuiAutocomplete-option': {
      padding: '0 .75rem !important',
      margin: 0,
    },
  },
  textFieldRoot: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 .75rem !important',
      color: ThemeV2.palette.gray8,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '2px',
      borderColor: ThemeV2.palette.gray5,
      transition: 'all .3s',
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          color: ThemeV2.palette.gray6,
          opacity: 1,
        },
      },
      '&:hover': {
        borderColor: ThemeV2.palette.geekBlue5,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiAutocomplete-input': {
        fontSize: '1rem',
        height: '1rem',
        lineHeight: '2rem',
        margin: '.25rem 0',
        padding: '0 !important',
      },
      '& .MuiAutocomplete-tag': {
        color: ThemeV2.palette.gray10,
        backgroundColor: ThemeV2.palette.gray3,
        margin: '0 .5rem 0 -.25rem',
        padding: '2.5px 5px',
        borderRadius: '100px',
        fontSize: '.75rem',
      },
    },
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
    '& .Mui-focused': {
      boxShadow: '0px 0px 4px rgba(24, 144, 255, 0.5)',
      borderColor: ThemeV2.palette.geekBlue6,
    },
  },
});

interface TextFieldWithChipsProps {
  value: string[];
  textFieldValue: string;
  size?: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  name?: string;
  helperText?: string;
  classes?: PropsClasses;
  placeholder?: string;
  truncate?: number;
  validation?: (value: string) => boolean;
  options: string[];
  onChange: (event: React.ChangeEvent<unknown>, value: string[]) => void;
  onTextFieldChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const TextFieldWithChips = (props: TextFieldWithChipsProps) => {
  const classes = useStyles(props);
  const {
    value,
    textFieldValue,
    size,
    multiline,
    maxRows,
    minRows,
    name,
    placeholder,
    validation,
    options,
    helperText,
    onChange,
    onTextFieldChange,
  } = props;

  const renderTags = useCallback(
    (tagValue: string[], getTagProps: AutocompleteGetTagProps) => {
      return tagValue.map((option: string, index: number) => (
        <Chip
          key={option}
          label={option}
          validation={validation}
          {...getTagProps({ index })}
        />
      ));
    },
    [validation],
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<unknown>, newInputValue: string) => {
      const inputOptions = newInputValue.split(/[ ,]+/);
      if (inputOptions.length > 1) {
        onChange(
          event,
          value
            .concat(inputOptions)
            .map((x) => x.trim())
            .filter((x) => x),
        );
      }
    },
    [value, onChange],
  );

  const renderInput = useCallback(
    (params) => (
      <Textfield
        {...params}
        name={name}
        value={textFieldValue}
        label={name}
        placeholder={value && value.length > 0 ? null : placeholder}
        helperText={helperText}
        size={size}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        onChange={onTextFieldChange}
      />
    ),
    [
      name,
      textFieldValue,
      value,
      placeholder,
      helperText,
      size,
      multiline,
      maxRows,
      minRows,
      onTextFieldChange,
    ],
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      autoHighlight
      disableClearable
      id={name}
      value={value}
      onChange={onChange}
      options={options}
      renderTags={renderTags}
      onInputChange={onInputChange}
      renderInput={renderInput}
      classes={{
        paper: classes.autoCompleteStyle,
      }}
    />
  );
};

const MemoizedTextFieldWithChips = React.memo(TextFieldWithChips);
MemoizedTextFieldWithChips.displayName = 'TextFieldWithChips';

export default MemoizedTextFieldWithChips;
