export const EACH = 'each';
export const LEFT = 'left';
export const GIVE = 'give';
export const ME = 'Me';
export const OF = 'of';
export const MY = 'my';
export const SHOWING = 'Showing';
export const EDIT = 'Edit';
export const CLOSE = 'Close';

export const AVATAR = 'Avatar';
export const NAME = 'Name';
export const HANDLE = 'Handle';
export const EMAIL = 'Email';
export const DEPARTMENT = 'Department';
export const TIME_ZONE = 'Time Zone';
export const MANAGER = 'Direct Manager';
export const DIRECT = 'Direct';
export const REPORTS = 'Reports';
export const POST = 'Post';
export const BIRTHDAY = 'Birthday';
export const START = 'Start';
export const DATE = 'Date';
export const PROFILE = 'Profile';
export const INFORMATION = 'Information';
export const IN = 'In';
export const APP = 'App';

export const OK = 'OK';
export const CANCEL = 'Cancel';

export const YOUR = 'your';
export const YOU = 'You';

export const LOADING = 'Loading';
export const NO = 'No';

export const OR = 'OR';
export const FOR = 'for';
export const A = 'a';
export const AN = 'an';

export const MORE = 'more';
export const REQUIRED = 'Required';
export const WITH = 'with';

export const NOW = 'now';

export const FLOW = 'flow';

export const EVERYONE = 'Everyone';

export const ONLY = 'only';
export const OWNER = 'owner';

export const CUSTOM = 'custom';

export const AT = 'at';

export const THIS = 'this';

export const ABOUT = 'About';

export const MANAGE = 'Manage';

export const NEXT = 'Next';
export const TO = 'to';

export const CREATE = 'Create';
export const OPTION = 'option';

export const ADD = 'Add';
export const ANOTHER = 'another';
export const OTHER = 'Other';

export const AND = 'and';

export const MINUTES = 'minutes';

export const TRY = 'Try';
export const AGAIN = 'again';
export const ON = 'on';
export const OFF = 'off';

export const BY = 'by';

export const USE = 'Use';

export const THE = 'the';
export const REFRESHES = 'Refreshes';

export const PENDING = 'pending';
export const GOOGLE = 'google';

export const NOT = 'not';
export const ERROR = 'error';

export const BACK = 'Back';
