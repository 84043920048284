import { SplitFactory } from '@splitsoftware/splitio-react';
import get from 'lodash/get';
import { parse } from 'qs';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import AssemblyLanderLoadingScreen from '../atomic/molecules/AssemblyLanderLoadingScreen';
import RoutesList from '../aV2/routes/routesList';
import { PLAN_IDS } from '../config';
import { DEVICE, DEVICES } from '../constants/devices';
import { AUTH_SAML, GOOGLE_ONE_TAP_AUTH, SIGN_IN } from '../constants/routes';
import SAMLAuthController from '../controllers/auth/SAMLAuthController';
import history from '../history';
import { HttpsStatus } from '../interfaces/ResponseError';
import {
  useCurrentPlanFetchQuery,
  useProfileInfoFetchQuery,
} from '../queries/Profile';
import ErrorBoundary from '../screens/ErrorBoundary';
import * as Analytics from '../Utils/analytics';
import { trackPageView } from '../Utils/analytics';
import { setSourceDeviceAsIPhone } from '../Utils/device';
import RoutesComponent from './routesComponent';
import KioskFooter from '../atomic/organism/KioskFooter';
import { getSplitIOFactory } from '../Utils/splitOptions';

const SplitV2 = () => {
  const { search, pathname } = useLocation();

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isDevicePresent = parsedParams && parsedParams[DEVICE];
    if (isDevicePresent) {
      const { device } = parsedParams;
      if (device === DEVICES.IPHONE) {
        setSourceDeviceAsIPhone();
      }
    }
  }, [search]);
  return (
    <Switch>
      <Route path={GOOGLE_ONE_TAP_AUTH} exact component={RoutesComponent} />
      <Route path={AUTH_SAML} component={SAMLAuthController} />
      <Route component={RoutesComponent} />
      <Redirect to={SIGN_IN} />
    </Switch>
  );
};

const Routers = () => {
  const authenticated = useSelector((state) =>
    get(state, ['auth', 'authenticated'], false),
  );

  const { data, isLoading, isError, error } =
    useProfileInfoFetchQuery(authenticated);
  const { data: planData } = useCurrentPlanFetchQuery(authenticated);

  useEffect(() => {
    if (data) {
      Analytics.registerPropsFromUser(data.member);
    }
  }, [data]);

  useEffect(() => {
    if (planData) {
      Analytics.registerUserProps({
        planType: planData.uniqueId,
        isPaid: planData.uniqueId !== PLAN_IDS.tier1,
      });
    }
  }, [planData]);

  const splitConfig = getSplitIOFactory(data?.assembly?.assemblyId);

  useEffect(() => {
    if (
      isError &&
      error &&
      error.response &&
      error.response.status === HttpsStatus.UNAUTHORIZED
    ) {
      history.push(RoutesList.SIGN_OUT);
    }
  }, [error, isError]);
  if (isLoading && authenticated) {
    return <AssemblyLanderLoadingScreen />;
  }

  // if (!authenticated && !isLoading) {
  //   debugger;
  //   window.location.href = '/signin';
  // }

  return (
    <SplitFactory factory={splitConfig} updateOnSdkUpdate>
      <ErrorBoundary>
        <Router history={history}>
          <SplitV2 />
          <KioskFooter />
        </Router>
      </ErrorBoundary>
    </SplitFactory>
  );
};
export default Routers;
