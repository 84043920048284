import React from 'react';
import { connect } from 'react-redux';
import HeaderWithMenu from './header/HeaderWithMenu';

const Header = () => {
  return (
    <div>
      <HeaderWithMenu shouldShowMenuToggle={false} />
    </div>
  );
};

export default connect()(Header);
