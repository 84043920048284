import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import ThemeV2 from '../../theme';

const useStyles = makeStyles({
  modalCloseButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    cursor: 'pointer',
    padding: '5px',
    '& svg': {
      '& path': {
        fill: ThemeV2.palette.lightGray1,
        transition: '0.3s ease',
      },
    },
    '&:hover': {
      '& svg': {
        '& path': {
          fill: ThemeV2.palette.darkGray3,
        },
      },
    },
  },
});

interface CloseButtonProps {
  onClick?: (event: React.MouseEvent) => void;
  style?: any;
}

const CloseButton = (props: CloseButtonProps) => {
  const { onClick = () => {}, style = {}, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.modalCloseButton}
      onClick={onClick}
      style={style}
      {...rest}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // eslint-disable-next-line max-len
          d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
          fill="#E2E2E2"
        />
      </svg>
    </div>
  );
};

export default CloseButton;
