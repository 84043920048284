import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { QueryClient, QueryClientProvider } from 'react-query';

import Routers from './routers/routers';
import ToastContainer from './ToastContainer';
import './static/css/App.css';
import './static/css/vendor-styles.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LIGHT = 300;
const REGULAR = 400;
export const MEDIUM = 500;
const BOLD = 600;

const theme = createTheme({
  props: {
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        button: {
          '&:focus': {
            outline: 'none !important',
          },
        },
        body: {
          backgroundColor: '#f6f6f6',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:focus': {
          outline: 'none !important',
        },
      },
    },
    MuiFormLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: 'rgb(0,0,0,0.38)', // Some CSS
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid #FF4400',
          transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        },
        '&:before': {
          borderBottom: '1px solid rgb(224, 224, 224)',
        },
        '&:hover:before': {
          borderBottom: '1px solid rgb(224, 224, 224) !important',
        },
      },
    },
    MuiButton: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
      containedPrimary: {
        color: '#FFF',
        minWidth: 80,
        backgroundColor: '#FF4400',
        '&:hover': {
          color: '#FFF',
        },
      },
      containedSecondary: {
        color: '#FFF',
        '&:hover': {
          color: '#FFF',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#000',
        fontSize: 20,
        fontWeight: 500,
      },
    },
  },
  palette: {
    primary: {
      main: '#FF4400',
    },
    secondary: {
      main: '#0AD71C',
    },
    text: {},
  },
  typography: {
    fontWeightLight: LIGHT,
    fontWeightRegular: REGULAR,
    fontWeightMedium: MEDIUM,
    fontWeightBold: BOLD,
    fontSize: 14,
    htmlFontSize: 16,
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 5 * 1000,
      retry: (failureCount, error) => {
        /* @ts-ignore */
        return error?.response && error.response?.status >= 500;
      },
    },
  },
});

const App = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Helmet
          titleTemplate="%s | Assembly"
          defaultTitle="Assembly - Empower your team"
        >
          <meta name="version" content={process.env.REACT_APP_VERSION}></meta>
        </Helmet>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Routers />
        </MuiThemeProvider>
        <ToastContainer newestOnTop />
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
