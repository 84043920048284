import { BotTypeOptions } from '../../Utils/data/botManager/common';
import { SSOOptions, SSOTypes } from './../../Utils/data/ssoManager/common';

export default {
  SIGNUP_SUCCESS: '/signup-success',
  EMAIL_SIGNIN: '/slack/onboard/email-signin',
  EMAIL_SIGNUP: '/slack/onboard/email-signup',

  SIGN_IN: '/signin',
  SIGN_OUT: '/signout',

  SLACK_ONBOARD: '/slack/onboard',
  SLACK_SIGN_IN: '/slack/onboard/signin',
  SLACK_SIGN_IN_V2: '/slack/onboard/signin/v2',
  SLACK_ADD_TO: '/slack/onboard/add',
  SLACK_USER_AUTH: '/slack/onboard/userauth',
  SLACK_SELECT_CHANNELS: '/slack/onboard/channels',
  SLACK_SELECT_DEFAULT_CHANNEL: '/slack/onboard/default-channel',
  SLACK_SELECT_DEFAULT_CURRENCY: '/slack/onboard/currency',
  SLACK_SELECT_USERS_FOR_ACCESS: '/slack/onboard/select-users',
  SLACK_HANDLE_MEMBERS: '/slack/onboard/handle-members',
  SLACK_SPECIFIC_MEMBERS: '/slack/onboard/members',
  SLACK_AUTO_MANAGE_MEMBERS: '/slack/onboard/manage-members',
  SLACK_ASSEMBLY_ANNOUNCEMENT: '/slack/onboard/announcement',
  SLACK_SUCCESS: '/slack/onboard/success',

  ADP_SIGNIN: '/adp/onboard/authorize',
  IM_AUTHORIZE: '/:sso/onboard/authorize',
  IM_AUTHORIZE_SSO: (sso: SSOOptions) => `${sso}/onboard/authorize`,

  BOT_AUTHORIZE: '/:botType/bot/authorize',
  BOT_AUTHORIZE_FN: (sso: BotTypeOptions) => `${sso}/bot/authorize`,

  SSO_SIGNIN_AUTHORIZE: '/:sso/signin/authorize',
  SSO_SIGNIN_AUTHORIZE_FN: (sso: SSOOptions) => `${sso}/signin/authorize`,
  SSO_AUTHORIZE_FN: (sso: SSOOptions, ssoType: SSOTypes) =>
    `${sso}/${ssoType}/authorize`,

  SSO_AUTHORIZE_MODAL_FN: (sso: SSOOptions, ssoType: SSOTypes) =>
    `${sso}/${ssoType}/authorizeModal`,
  SSO_SIGNIN_AUTHORIZE_MODAL: '/:sso/signin/authorizeModal',
  SSO_SIGNUP_AUTHORIZE_MODAL: '/:sso/signup/authorizeModal',
  SAML_AUTHORIZE_MODAL: '/saml/authorizeModal',

  SSO_SIGNUP_AUTHORIZE: '/:sso/signup/authorize',
  SSO_SIGNUP_AUTHORIZE_FN: (sso: SSOOptions) => `${sso}/signup/authorize`,

  SSO_ERRORS: '/:sso/:ssoType(signup|signin)/error/:errorCode',
  SSO_ERRORS_FN: (sso: SSOOptions, ssoType: SSOTypes, errorCode?: string) =>
    `${sso}/${ssoType}/error/${errorCode}`,

  HOME: '/home',
  MSTEAM_CONFIG: '/msteams/config',

  SUPER_ADMIN_ANALYTICS: '/superadmin/analytics',
  SUPER_ADMIN_DASHBOARD_V2: '/superadmin/accounts/dashboard/v2',

  //  ADMIN
  ADMIN: '/admin',

  //  WORKSPACE
  ADMIN_WORKSPACE_GENERAL: '/settings',
  ADMIN_WORKSPACE_ALLOWANCES: '/settings/allowances',
  ADMIN_WORKSPACE_ALLOWANCES_MANAGE: '/settings/allowances/manage',
  ADMIN_WORKSPACE_INTEGRATIONS_SLACK: '/settings/integrations/slack',
  ADMIN_WORKSPACE_INTEGRATIONS_TEAMS: '/settings/integrations/teams',
  ADMIN_WORKSPACE_INTEGRATIONS: '/settings/integrations',
  ADMIN_WORKSPACE_INSIGHTS: '/settings/insights',

  //  RECOGNITION
  ADMIN_RECOGNITION_SETTINGS: '/recognition/settings',
  ADMIN_RECOGNITION_ACTIVITY: '/recognition/activity',
  ADMIN_RECOGNITION_PARTICIPATION: '/recognition/participation',

  //  FLOWS
  ADMIN_FLOWS_PERMISSIONS: '/flows/permissions',
  ADMIN_FLOWS_INSIGHTS: '/flows/insights',

  // CHALLENGES
  ADMIN_CHALLENGES: '/challenges',

  //  USERS
  ADMIN_USER_MANAGE: '/users/manage',
  ADMIN_USER_MANAGE_IDENTITY: '/users/manage/identity',
  ADMIN_USER_INVITE: '/users/invite',
  ADMIN_USER_EARNINGS: '/users/earnings',
  ADMIN_USER_MANAGE_QUEUED: `/users/manage?activeTab=queued`,
  ADMIN_USER_ACTIVITY: `/users/activity`,

  // BOTS
  ADMIN_CELEBRATIONS_ANNIVERSARIES: '/celebrations/anniversaries',
  ADMIN_CELEBRATIONS_BIRTHDAYS: '/celebrations/birthdays',
  ADMIN_BOTS_AUTO_DEPOSIT: '/bots/auto-deposit',

  //  REWARDS
  ADMIN_REWARDS_GIFT_CARDS: '/rewards/gifts',
  ADMIN_REWARDS_CULTURE: '/rewards/culture',
  ADMIN_REWARDS_CHARITY: '/rewards/charities',
  ADMIN_REWARDS_REPORT: '/rewards/report',
  ADMIN_REWARDS_SWAGS: '/rewards/swag',
  ADMIN_REWARDS_DISCOUNTS: '/rewards/discounts',
  ADMIN_REWARDS_AMAZON: '/rewards/amazon',

  //  BILLING
  ADMIN_BILLING_ACCOUNT: '/billing/account',
  ADMIN_BILLING_HISTORY: '/billing/history',
  ADMIN_BILLING_HISTORY_INVOICE: '/billing/invoice',
  ADMIN_BILLING_METHOD: '/billing/method',

  //  SAML
  ADMIN_SAML_AUTHENTICATION: '/security/saml',

  // HELP
  HELP_CENTER: '/help-center',

  //  Client APP
  CLIENT_APP: '/client/app',
} as const;
