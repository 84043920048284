import { DEVICES } from '../../constants/devices';

const SOURCE_DEVICE = 'SOURCE_DEVICE';

const setSourceDevice = (device: DEVICES) => {
  localStorage.setItem(SOURCE_DEVICE, device);
};

const getSourceDevice = () => {
  return localStorage.getItem(SOURCE_DEVICE);
};

export const setSourceDeviceAsIPhone = () => {
  setSourceDevice(DEVICES.IPHONE);
};

export const isSourceDeviceIPhone = () => getSourceDevice() === DEVICES.IPHONE;
