import React, { useCallback, useMemo, useState } from 'react';
import Autocomplete, {
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import TextField from '../../molecules/NewTextfield';
import DropdownItem from '../../molecules/DropdownListItem_V2';
import SVGIcon from '../../atoms/SVGIcon';
import {
  StyledChip,
  StyledPaper,
  useStyles,
  StyledLoader,
  StyledDynamicPaper,
} from './styles';
import {
  AutoCompleteCoreProps,
  AutocompleteDropdownItem,
  SingleSelectAutoCompleteProps,
  PossibleAutoCompleteValues,
} from './interfaces';
import ThemeV2 from '../../../componentsV2/theme';
import { VariantTypes } from '../../molecules/CircularProgress/interfaces';
import CircularProgress from '../../molecules/CircularProgress';
import OptionLoader from './OptionLoader';

const LOADING_SKELETON = 'loadingSkeleton';

function AutoCompleteCore<T extends string | number, U>({
  autoSelect = false,
  autoFocus = false,
  groupByCategory,
  label,
  placeholder,
  options,
  onChange,
  value,
  className,
  multiple = true,
  disableCloseOnSelect,
  limitTags,
  loading,
  textboxSize = 'medium',
  textboxValue,
  onTextboxValueChange,
  filterOptionsFunction,
  onBlur,
  onFocus,
  inputBaseRightPadding,
  ListboxProps,
  checkIfDisabled,
  removeElevation,
  disabled = false,
  hasError = false,
  multiline = false,
  helperText,
  showClearIcon = true,
  expandableDropdown,
  getOptionLabel = (option: AutocompleteDropdownItem<T, U>) =>
    option.title || '',
  dataTestId,
  validation,
  name,
}: AutoCompleteCoreProps<T, U>) {
  const classes = useStyles({ inputBaseRightPadding });
  const [inputValue, setInputValue] = useState('');
  const onInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onTextboxValueChange) {
        onTextboxValueChange(e.target.value);
      } else {
        setInputValue(e.target.value);
      }
    },
    [onTextboxValueChange],
  );
  const onTagDelete = useCallback(
    (id: T) => () => {
      if (value) {
        // Manually assigning types as this function is only called when multiple
        // items can be selected and when value will not be empty.
        const currentValue = value as AutocompleteDropdownItem<T, U>[];
        onChange(
          undefined,
          currentValue.filter((item) => item.id !== id),
        );
      }
    },
    [onChange, value],
  );

  const renderInput = useCallback(
    (props) => (
      <TextField
        autoFocus={autoFocus}
        label={label}
        placeholder={placeholder}
        value={textboxValue || inputValue}
        onChange={onInputValueChange}
        inputProps={props.inputProps}
        InputLabelPropsObj={props.InputLabelProps}
        multiline={multiline}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <>
              {loading ? (
                <StyledLoader className="autocomplete-loader">
                  <CircularProgress
                    variant={VariantTypes.INDETERMINATE}
                    size="16px"
                  />
                </StyledLoader>
              ) : null}
              {props.InputProps.endAdornment}
            </>
          ),
        }}
        size={textboxSize}
        removeElevation={removeElevation}
        disabled={disabled}
        hasError={hasError}
        helperText={helperText}
        name={name}
      />
    ),
    [
      autoFocus,
      label,
      placeholder,
      textboxValue,
      inputValue,
      onInputValueChange,
      multiline,
      loading,
      textboxSize,
      removeElevation,
      disabled,
      hasError,
      helperText,
      name,
    ],
  );
  const renderOption = (
    option: AutocompleteDropdownItem<T, U>,
    state: AutocompleteRenderOptionState,
  ) => {
    if (loading && option.id === LOADING_SKELETON) {
      return <OptionLoader />;
    }

    return (
      <DropdownItem
        id={option.id}
        value={option.title}
        isSelected={state.selected}
        prefixIcon={option.icon}
        avatar={option.avatar}
        disabled={option.disabled}
        disableHoverActions
      />
    );
  };

  const getOptionDisabled = (option: AutocompleteDropdownItem<T, U>) =>
    checkIfDisabled ? checkIfDisabled(option) : Boolean(option.disabled);

  const renderTags = (tags: AutocompleteDropdownItem<T, U>[]) =>
    tags.map((tag) => (
      <StyledChip
        avatar={tag.avatar}
        label={tag.title}
        key={`tag-${tag.id}`}
        onDelete={onTagDelete(tag.id)}
        disabled={disabled}
        validation={validation}
      />
    ));

  const generateInfiniteOptions = () => {
    if (loading) {
      const loadingSkeletonOption: AutocompleteDropdownItem<string> = {
        disabled: true,
        id: LOADING_SKELETON,
        title: LOADING_SKELETON,
      };
      return [...options, loadingSkeletonOption];
    }

    return options;
  };

  const getOptionSelected = (
    option: AutocompleteDropdownItem<T, U>,
    optionToTestAgainst: AutocompleteDropdownItem<T, U>,
  ) => option.id === optionToTestAgainst.id;

  const groupBy = useMemo(() => {
    if (groupByCategory) {
      return (option: AutocompleteDropdownItem<T, U>) => option.category || '';
    }
    return undefined;
  }, [groupByCategory]);

  return (
    <Autocomplete
      fullWidth
      multiple={multiple}
      value={value}
      onChange={onChange}
      id={`combo-box-${Date.now()}`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      options={generateInfiniteOptions()}
      loading={loading}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptionsFunction}
      closeIcon={
        showClearIcon && (
          <SVGIcon
            size="16px"
            icon="close-rounded"
            color={ThemeV2.palette.gray9}
          />
        )
      }
      groupBy={groupBy}
      renderInput={renderInput}
      PaperComponent={expandableDropdown ? StyledDynamicPaper : StyledPaper}
      renderOption={renderOption}
      getOptionDisabled={getOptionDisabled}
      renderTags={renderTags}
      classes={classes}
      className={className}
      popupIcon={
        <SVGIcon
          size="16px"
          icon="caret-rounded"
          color={disabled ? ThemeV2.palette.gray6 : ThemeV2.palette.gray9}
        />
      }
      disableCloseOnSelect={disableCloseOnSelect}
      limitTags={limitTags}
      autoHighlight
      autoSelect={autoSelect}
      onBlur={onBlur}
      onFocus={onFocus}
      ListboxProps={ListboxProps}
      disabled={disabled}
      data-testid={dataTestId}
      validation={validation}
    />
  );
}

export function SingleSelectAutoComplete<T extends string | number, U>(
  props: SingleSelectAutoCompleteProps<T, U>,
) {
  const { value, onChange, disabled, validation } = props;
  const handleOnChange = useCallback(
    (event: unknown, val: PossibleAutoCompleteValues<T, U>) => {
      onChange(val as AutocompleteDropdownItem<T, U> | null);
    },
    [onChange],
  );
  const convertedValue = useMemo(() => {
    return value as PossibleAutoCompleteValues<T, U>;
  }, [value]);
  return (
    <AutoCompleteCore
      {...props}
      value={convertedValue}
      onChange={handleOnChange}
      multiple={false}
      disabled={disabled}
      validation={validation}
    />
  );
}
