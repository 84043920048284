import titleize from 'titleize';

const APP_CURRENCY_NAME = 'gold';
const APP_RECOGNITION = 'recognition';
const APP_RECOGNITION_PAST = 'recognized';

const APP_NAME = 'Assembly';

export type AppCurrencyDetails = {
  NAME: string;
  NAME_PLURAL: string;
  NAME_TITLEIZED: string;
  NAME_TITLEIZED_PLURAL: string;
  NAME_ALL_CAPS: string;
  NAME_ALL_CAPS_PLURAL: string;
  APP_CURRENCY_WITH_SPACES: string;
  APP_CURRENCY_WITH_SPACES_PLURAL: string;
};

type CurrencyDetails = { singular?: string; plural?: string };

const GET_APP_CURRENCY = (
  currency: CurrencyDetails = { singular: undefined, plural: undefined },
): AppCurrencyDetails => {
  const APP_CURRENCY_STR = currency.singular || APP_CURRENCY_NAME;
  const APP_CURRENCY_PLURAL_STR = currency.plural || APP_CURRENCY_NAME;
  return {
    NAME: APP_CURRENCY_STR,
    NAME_PLURAL: APP_CURRENCY_PLURAL_STR,
    NAME_TITLEIZED: APP_CURRENCY_STR,
    NAME_TITLEIZED_PLURAL: APP_CURRENCY_PLURAL_STR,
    NAME_ALL_CAPS: APP_CURRENCY_STR.toUpperCase(),
    NAME_ALL_CAPS_PLURAL: APP_CURRENCY_PLURAL_STR.toUpperCase(),
    APP_CURRENCY_WITH_SPACES: ` ${APP_CURRENCY_STR} `,
    APP_CURRENCY_WITH_SPACES_PLURAL: ` ${APP_CURRENCY_PLURAL_STR} `,
  };
};

const APP_VERBS = {
  RECOGNITION: APP_RECOGNITION,
  RECOGNITION_PAST: APP_RECOGNITION_PAST,
  RECOGNITION_TITLEIZED: titleize(APP_RECOGNITION),
  GAVE_RECOGNITION: 'recognized',
  MENTIONED: 'mentioned',
};

const FLASHBAR_AUTOHIDE_DURATION = 3000;

const FLASHBAR_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'error',
  INFO: 'info',
};

export { FLASHBAR_AUTOHIDE_DURATION, FLASHBAR_TYPES };
export {
  CULTURE_REWARDS,
  REWARD_TYPES,
  DEFAULT_COUNTRY,
  GET_APP_CURRENCY,
  APP_VERBS,
  APP_NAME,
  IMPACT_DATA,
  IMPACT_CUSTOM_OPTIONS,
  IMPACT_CUSTOM_OPTIONS_VALUES,
  PRIVATE_MESSAGE_OPTIONS_VALUES,
  ALLOWANCE_TYPES,
  PRIVATE_MESSAGE_OPTIONS,
  COOKIE_CONSTANTS,
};

const CULTURE_REWARDS = {
  MIN_DIMENSIONS: {
    WIDTH: 150,
    HEIGHT: 150,
  },
  FILE_SIZE_LIMIT: 15 * 1000 * 1000,
};

const ALLOWANCE_TYPES = {
  REGULAR: 'regular',
  CUSTOM: 'custom',
  NO_ALLOWANCE: 'no',
};

const REWARD_TYPES = {
  GIFT_CARD: 'gift card',
  CASH_EQUIVALENT: 'cash equivalent',
  DONATION: 'donation',
};

const DEFAULT_COUNTRY = 'US';

const IMPACT_DATA = {
  small: {
    displayName: 'Small Impact',
    emoji: '🙂',
  },
  medium: {
    displayName: 'Medium Impact',
    emoji: '😀',
  },
  large: {
    displayName: 'Large Impact',
    emoji: '👏',
  },
  huge: {
    displayName: 'Huge Impact',
    emoji: '💪',
  },
  above: {
    displayName: 'Above & Beyond',
    emoji: '🚀',
  },
};

const IMPACT_CUSTOM_OPTIONS_VALUES = {
  MANAGER_ONLY: 'MANAGER_ONLY',
  ADMINS_ONLY: 'ADMINS_ONLY',
  MANAGER_AND_CUSTOM_ALLOWANCES: 'MANAGER_AND_CUSTOM_ALLOWANCES_ONLY',
  EVERYONE: 'EVERYONE',
};

const PRIVATE_MESSAGE_OPTIONS_VALUES = {
  MANAGER_ONLY: 'MANAGER_ONLY',
  ADMINS_ONLY: 'ADMINS_ONLY',
  MANAGER_AND_CUSTOM_ALLOWANCES: 'MANAGER_AND_CUSTOM_ALLOWANCES_ONLY',
  EVERYONE: 'EVERYONE',
};

const IMPACT_CUSTOM_OPTIONS = [
  {
    label: 'Admins Only',
    value: IMPACT_CUSTOM_OPTIONS_VALUES.ADMINS_ONLY,
  },
  {
    label: 'Managers Only',
    value: IMPACT_CUSTOM_OPTIONS_VALUES.MANAGER_ONLY,
  },
  {
    label: 'Managers & Custom Allowances Only',
    value: IMPACT_CUSTOM_OPTIONS_VALUES.MANAGER_AND_CUSTOM_ALLOWANCES,
  },
];

const PRIVATE_MESSAGE_OPTIONS = [
  {
    label: 'Admins Only',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.ADMINS_ONLY,
  },
  {
    label: 'Managers Only',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.MANAGER_ONLY,
  },
  {
    label: 'Managers & Custom Allowances Only',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.MANAGER_AND_CUSTOM_ALLOWANCES,
  },
  {
    label: 'Entire organization',
    value: PRIVATE_MESSAGE_OPTIONS_VALUES.EVERYONE,
  },
];

const COOKIE_CONSTANTS = {
  FROM_IDENTITY_MANAGER_AUTHORIZE: 'FROM_IDENTITY_MANAGER_AUTHORIZE',
  SEEING_FIRST_TIME: 'SEEING-RECOGNITION-TIPS-FIRST-TIME',
  ASSEMBLY_TOKEN: 'assembly-token',
  ASSEMBLY_USER: 'assembly-user-data',
};
