import axios from 'axios';
import { API_URL_WITH_V2 } from '../../config';
import { adminProcessError, adminActions } from '../Admin';
import getter from '../../Utils/objectUtils/getter';
import { AuthHeaders } from '../shared/HeaderToken';
import {
  showSuccessMessage,
  showErrorMessage,
  showInfoMessage,
} from '../../Utils/flashHandler';
import { APP_NAME, APP_VERBS } from '../../Utils/constants';

export const SLACK_CHANNEL_MODAL_STATUS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  RESET: 'RESET',
};

// ------------------------------------
// Helpers
// ------------------------------------
const apiEndpoints = {
  SLACK_AUTHORIZE: `${API_URL_WITH_V2}/employer/integrations/slack/authorize`,
  CREATE_SLACK_CHANNEL: `${API_URL_WITH_V2}/employer/integrations/slack/createChannel`,
  SLACK_DE_AUTHORIZE: `${API_URL_WITH_V2}/employer/integrations/slack/deauthorize`,
};

// ------------------------------------
// Constants
// ------------------------------------

export const SLACK_AUTHORIZATION_SUCCESS = 'slack_authorization_success';
export const CREATE_SLACK_CHANNEL_SUCCESS = 'create_slack_channel_success';
export const SLACK_DEAUTHORIZATION_SUCCESS = 'slack_deauthorization_success';
export const INTEGRATION_REQUEST_FAILED = 'integration_request_failed';
export const CHANNEL_NAME_FAILED = 'channel_name_failed';
export const SET_SLACK_CHANNEL_MODAL_STATUS = 'set_slack_channel_modal_status';

// ------------------------------------
// Actions
// ------------------------------------

const slackAuthorizationSuccess = () => ({
  type: SLACK_AUTHORIZATION_SUCCESS,
});
const createSlackChannelSuccess = () => ({
  type: CREATE_SLACK_CHANNEL_SUCCESS,
});
const slackDeauthorizationSuccess = () => ({
  type: SLACK_DEAUTHORIZATION_SUCCESS,
});
const setSlackChannelModalStatus = (status) => ({
  type: SET_SLACK_CHANNEL_MODAL_STATUS,
  status,
});
const channelNameFailed = (errorMessage) => ({
  type: CHANNEL_NAME_FAILED,
  errorMessage,
});

const requestFailed = (errorMessage) => ({
  type: INTEGRATION_REQUEST_FAILED,
  error: errorMessage,
});

export const integrationsActions = {
  slackAuthorizationSuccess,
  createSlackChannelSuccess,
  slackDeauthorizationSuccess,
  requestFailed,
  channelNameFailed,
  setSlackChannelModalStatus,
};

// ------------------------------------
// API Wrapper
// ------------------------------------

export const authorizeSlack = (props) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        apiEndpoints.SLACK_AUTHORIZE,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      if (result.success) {
        dispatch(integrationsActions.slackAuthorizationSuccess());
        const { employer } = result.data;
        if (employer) {
          dispatch(adminActions.gotMyEmployer(employer));
        }
        const slackAuthenticated =
          getter(['integrations', 'slack', 'isAuth'], employer) || false;
        const slackAdded =
          getter(['integrations', 'slack', 'isAdded'], employer) || false;
        if (slackAuthenticated && !slackAdded) {
          dispatch(
            integrationsActions.setSlackChannelModalStatus(
              SLACK_CHANNEL_MODAL_STATUS.OPEN,
            ),
          );
        }
        dispatch(
          showSuccessMessage(
            `${APP_NAME} is now connected to your Slack workspace!`,
          ),
        );
      }
    } catch (error) {
      adminProcessError(error, dispatch, integrationsActions);
      dispatch(
        showErrorMessage(
          'Whoops, looks like we’re experiencing network issues -- Try again.',
        ),
      );
    }
  };
};

export const createSlackChannel = (props) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        apiEndpoints.CREATE_SLACK_CHANNEL,
        props,
        AuthHeaders(),
      );
      const result = res.data;
      if (result.success) {
        dispatch(integrationsActions.createSlackChannelSuccess());
        const { employer } = result.data;
        if (employer) {
          dispatch(adminActions.gotMyEmployer(employer));
        }
        dispatch(
          showSuccessMessage(
            `${APP_VERBS.RECOGNITION_TITLEIZED} given will now appear in the #${props.channelName} Slack channel!`,
          ),
        );
        dispatch(
          integrationsActions.setSlackChannelModalStatus(
            SLACK_CHANNEL_MODAL_STATUS.CLOSE,
          ),
        );
      }
    } catch (error: any) {
      adminProcessError(error, dispatch, integrationsActions);
      if (error && error.response && error.response.data) {
        const responseData = error.response.data;
        if (responseData.code === 102) {
          // throw new SubmissionError({
          //   channelName: responseData.message,
          //   _error: 'Failed!',
          // });
          // dispatch(integrationsActions.channelNameFailed(responseData.message));
        }
      }
      dispatch(
        showErrorMessage(
          'Whoops, looks like we’re experiencing network issues -- Try again.',
        ),
      );
    }
  };
};
export const deauthorizeSlack = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        apiEndpoints.SLACK_DE_AUTHORIZE,
        {},
        AuthHeaders(),
      );
      const result = res.data;
      if (result.success) {
        dispatch(integrationsActions.slackDeauthorizationSuccess());
        const { employer } = result.data;
        if (employer) {
          dispatch(adminActions.gotMyEmployer(employer));
        }
        dispatch(
          showInfoMessage(
            `${APP_NAME} is now disconnected from your Slack workspace!`,
          ),
        );
      }
    } catch (error) {
      adminProcessError(error, dispatch, integrationsActions);
      dispatch(
        showErrorMessage(
          'Whoops, looks like we’re experiencing network issues -- Try again.',
        ),
      );
    }
  };
};

export const setCreateModalStatus = (status) => (dispatch) => {
  dispatch(integrationsActions.setSlackChannelModalStatus(status));
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (state = {}, action) => {
  switch (action.type) {
    case SLACK_AUTHORIZATION_SUCCESS:
      return { ...state, errors: null, error: null };
    case SLACK_DEAUTHORIZATION_SUCCESS:
      return { ...state, errors: null, error: null };
    case INTEGRATION_REQUEST_FAILED:
      return { ...state, error: action.errorMessage, errors: null };
    case SET_SLACK_CHANNEL_MODAL_STATUS:
      return {
        ...state,
        channelModalStatus: action.status,
        errors: null,
        error: null,
      };
    case CHANNEL_NAME_FAILED:
      return {
        ...state,
        errors: { channelName: action.errorMessage },
        error: null,
      };
    default:
      return state;
  }
};
