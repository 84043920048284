/* eslint-disable max-len */
import { PLAN_IDS, Tiers } from '../../config';

const tier1 = {
  id: PLAN_IDS.tier1,
  featureList: [
    'No credit card required',
    'Up to 10 team members',
    'Unlimited flows',
    'Access to workflow templates',
    'Up to 1 app integrations',
  ],
};

const tier2 = {
  id: PLAN_IDS.tier2,
  featureList: [
    'Up to 50 team members',
    'Unlimited flows',
    'Customized culture rewards',
    '30 days of version history',
    'Up to 5 app integrations',
    'Up to 10 gift card options',
    'Custom core values',
    'Custom currency',
    'Custom allowances',
  ],
};

const tier3 = {
  id: PLAN_IDS.tier3,
  featureList: [
    'Unlimited team members',
    'Unlimited version history',
    'HRIS Integrations',
    'SAML + SSO',
    'Company branding',
    'Birthday/Anniversary bot',
    'All 600+ gift cards',
    'VISA reward card',
    'Premium customer support',
  ],
};

const tier4 = {
  id: PLAN_IDS.tier4,
  // NAME: 'Enterprise',
  // PRICE: { US: 8.50 },
  // type: 'paid',
  featureList: [
    'Dedicated Account Manager',
    '99.99% guaranteed uptime SLA',
    'Custom contracts',
    'Onboarding support',
    'Executive training & office hours',
    'Admin & employee trainings',
    'Quarterly business reviews',
  ],
};

export const getTierForPlanId = (planId: string) => {
  const currentPlans = Object.keys(PLAN_IDS);
  let tier = 'tier1';
  currentPlans.forEach((tierVal) => {
    if (PLAN_IDS[tierVal as Tiers] === planId) {
      tier = tierVal;
    }
  });
  return tier;
};

export const FEATURE_STATUS = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

export const FEATURE_LIST = {
  HISTORY_DURATION_IN_MONTHS: 'HISTORY_DURATION_IN_MONTHS',
  PER_SEAT_PRICE: 'PER_SEAT_PRICE',
  CHARGEABLE: 'CHARGEABLE',
  CULTURE_REWARDS: 'CULTURE_REWARDS',
  CHARITY_REWARDS: 'CHARITY_REWARDS',
  AXOMO_SWAGS: 'ALLOW_REWARDS_PROVIDER',
  GIFT_CARD_REWARDS: 'GIFT_CARD_REWARDS',
  INTERNATIONAL_GIFT_CARD_REWARDS: 'INTERNATIONAL_GIFT_CARD_REWARDS',
  CASH_EQUIVALENT_REWARDS: 'CASH_EQUIVALENT_REWARDS',
  EMPLOYEE_LIMIT: 'EMPLOYEE_LIMIT',
  INTEGRATIONS_LIMIT: 'INTEGRATIONS_LIMIT',
  CUSTOM_ALLOWANCE: 'CUSTOM_ALLOWANCE',
  BIRTHDAY_BOT: 'BIRTHDAY_BOT',
  ANNIVERSARY_BOT: 'ANNIVERSARY_BOT',
  PREMIUM_CUSTOMER_SUPPORT: 'PREMIUM_CUSTOMER_SUPPORT',
  RECOGNITION_MESSAGES: 'RECOGNITION_MESSAGES',
  CORE_VALUES: 'CORE_VALUES',
  PAID_ALLOWANCE: 'PAID_ALLOWANCE',
  SWITCH_PLAN: 'SWITCH_PLAN',
  CUSTOM_CURRENCY: 'CUSTOM_CURRENCY',
  CUSTOM_BRAND_LOGO: 'CUSTOM_BRAND_LOGO',
  PRIVATE_MESSAGE: 'PRIVATE_MESSAGES',
  ACCOUNT_MANAGEMENT_SELECTION: 'ACCOUNT_MANAGEMENT_SELECTION',
  FEED_SEARCH: 'FEED_SEARCH',
  ADMIN_ANALYTICS: 'ADMIN_ANALYTICS',
  ALLOW_ADP_IDENTITY_MANAGER: 'ALLOW_ADP_IDENTITY_MANAGER',
  ALLOW_MERGE_IDENTITY_MANAGER: 'ALLOW_MERGE_IDENTITY_MANAGER',
  SAML: 'SAML',
  ALLOW_TO_CHANGE_USER_INVITE_AND_REQUEST_PERMISSION:
    'ALLOW_TO_CHANGE_USER_INVITE_AND_REQUEST_PERMISSION',
  ALLOW_TO_CHANGE_ASSEMBLY_CURRENCY: 'ALLOW_TO_CHANGE_ASSEMBLY_CURRENCY',
  ALLOW_TO_CHANGE_ASSEMBLY_LOGO: 'ALLOW_TO_CHANGE_ASSEMBLY_LOGO',
  ALLOW_AMAZON_BUSINESS: 'ALLOW_AMAZON_BUSINESS',
};

export const getFeatureDeatilsForPlan = (
  // TO DO: Sumedha - validate and remove any type
  currentPlan: { features: any },
  feature: string,
) => {
  if (!currentPlan) {
    return null;
  }
  const { features } = currentPlan;
  const featureDetails = features.find(
    (feature_: { id: any }) => feature_.id === feature,
  );
  return featureDetails;
};

export const isFeatureEnabled = (featureList: any[], requiredFeature: string) =>
  featureList?.find((feature: { id: any }) => feature?.id === requiredFeature)
    ?.status === FEATURE_STATUS.ENABLED;

export const FAQData = (customCurrency: { NAME_TITLEIZED_PLURAL: any }) => [
  {
    QUESTION: 'Is there any limit to the number of messages we can post?',
    ANSWER:
      'Nope! You can post as many messages on Assembly as you’d like. There’s never an additional charge for recognizing team members more in Assembly.',
  },
  {
    QUESTION:
      'How many team members can I have on the Standard or Premium plan?',
    ANSWER:
      'There are no limits on number of team members the Standard or Premium plan. Only our Starter and Lite plans have seat limits (which is subject to change)',
  },
  {
    QUESTION: 'Do I have to install anything or talk to IT?',
    ANSWER: `Nope! You can do all your recognition and ${customCurrency.NAME_TITLEIZED_PLURAL} giving through your web browser. That said, if you'd prefer, we do offer a Slack app integration.`,
  },
  {
    QUESTION: 'Do you have discounts for non-profits and education?',
    ANSWER:
      'We sure do! Assembly is 20% off for non-profits and charities. To get the discounted rate, just contact our support team. They’ll ask you for some documentation verifying that you’re eligible, and then they’ll apply the discount to your account.',
  },
  {
    QUESTION: 'What if I change my mind?',
    ANSWER:
      'No problem, you can cancel anytime, all on your own, no questions asked. You can even get an export of all your data to keep forever for reference.',
  },
];

export const downgradeToBusinessFeatureLoss = [
  'You will lose your dedicated account manager',
  'If you have a custom contract, it will remain in place till the end of its term and after be terminated and move to monthly billing',
  'Any onboarding support will be lost',
  'No executive training or available office hours',
  'No admin or employee training to help boost your engagement',
  'No quarterly business reviews',
];

export const downgradeToTeamFeatureLoss = [
  'New features are premiered and typically limited to the Business and Enterprise accounts',
  'Any Birthday or Anniversary bot messages will no longer be automated or sent',
  'Only U.S. based gift cards will be available',
  `The gift card catalog will be limited to 10 of your choice — we'll automatically pick the most used`,
  'No cash equivalent gift card is available like the VISA card',
  'You will still have top notch support but we prioritize our Business and Enterprise accounts',
  `You'll only have 3 months worth of recognition history to review`,
  'Your HRIS connection will be turned off or you will lose access to HRIS integrations',
  'Any company branding will revert back to Assembly branding',
  'Your account will be limited to 50 seats and if over that, you will only have a short grace period before your account is deactivated',
];

export const downgradeToFreeFeatureLoss = (customCurrency: {
  NAME_TITLEIZED_PLURAL: any;
}) => [
  'Any HRIS or SAML integrations will be immediately disconnected',
  'All permissions will be reverted back to "Everyone"',
  'You’ll only have 3 months worth of recognition history to review',
  'Members with custom allowances will be reverted to your default allowance',
  'Team members will lose access to premier rewards (charities and gift cards)',
  // 'You will have to choose the 3 integrations you want to keep',
  'Custom core values will be replaced with badges and history will be lost',
  // 'Any Birthday or Anniversary bot messages will no longer be automated',
  `All ${customCurrency.NAME_TITLEIZED_PLURAL} will lose their dollar value`,
  'Lose access to the latest upgrades that are released on Team, Business, or Enterprise plans only',
];

export default {
  tier1,
  tier2,
  tier3,
  tier4,
};
