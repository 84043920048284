import React from 'react';
import {
  StyledProgressBarWrapper,
  StyledProgressBar,
  ColorTypes,
} from './styles';

type ProgressBarProps = {
  background?: string;
  className?: string;
  color?: ColorTypes;
  dataTestId?: string;
  height?: string;
  value?: number;
};

const ProgressBar = (props: ProgressBarProps) => {
  const {
    color,
    value = 0,
    background,
    height,
    className,
    dataTestId = 'progress-bar',
  } = props;

  return (
    <StyledProgressBarWrapper
      background={background}
      height={height}
      className={className}
      data-testid={dataTestId}
    >
      <StyledProgressBar color={color} value={value} />
    </StyledProgressBarWrapper>
  );
};

export default ProgressBar;
