import React, { Component } from 'react';
import { Img } from 'react-image';
import Button from '../atomic/atoms/Button';
import { detect } from 'detect-browser';
import disassembled from '../img/svgs/disassembled.svg';
import safariLogo from '../img/safariLogo.png';
import chromeLogo from '../img/chromeLogo.png';
import edgeLogo from '../img/edgeLogo.png';
import firefoxLogo from '../img/firefoxLogo.png';
import { APP_NAME } from '../Utils/constants';

type ErrorBoundaryProps = { children: any };

type ErrorBoundaryState = {
  hasError: boolean;
  unSupportedBrowser: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, unSupportedBrowser: false };
  }

  componentDidCatch() {
    // Display fallback UI
    const browser = detect();
    let isSupportedBrowser = true;
    if (browser) {
      if (browser.name === 'chrome') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (browser.version < 66) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'firefox') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (browser.version < 60) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'safari') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (browser.version < 11) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'edge') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (browser.version < 15) {
          isSupportedBrowser = false;
        }
      } else if (browser.name === 'ie') {
        isSupportedBrowser = false;
      }
    }
    if (!isSupportedBrowser) {
      this.setState({ unSupportedBrowser: true });
    } else {
      // this.setState({ unSupportedBrowser: true });
      this.setState({ hasError: true });
    }
  }

  onBackHomeClick = () => {
    this.setState({ hasError: false, unSupportedBrowser: false });
    window.location.href = `${window.location.origin}/home`;
    // history.push('/home');
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="somethingwentwrong-bg__div">
          <div className="vertical-center">
            <div className="container">
              <div className="row justify-content-center">
                <img className="nopage-image" src={disassembled} />
              </div>
              <div className="row justify-content-center top-buffer-new-row">
                <div className="nopage-subheader__div">
                  Oops! Something was disassembled.
                </div>
              </div>
              <div className="row justify-content-center top-buffer-row-small-new bottom-buffer-row-new">
                <div className="nopage-body__div">
                  Head back home while we re-assemble.
                </div>
              </div>
              <div className="row justify-content-center top-buffer-row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onBackHomeClick}
                >
                  Take me home
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.unSupportedBrowser) {
      return (
        <div className="somethingwentwrong-bg__div">
          <div className="vertical-center">
            <div className="container">
              {/* <div className="row  justify-content-center">
                <img className='sww-broken-carrot__img' alt="somethingwentwrong" src={broken_carrot} />
              </div> */}
              <div
                className="row  justify-content-center top-buffer-row"
                style={{ paddingTop: '9%' }}
              >
                <div className="nobrowser-subheader__div">
                  SORRY, THIS BROWSER OR VERSION IS NOT SUPPORTED
                </div>
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <div className="nobrowser-body__div">
                  To best experience {APP_NAME}, please download or swtich to
                  the latest one of these browsers
                </div>
              </div>
              <div className="row  justify-content-center top-buffer-row">
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="safari"
                        src={safariLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Safari
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 11+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="chrome"
                        src={chromeLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Chrome
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 66+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="firefox"
                        src={firefoxLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Firefox
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 60+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2-lg nobrowser-col__div">
                  <div className="container">
                    <div className="row justify-content-center">
                      <Img
                        className="browser-logo__img"
                        alt="edge"
                        src={edgeLogo}
                      />
                    </div>
                    <div className="row justify-content-center top-buffer-row">
                      <div className="container">
                        <div className="row justify-content-center nobrowser-name__div">
                          Edge
                        </div>
                        <div className="row justify-content-center nobrowser-version__div">
                          Version: 15+
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
