import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { getFirstLetterOfName } from './text';
import profilePlaceholder from '../img/profilePlaceholder.png';
import getter from './objectUtils/getter';

const useStyles = makeStyles({
  avatar: {
    margin: 10,
  },
  avatarColor: (props: any) => ({
    // margin: 10,
    color: props?.color,
    backgroundColor: props?.backgroundColor,
    fontWeight: '600' as any,
    width: props?.size ? props?.size : 40,
    height: props?.size ? props?.size : 40,
    fontSize: props?.fontSize,
  }),
  avatarImage: (props: any) => ({
    width: props?.size ? props?.size : 40,
    height: props?.size ? props?.size : 40,
  }),
});

export const getColor = (user) => {
  // let firstName = "m";
  // if (user && user.profile) {
  //   firstName = user.profile.firstName.toLowerCase();
  // }
  // else if(user && user.firstName) {
  //   firstName = user.firstName;
  // }
  let lastChar = 'p';
  if (user && user._id) {
    lastChar = user._id.substr(user._id.length - 1);
  }
  const charCode = lastChar.charCodeAt(0);
  let bg = '#531DAB';
  const color = '#FFFFFF';
  switch (charCode) {
    case 97:
    case 113:
    case 103:
    case 119:
    case 49:
    case 111:
      bg = '#C41D7F';
      break;
    case 98:
    case 114:
    case 104:
    case 120:
    case 50:
    case 57:
      bg = '#08979C';
      break;
    case 99:
    case 115:
    case 106:
    case 122:
    case 52:
    case 112:
      bg = '#7CB305';
      break;
    case 100:
    case 116:
    case 105:
    case 121:
    case 51:
    case 108:
      bg = '#D4B106';
      break;
    case 101:
    case 117:
    case 107:
    case 53:
    case 110:
    case 56:
    case 54:
      bg = '#D46B08';
      break;
    case 102:
    case 118:
    case 48:
    case 109:
    case 55:
      bg = '#D4380D';
      break;
    default:
      bg = '#096DD9';
      break;
  }
  return {
    bg,
    color,
  };
};

const UserNameAvatar = (props) => {
  const { user, size } = props;
  const firstLetter = user.isDeleted ? 'D' : getFirstLetterOfName(user);
  const colorProp = user.isDeleted
    ? { bg: '#FFF0A2', color: '#FFE145' }
    : getColor(user);
  let fontSize = 18;
  if (size === 30 || size === 32) {
    fontSize = 14;
  } else if (size === 88) {
    fontSize = 36;
  }
  const styleProps = {
    backgroundColor: colorProp.bg,
    color: colorProp.color,
    size,
    fontSize,
  };
  const classes = useStyles(styleProps);

  return <Avatar className={classes.avatarColor}>{firstLetter}</Avatar>;
};

export const ProfilePic = (props) => {
  const { user, size } = props;
  const classes = useStyles({ size });
  const image =
    user?.profile?.image?.resized?.relativeUrl ||
    user?.profile?.image?.original?.relativeUrl;

  if (image) {
    return <Avatar src={image} className={classes.avatarImage} />;
  }
  return <UserNameAvatar user={user} size={size} />;
};

export const getImageForCustomReward = (cultureReward) => {
  const relativeUrl = getter(
    ['image', 'original', 'relativeUrl'],
    cultureReward,
  );
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const image_url = getter(['image_url'], cultureReward);
  const imageUrl = getter(['imageUrl'], cultureReward);
  if (relativeUrl) {
    return relativeUrl;
  }
  if (image_url) {
    return image_url;
  }
  if (imageUrl) {
    return imageUrl;
  }
  return profilePlaceholder;
};
