import React from 'react';
import { Helmet } from 'react-helmet-async';

export default () => (
  <Helmet>
    <script
      src="https://unpkg.com/xlsx/dist/xlsx.full.min.js"
      type="text/javascript"
    />
  </Helmet>
);
