import { requestsReducer, success } from 'redux-saga-requests';
import _ from 'lodash';

export const EMPLOYER_SLACK_DETAILS = '@admin/slack/EMPLOYER_SLACK_DETAILS';

export default requestsReducer({
  actionType: EMPLOYER_SLACK_DETAILS,
  getData: (state, action) => {
    switch (action.type) {
      case success(EMPLOYER_SLACK_DETAILS): {
        const { details } = _.get(action, ['data', 'data'], {});
        return { ...state.data, ...details };
      }
      default:
        return state;
    }
  },
});
