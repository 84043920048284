import React from 'react';
import { Link } from 'react-router-dom';

type AdminBreadCrumpsPops = {
  primaryText: string;
  secondaryText?: string;
  thirdText?: string;
  link?: string;
};

const AdminBreadCrumps = ({
  thirdText,
  link,
  primaryText,
  secondaryText,
}: AdminBreadCrumpsPops) => {
  return (
    <div className="">
      {thirdText ? (
        <>
          <span className="admin-breadcrumps-primary__span">{primaryText}</span>
          {link ? (
            <span className="admin-breadcrumps-primary__span">
              {' '}
              &gt;&nbsp;
              <Link to={link} className="admin-breadcrumps-primary__link">
                {secondaryText}
              </Link>
            </span>
          ) : (
            <span className="admin-breadcrumps-primary__span">
              {' '}
              &gt; {secondaryText}
            </span>
          )}

          <span className="admin-breadcrumps-secondary__span">
            {' '}
            &gt; {thirdText}
          </span>
        </>
      ) : (
        <>
          <span
            className={
              secondaryText
                ? 'admin-breadcrumps-primary__span'
                : 'admin-breadcrumps-secondary__span'
            }
          >
            {primaryText}
          </span>
          {secondaryText && (
            <span className="admin-breadcrumps-secondary__span">
              {' '}
              &gt; {secondaryText}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default AdminBreadCrumps;
