import _ from 'lodash';
import { createSelector } from 'reselect';
import convertToArray from '../../../Utils/objectUtils/convertToArray';

export const getAccountsObjectSelector = (state: any) => {
  return _.get(state, ['superAdmin', 'data', 'accounts'], {});
};

export const getAccountsSelector = createSelector(
  getAccountsObjectSelector,
  (accounts) => {
    return convertToArray(accounts);
  },
);

export const getAccountCustomIconSelector = (state: any, employer: any) => {
  let icon: { type?: string; value?: any } | null = null;
  const customIcon = _.get(
    employer,
    ['customCurrency', 'currencyIconURL'],
    null,
  );
  const emoji = _.get(employer, ['customCurrency', 'currencyEmoji'], null);
  if (customIcon && customIcon.length > 0) {
    icon = {};
    icon.type = 'icon';
    icon.value = customIcon;
  } else if (emoji) {
    icon = {};
    icon.type = 'emoji';
    icon.value = emoji;
  }
  return icon;
};
