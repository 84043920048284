import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import ThemeV2 from '../../../componentsV2/theme';

interface LinkProps {
  to: string;
  className?: string;
  children: React.ReactChild;
}

const useStyles = makeStyles({
  root: {
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontStyle: 'normal',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
    color: ThemeV2.palette.geekBlue6,
    transition: '0.2s ease color',
    '&:hover': {
      color: ThemeV2.palette.geekBlue5,
    },
    '&:focus': {
      color: ThemeV2.palette.geekBlue7,
    },
    '&:active': {
      color: ThemeV2.palette.geekBlue7,
    },
  },
});

const Link = (props: LinkProps) => {
  const classes = useStyles(props);
  const { children, to, className } = props;
  return (
    <RouterLink to={to} className={`${classes.root} ${className}`}>
      {children}
    </RouterLink>
  );
};

export default Link;
