import { createSelector } from 'reselect';
import _ from 'lodash';
import { regularAllowanceSelector } from '../admin/selector';
import { ALLOWANCE_TYPES } from '../../Utils/constants';
import { checkAdmin } from '../../Utils/admin';

export const meSelector = (state: any) => _.get(state, ['me', 'me'], null);

export const checkIfAdmin = createSelector(meSelector, (me) => {
  if (me) {
    return checkAdmin(me.role);
  }
  return false;
});

export const getMyAllowanceAndTypeSelector = createSelector(
  meSelector,
  regularAllowanceSelector,
  (me, regularAllowance) => {
    if (!me) {
      return { type: ALLOWANCE_TYPES.REGULAR, allowance: 0 };
    }
    const myAllowanceType = _.get(
      me,
      ['profile', 'allowanceType'],
      ALLOWANCE_TYPES.REGULAR,
    );
    if (myAllowanceType === ALLOWANCE_TYPES.REGULAR) {
      return { type: ALLOWANCE_TYPES.REGULAR, allowance: regularAllowance };
    }
    if (myAllowanceType === ALLOWANCE_TYPES.NO_ALLOWANCE) {
      return { type: ALLOWANCE_TYPES.NO_ALLOWANCE, allowance: 0 };
    }
    const myCustomAllowance = _.get(
      me,
      ['profile', 'customAllowance'],
      ALLOWANCE_TYPES.CUSTOM,
    );
    return { type: ALLOWANCE_TYPES.CUSTOM, allowance: myCustomAllowance };
  },
);

export const getMySlackTeamIdInfo = (state: any) =>
  _.get(state, ['me', 'me', 'integrations', 'slack_v2', 'teamID'], null);
