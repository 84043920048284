import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';
import { BaseCSSProperties } from '@material-ui/styles';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

interface VariantProps {
  background?: string;
  border?: string;
  color?: string;
  focusColor?: string;
  iconColor?: string;
  padding?: string;
}

const variantMap: { [Key: string]: VariantProps } = {
  info: {
    background: ThemeV2.palette.geekBlue1,
    border: ThemeV2.palette.geekBlue1,
    color: ThemeV2.palette.gray9,
    focusColor: alpha(ThemeV2.palette.gray9, 0.1),
    iconColor: ThemeV2.palette.geekBlue6,
  },
  error: {
    background: ThemeV2.palette.red1,
    border: ThemeV2.palette.dustRed6,
    color: ThemeV2.palette.dustRed6,
    focusColor: alpha(ThemeV2.palette.dustRed6, 0.1),
    iconColor: ThemeV2.palette.dustRed6,
  },
  warning: {
    background: ThemeV2.palette.orange2,
    border: ThemeV2.palette.orange2,
    color: ThemeV2.palette.gray9,
    focusColor: alpha(ThemeV2.palette.gray9, 0.1),
    iconColor: ThemeV2.palette.orange6,
  },
};

const useStyles = makeStyles({
  root: (props: { alertType: string; customVariant?: VariantProps }) => ({
    display: 'flex',
    padding:
      props.alertType === 'custom' && props.customVariant?.padding
        ? props.customVariant?.padding
        : '8px 16px',
    width: '100%',

    background:
      props.alertType === 'custom' && props.customVariant
        ? `${props.customVariant.background}`
        : variantMap[props.alertType].background,
    border:
      props.alertType === 'custom' && props.customVariant
        ? `${props.customVariant.border}`
        : variantMap[props.alertType].border,
    borderRadius: '4px',
  }),
  iconWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginRight: '4px',
    marginTop: '3px',
  },
  alertText: (props: { alertType: string; customVariant?: VariantProps }) => ({
    color:
      props.alertType === 'custom' && props.customVariant
        ? `${props.customVariant.color}`
        : variantMap[props.alertType].color,
    marginRight: '8px',
  }),
  closeButtonWrapper: (props: {
    alertType: string;
    customVariant?: VariantProps;
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '22px',
    borderRadius: '20px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      background:
        props.alertType === 'custom' && props.customVariant
          ? `${props.customVariant.focusColor}`
          : variantMap[props.alertType].focusColor,
    },
  }),
});

interface StyleProps {
  root: BaseCSSProperties;
  iconWrapper: BaseCSSProperties;
  alertText: BaseCSSProperties;
  closeButtonWrapper: BaseCSSProperties;
}

type PropsClasses = Partial<Record<keyof StyleProps, string>>;

interface InfoAlertProps {
  id: number | string;
  className?: string;
  classes?: PropsClasses;
  text: string | React.ReactNode;
  icon?: string;
  alertType: string;
  isHidden?: boolean;
  isDismissible?: {
    closeIconColor?: string;
    onCloseClick(id: number | string): void;
    onCloseKeyPress(id: number | string): void;
  };
  customVariant?: VariantProps;
  bodyVariant?: string;
  role?: string;
}

const InfoAlert = (props: InfoAlertProps) => {
  const classes = useStyles(props);
  const {
    id,
    text,
    className,
    icon,
    alertType,
    isHidden,
    isDismissible,
    customVariant,
    bodyVariant,
    role = 'alert',
  } = props;
  const style = `${className} ${classes.root}`;
  const { gray9 } = ThemeV2.palette;

  const closeClickCallback = useCallback(() => {
    if (isDismissible) {
      const { onCloseClick } = isDismissible;
      onCloseClick(id);
    }
  }, [id, isDismissible]);

  const closeKeyPressCallback = useCallback(() => {
    if (isDismissible) {
      const { onCloseKeyPress } = isDismissible;
      onCloseKeyPress(id);
    }
  }, [id, isDismissible]);

  const handleCollapseAlertKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      closeKeyPressCallback();
    }
  };

  return (
    <Fade in={!isHidden}>
      <div className={style} role={role}>
        {icon && (
          <div className={classes.iconWrapper}>
            <SVGIcon
              icon={icon}
              size="16px"
              color={
                alertType === 'custom' && customVariant
                  ? customVariant.iconColor
                  : variantMap[alertType].iconColor
              }
              aria-hidden="true"
            />
          </div>
        )}
        <Body
          variant={bodyVariant || 'body2'}
          className={classes.alertText}
          color={
            alertType === 'custom' && customVariant
              ? customVariant.color
              : variantMap[alertType].color
          }
        >
          {text}
        </Body>
        {isDismissible && (
          <div
            className={classes.closeButtonWrapper}
            onClick={closeClickCallback}
            onKeyPress={handleCollapseAlertKeyPress}
            role="button"
            tabIndex={0}
          >
            <SVGIcon
              icon="close"
              size="12px"
              color={isDismissible.closeIconColor || gray9}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    </Fade>
  );
};

export default InfoAlert;
