import React from 'react';
import classnames from 'classnames';
import Button from '../../core/Button';
import ThemeV2 from '../../theme';
import makeStyles from '@material-ui/styles/makeStyles';
import { PropsClasses } from '../../../interfaces/ClassesProps';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    padding: '6px 3px 6px 10px',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  svg: {
    display: 'inline-block',
    height: '1em',
    width: '1em',
    fill: ThemeV2.palette.darkText,
    transform: 'rotate(0deg) translateX(0)',
    fontSize: '1.5rem',
    flexShrink: 0,
    userSelect: 'none',
    marginLeft: 'auto',
    transition: '0.25s ease all',
    '&.open': {
      transform: 'rotate(180deg) translateX(0)',
    },
  },
});

const ButtonWithArrow = (props: {
  isOpen: boolean;
  children: React.ReactNode;
  onClick: (e: any) => void;
  classes?: PropsClasses;
}) => {
  const { isOpen, children, onClick, ...rest } = props;
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      aria-label="Add Emoji"
      onClick={onClick}
      {...rest}
    >
      {children}
      <svg
        className={classnames(classes.svg, isOpen ? 'open' : '')}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        role="presentation"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </Button>
  );
};

export default ButtonWithArrow;
