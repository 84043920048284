import RoutesList from '../../../aV2/routes/routesList';
import {
  TAKE_ME_BACK,
  TRY_AGAIN,
  WIRES_CROSSED,
} from '../../../languages/en/errors';

export enum SAMLAuthErrorCodes {
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  OAUTH_CODE_INVALID = 'OAUTH_CODE_INVALID',
  USER_NOT_ACTIVE = 'USER_NOT_ACTIVE',
  CREATE_MEMBER_ERROR = 'CREATE_MEMBER_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  ADMIN_APPROVAL_REQUESTED = 'ADMIN_APPROVAL_REQUESTED',
  SAML_NOT_ENABLED = 'SAML_NOT_ENABLED',
}

const ErrorData = {
  [SAMLAuthErrorCodes.OAUTH_CODE_INVALID]: {
    primaryText: 'Authentication failed',
    secondaryText:
      'Looks like your authentication token has expired. Please try signing in again.',
    buttonText: 'Setup new Assembly',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SAMLAuthErrorCodes.INVALID_PARAMETER]: {
    primaryText: WIRES_CROSSED,
    secondaryText: TRY_AGAIN,
    buttonText: TAKE_ME_BACK,
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SAMLAuthErrorCodes.CREATE_MEMBER_ERROR]: {
    primaryText: WIRES_CROSSED,
    secondaryText: TRY_AGAIN,
    buttonText: TAKE_ME_BACK,
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SAMLAuthErrorCodes.SAML_NOT_ENABLED]: {
    primaryText: WIRES_CROSSED,
    secondaryText: TRY_AGAIN,
    buttonText: TAKE_ME_BACK,
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SAMLAuthErrorCodes.USER_NOT_FOUND]: {
    primaryText: 'Account not found',
    secondaryText:
      'Sorry, we’re not able to find your account. Please talk to your admin or try creating a new Assembly',
    buttonText: 'Setup new Assembly',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SAMLAuthErrorCodes.USER_NOT_ACTIVE]: {
    primaryText: 'Account not active',
    secondaryText:
      'Sorry, your account is not active. Please talk to your admin or try creating a new Assembly',
    buttonText: 'Setup new Assembly',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: null,
  },
  [SAMLAuthErrorCodes.ADMIN_APPROVAL_REQUESTED]: {
    primaryText: 'Sorry, this Assembly is invite only.',
    secondaryText:
      'If you’d like to join, you’ll have to request permission from the Assembly admin for this account.',
    buttonText: 'Access Request Sent',
    urlToPush: RoutesList.SIGN_IN,
    buttonIcon: 'check-rounded',
  },
};

export const getSAMLErrorData = (errorCode: SAMLAuthErrorCodes) => {
  if (!Object.values(SAMLAuthErrorCodes).includes(errorCode)) {
    return {
      headerText: ErrorData[SAMLAuthErrorCodes.INVALID_PARAMETER].primaryText,
      bodyText: ErrorData[SAMLAuthErrorCodes.INVALID_PARAMETER].secondaryText,
      buttonText: ErrorData[SAMLAuthErrorCodes.INVALID_PARAMETER].buttonText,
      urlToPush: ErrorData[SAMLAuthErrorCodes.INVALID_PARAMETER].urlToPush,
      buttonIcon: ErrorData[SAMLAuthErrorCodes.INVALID_PARAMETER].buttonIcon,
    };
  }
  return {
    headerText: ErrorData[SAMLAuthErrorCodes[errorCode]].primaryText,
    bodyText: ErrorData[SAMLAuthErrorCodes[errorCode]].secondaryText,
    buttonText: ErrorData[SAMLAuthErrorCodes[errorCode]].buttonText,
    urlToPush: ErrorData[SAMLAuthErrorCodes[errorCode]].urlToPush,
    buttonIcon: ErrorData[SAMLAuthErrorCodes[errorCode]].buttonIcon,
  };
};
