// Important - Dont change it unless sure of it
const APP_STAGE = process.env.REACT_APP_STAGE || 'localdev';

const PLATFORM = 'web';
const MAX_CUSTOM_ALLOWANCE = 100000;

const WEB_BASE_URL = 'joinassembly.com';

const dev = {
  API_URL: 'https://api-dev.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.781607625924',
  // IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  AMAZON_BUSINESS_APPLICATION_ID:
    'amzn1.sp.solution.b1ec00cf-6603-47a8-bba3-20af3d8dacda',
  APP_URL: 'https://dev.joinassembly.com/',
  ADMIN_APP_URL: 'https://dev-admin.joinassembly.com',
  NEW_APP_URL: 'https://dev-app.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly_admin',
    env: 'dev',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
};

const local = {
  API_URL: 'http://localhost:8080',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.781607625924',
  IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  AMAZON_BUSINESS_APPLICATION_ID:
    'amzn1.sp.solution.b1ec00cf-6603-47a8-bba3-20af3d8dacda',
  APP_URL: 'http://localhost:3000/',
  ADMIN_APP_URL: 'http://localhost:4000',
  NEW_APP_URL: 'http://localhost:3030',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly-admin',
    env: 'local',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
};

const production = {
  API_URL: 'https://api-prod.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_live_1Sa8uGD8FGRnKqoamJIVNIt3',
  SLACK_APP_URL: '255786340961.404101138883',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  AMAZON_BUSINESS_APPLICATION_ID:
    'amzn1.sp.solution.b1ec00cf-6603-47a8-bba3-20af3d8dacda',
  APP_URL: 'https://my.joinassembly.com/',
  ADMIN_APP_URL: 'https://admin.joinassembly.com',
  NEW_APP_URL: 'https://app.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'f92aegb39s0q7892tqdttb2i1gql3tcmoc0g',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly',
  },
  DATA_DOG: {
    applicationId: 'c384b0d4-ee6d-46bf-8d86-af63928add76',
    clientToken: 'pub8d62f462bd2240b44d12ac3f881c0986',
    service: 'assembly_admin',
    env: 'prod',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/316521',
  MIXPANEL_KEY: '242212480a3ebd3854f67da96134fc40',
  PUSHER: {
    KEY: 'bb892a3236c82edfe227',
  },
};

const staging = {
  API_URL: 'https://api-stg.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.407704820679',
  IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  AMAZON_BUSINESS_APPLICATION_ID:
    'amzn1.sp.solution.b1ec00cf-6603-47a8-bba3-20af3d8dacda',
  APP_URL: 'https://stg.joinassembly.com/',
  ADMIN_APP_URL: 'https://stg-admin.joinassembly.com',
  NEW_APP_URL: 'https://stg-app.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly_admin',
    env: 'staging',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
};

const qa = {
  API_URL: 'https://api-qa.joinassembly.com',
  STRIPE_PUBLISHABLE: 'pk_test_m8GXOCB2pjtUnvAoJxDyJ19o',
  SLACK_APP_URL: '255786340961.781607625924',
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  AMAZON_BUSINESS_APPLICATION_ID:
    'amzn1.sp.solution.b1ec00cf-6603-47a8-bba3-20af3d8dacda',
  APP_URL: 'https://qa.joinassembly.com/',
  ADMIN_APP_URL: 'https://qa-admin.joinassembly.com',
  NEW_APP_URL: 'https://qa-app.joinassembly.com',
  SOURCE: 'API',
  SPLIT_AUTH_KEY: 'ibhu998u3ndtr0num1ss8pg8jn0md822v96f',
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly-dev',
  },
  DATA_DOG: {
    applicationId: '6ef28a14-4941-44b5-935e-75c07f51044c',
    clientToken: 'pubb2d38a6d5d5b49b338441586d68746a9',
    service: 'assembly_admin',
    env: 'qa',
  },
  ADP_MARKETPLACE_URL: 'https://apps.adp.com/apps/307297',
  MIXPANEL_KEY: 'f8cf5a53c3894e8d32611034942c03df',
  PUSHER: {
    KEY: '65656f0ec6850a60d9d5',
  },
  GOOGLE_RECAPTCHA_SITE_KEY: '6Leyk-oeAAAAACCnCogUKlajDEH15trv7Q4thR0Z',
};

const getConfig = () => {
  if (process.env.REACT_APP_STAGE === 'production') {
    return production;
  }
  if (process.env.REACT_APP_STAGE === 'development') {
    return dev;
  }
  if (process.env.REACT_APP_STAGE === 'staging') {
    return staging;
  }
  if (process.env.REACT_APP_STAGE === 'qa') {
    return qa;
  }
  return local;
};

const config = getConfig();
if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line no-console
  console.log('Running in ', APP_STAGE);
}

export type Tiers = 'tier1' | 'tier2' | 'tier3' | 'tier4';
type Plans = 'free' | 'lite' | 'standard' | 'premium';

const PLAN_IDS: Record<Tiers, Plans> = {
  tier1: 'free',
  tier2: 'lite',
  tier3: 'standard',
  tier4: 'premium',
};

const { API_URL: defaultApiUrl } = config;

const API_URL = process.env.REACT_APP_API_URL || defaultApiUrl;

const API_URL_WITH_V2 = `${API_URL}/api/v2`;
const API_URL_WITH_V3 = `${API_URL}/api/v3`;
const {
  STRIPE_PUBLISHABLE,
  GIPHY_KEY,
  APP_URL,
  ADMIN_APP_URL,
  NEW_APP_URL,
  SOURCE,
  SPLIT_AUTH_KEY,
  MS_TEAMS,
  ADP_MARKETPLACE_URL,
  MIXPANEL_KEY,
  DATA_DOG,
  AMAZON_BUSINESS_APPLICATION_ID,
} = config;

const GET_PLATFORM = (isFromMSTeams: boolean) =>
  isFromMSTeams ? 'MS Teams' : PLATFORM;

const LANDER_URL = `https://${WEB_BASE_URL}`;
const HELP_URL = `https://${WEB_BASE_URL}/faq`;
const TERMS_URL = `https://${WEB_BASE_URL}/terms-of-service`;
const PRIVACY_URL = `https://${WEB_BASE_URL}/privacy-policy`;

const IOS_URL = 'com.joinassembly.assembly:/';
const GO_TO_AXOMO_LINK =
  'https://shop.axomo.com/store-generator?referralCode=ASSEMBLY';
const AXOMO_URL = 'https://axomo.com';
const IP_API_KEY = 'NiQCyrJTYLg4dlnOJNhg4zvNQaw1PLfF5V05iDLHfGhNnaTu21';

export {
  API_URL,
  API_URL_WITH_V2,
  API_URL_WITH_V3,
  STRIPE_PUBLISHABLE,
  MAX_CUSTOM_ALLOWANCE,
  GIPHY_KEY,
  APP_URL,
  ADMIN_APP_URL,
  NEW_APP_URL,
  LANDER_URL,
  HELP_URL,
  TERMS_URL,
  PRIVACY_URL,
  GET_PLATFORM,
  SOURCE,
  SPLIT_AUTH_KEY,
  MIXPANEL_KEY,
  MS_TEAMS,
  ADP_MARKETPLACE_URL,
  PLAN_IDS,
  IOS_URL,
  DATA_DOG,
  GO_TO_AXOMO_LINK,
  AXOMO_URL,
  IP_API_KEY,
  AMAZON_BUSINESS_APPLICATION_ID,
};
