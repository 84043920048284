export const mapHexCodeToEmoticon = (value: string) => {
  if (!value) {
    throw new Error('invalid arguments');
  }

  if (value.indexOf('-') > 0) {
    return String.fromCodePoint(
      ...value.split('-').map((item) => +`0x${item}`),
    );
  }

  return String.fromCodePoint(+`0x${value}`);
};
