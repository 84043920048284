export const MEMBER_GROUPS = {
  ALL: 'all-members',
  FULL: 'only-full-slack',
  SPECIFIC: 'specific-members',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SLACK_ONBOARDING_STEPS {
  MEMBERS_ONBOARDED = 'MEMBERS_ONBOARDED',
  SET_CUSTOM_CURRENCY = 'SET_CUSTOM_CURRENCY',
  WELCOME_MESSAGE_SENT = 'WELCOME_MESSAGE_SENT',
}
