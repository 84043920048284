import { ReduxActionTypes } from './stateManagementConstants';

const apiCallReducers = (state = {}, { type, payload }) => {
  switch (type) {
    case ReduxActionTypes.API_CALL_BEGIN: {
      const { reduxKey, requestParams } = payload;
      return {
        ...state,
        [reduxKey]: {
          ...state[reduxKey],
          error: undefined,
          loading: true,
          requestParams,
        },
      };
    }
    case ReduxActionTypes.API_CALL_SUCCESS: {
      const { reduxKey, response } = payload;
      return {
        ...state,
        [reduxKey]: {
          ...state[reduxKey],
          error: undefined,
          loading: false,
          response,
        },
      };
    }
    case ReduxActionTypes.API_CALL_ERROR: {
      const { reduxKey, error } = payload;
      return {
        ...state,
        [reduxKey]: { ...state[reduxKey], error, loading: false },
      };
    }
    default:
      return state;
  }
};

export default apiCallReducers;
