import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { employerSelector } from '../../modules/admin/selector';
import { meSelector } from '../../modules/me/selector';
import LoadingPulse from '../../screens/shared/LoadingPulse';

interface AuthenticationProps {
  history: any;
  location: any;
  me: any;
  employer: any;
  authenticated: any;
}

const auth = (ComposedComponent) => {
  class Authentication extends Component<AuthenticationProps> {
    PropTypes = {
      router: PropTypes.object,
    };

    componentDidMount() {
      if (!this.props.authenticated) {
        const { history, location } = this.props;
        let fullPath = location.pathname;
        if (location.search) {
          fullPath = `${location.pathname}${location.search}`;
        }
        history.push({
          pathname: '/signin',
          state: { origin: fullPath },
          search: '',
        });
      } else {
        // const { me } = this.props;
        // if (me) {
        //   if (!me.isOnboardingComplete) {
        //     this.props.dispatch(uiActions.showIncompleteOnboarding());
        //   }
        // }
      }
    }

    componentDidUpdate() {
      if (!this.props.authenticated) {
        const { location } = this.props;
        let fullPath = location.pathname;
        if (location.search) {
          fullPath = `${location.pathname}${location.search}`;
        }
        this.props.history.push({
          pathname: '/signin',
          state: { origin: fullPath },
          search: '',
        });
      }
    }

    render() {
      const { authenticated, me, employer } = this.props;
      if (authenticated) {
        if (me && employer) {
          return <ComposedComponent {...this.props} />;
        }
        return (
          <div style={{ paddingTop: 50 }}>
            <LoadingPulse isLoading />
          </div>
        );
      }

      return <div />;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.auth.authenticated,
      me: meSelector(state),
      employer: employerSelector(state),
    };
  }

  return connect(mapStateToProps)(Authentication);
};

export default auth;
