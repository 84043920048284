import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import axios from 'axios';
import { AuthHeadersV2 } from '../../modules/shared/HeaderToken';
import {
  apiCallAction,
  apiCallSuccessAction,
  apiCallErrorAction,
  getApiCallResponse,
  getApiCallError,
  apiCallClearAction,
} from './redux';
import endpoints from './endpoints';
import {
  ApiResponseError,
  ApiResponseSuccess,
} from '../../interfaces/identityManager/common';

type FetchProps = {
  apiEndpoint: string;
  onSuccess?: (response: ApiResponseSuccess) => void;
  onFailure?: (error: ApiResponseError) => void;
  useCache?: boolean;
};

/**
 * useFetch Hook is an abstraction to call api's from our
 * React frontend. Each API is configured in endpoints file
 * To configure an API, set an apiEndpoint as a key and have its
 * endpoint and method as value in the endpoints file.  When needed
 * to do an API call, call makeRequest of this hook with the apiEndpoint
 * and you will get the response, error objects and makeRequest
 * method. Call the makeRequest method at the appropriate place
 * to trigger the call. Loading state can be determined using
 * the response object on onSuccess callback of this hook. This uses
 * Redux to save the state of the API call, so that it can be shared
 * across components.
 *  */
const useFetch = ({
  apiEndpoint,
  onSuccess = () => {},
  onFailure = () => {},
  useCache = false,
}: FetchProps) => {
  let reduxKeyRefStr = apiEndpoint;
  if (!useCache) {
    reduxKeyRefStr = `${apiEndpoint}_${new Date().getTime()}`;
  }
  const reduxKeyRef = useRef(reduxKeyRefStr);
  const reduxKey = reduxKeyRef.current;
  const [isMakeRequestCalled, setIsMakeRequestCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { response, error } = useSelector((state) => ({
    response: getApiCallResponse(state, reduxKey),
    error: getApiCallError(state, reduxKey),
  }));
  const dispatch = useDispatch();
  // Calls onSuccess when response not null and there is change in its reference
  useEffect(() => {
    if (response && isMakeRequestCalled) {
      onSuccess(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isMakeRequestCalled]);
  // Calls onFailure when error not null and there is change in its reference
  useEffect(() => {
    if (error && isMakeRequestCalled) {
      const errorStatus = get(error, ['response', 'status']);
      let errorMessage;
      const defaultMessage = 'SERVER_ERROR';
      switch (errorStatus) {
        case 500:
        case 409:
        case 400:
        case 422:
          errorMessage = get(
            error,
            ['response', 'data', 'message'],
            defaultMessage,
          );
          break;

        default:
          errorMessage = defaultMessage;
          break;
      }
      onFailure({ status: errorStatus, message: errorMessage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isMakeRequestCalled]);
  // Cleans up api request in store which are not cached
  useEffect(() => {
    if (!useCache) {
      return () => {
        dispatch(apiCallClearAction({ reduxKey }));
      };
    }
    return undefined;
  }, [dispatch, reduxKey, useCache]);

  const makeRequest = useCallback(
    async ({ params = {}, data = {}, substitutions = {} } = {}) => {
      setIsMakeRequestCalled(true);
      setIsLoading(true);
      const api = endpoints[apiEndpoint];
      const { url, method } = api;
      let substitutedURL = url;
      Object.keys(substitutions).forEach((key) => {
        const substitution = substitutions[key];
        substitutedURL = substitutedURL.replace(`:${key}`, substitution);
      });
      const { headers } = AuthHeadersV2();
      const options = { method, headers, data, params };
      dispatch(
        apiCallAction({
          reduxKey,
          url: substitutedURL,
          ...options,
        }),
      );
      try {
        axios.defaults.withCredentials = true;
        // @ts-ignore  TODO: type issues need to be fixed here
        const res = await axios.request(substitutedURL, options);
        dispatch(apiCallSuccessAction({ reduxKey, response: res }));
        setIsLoading(false);
      } catch (e) {
        dispatch(apiCallErrorAction({ reduxKey, error: e }));
        setIsLoading(false);
      }
    },
    [dispatch, apiEndpoint, reduxKey],
  );
  return { isLoading, makeRequest };
};

export default useFetch;
