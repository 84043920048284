import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircleIndicator from '../../atoms/CircleIndicator';
import Body from '../../atoms/Body';

interface ValidationTextProps {
  checked: boolean;
  className?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  indicator: {
    position: 'absolute',
    top: '5px',
    left: '4px',
  },
  bodyText: {
    position: 'relative',
    paddingLeft: '24px',
  },
});

const ValidationText = (props: ValidationTextProps) => {
  const { className: parentClassName, checked, children } = props;
  const classes = useStyles();
  const className = `${classes.root} ${parentClassName}`;

  return (
    <div className={className}>
      <CircleIndicator checked={checked} className={classes.indicator} />
      <Body variant="body2" className={classes.bodyText}>
        {children}
      </Body>
    </div>
  );
};

export default ValidationText;
