import { AxiosError } from 'axios';
import { showErrorMessage } from '../../Utils/toast';

export default function processErrorResponse(response: any, renames?: any) {
  if (response.code === 100) {
    const error: Record<string, unknown> = {};
    const errorKeys = Object.keys(response.message);

    for (const key in errorKeys) {
      let assignKey = errorKeys[key];
      // If you want to rename the field
      // example - first_name => firstName
      if (renames && Object.keys(renames).includes(assignKey)) {
        assignKey = renames[assignKey];
      }
      error[assignKey] = response.message[errorKeys[key]].msg;
    }
    return error;
  } else if (response.code === 101) {
    return response.message.join(', ');
  } else if (response.code === 102) {
    return response.message;
  }
  return 'Server Error';
}

export const ErrorMessages = {
  networkError: 'Network Error.',
  serverError: 'Server down. Please try again later.',
  missingRequiredParametersError: 'Missing Required Parameters',
};

export const handleErrorCallback = (e: AxiosError) => {
  if (e.response?.status) {
    const { status } = e.response;
    if (status === 400 || status === 409) {
      showErrorMessage(ErrorMessages.missingRequiredParametersError);
    } else if (status === 412) {
      showErrorMessage(ErrorMessages.serverError);
    }
  } else {
    showErrorMessage('Error occurred, Please try again');
  }
};
