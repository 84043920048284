import _ from 'lodash';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { AssemblyCurrencyIcon } from '../../interfaces/AssemblyCurrencyIcon';
import { GET_APP_CURRENCY } from '../../Utils/constants';
import DEFAULT_CURRENCY from '../../Utils/superadmin/constants';

export const employerSelector = (state) =>
  _.get(state, ['admin', 'employer'], null);

export const regularAllowanceSelector = (state) =>
  _.get(state, ['admin', 'employer', 'regularAllowance'], 0);

export const getEmployerCompanyDetails = (state) =>
  _.get(state, ['admin', 'companyDetails'], null);

export const getEmployerCustomCurrencySelector = (state) => {
  const currency = {
    singular: get(
      state,
      ['admin', 'companyDetails', 'currency', 'currencyName'],
      null,
    ),
    plural: get(
      state,
      ['admin', 'companyDetails', 'currency', 'currencyNamePlural'],
      null,
    ),
  };
  return GET_APP_CURRENCY(currency);
};

export const getEmployerCustomIconSelector = (state) => {
  const customIcon = _.get(
    state,
    ['admin', 'companyDetails', 'currency', 'currencyIconURL'],
    null,
  );
  const emoji = _.get(
    state,
    ['admin', 'companyDetails', 'currency', 'currencyEmoji'],
    null,
  );
  if (customIcon && customIcon.length > 0) {
    return {
      type: 'icon',
      value: customIcon,
    };
  } else if (emoji) {
    return {
      type: 'emoji',
      value: emoji,
    };
  }
  return null;
};

const currencyNameSelector = (state) => {
  return _.get(
    state,
    ['admin', 'companyDetails', 'currency', 'currencyName'],
    DEFAULT_CURRENCY.CURRENCY_NAME,
  );
};

const customURLSelector = (state) => {
  return _.get(
    state,
    ['admin', 'companyDetails', 'currency', 'currencyIconURL'],
    null,
  );
};

const customEmojiSelector = (state) => {
  return _.get(
    state,
    ['admin', 'companyDetails', 'currency', 'currencyEmoji'],
    null,
  );
};

export const getEmployerAssemblyIconSelector = createSelector(
  customEmojiSelector,
  customURLSelector,
  currencyNameSelector,
  (emoji, customUrl, currencyName): AssemblyCurrencyIcon => {
    let hexCode;
    let url;
    let id = currencyName;
    if (emoji) {
      if (emoji.hexCode) {
        hexCode = emoji.hexCode;
      }
      if (emoji.url) {
        url = emoji.url;
      }
      if (emoji.id) {
        id = emoji.id;
      }
    }
    if (customUrl) {
      url = customUrl;
    }
    return { hexCode, url, id, currencyName };
  },
);

export const getEmployerCustomLogoSelector = (state) => {
  const logo = null;
  const logoRelativeURL = _.get(
    state,
    ['admin', 'employer', 'logo', 'original', 'relativeUrl'],
    null,
  );
  if (logoRelativeURL) {
    return {
      path: logoRelativeURL,
      name: _.get(
        state,
        ['admin', 'employer', 'logo', 'original', 'filename'],
        '',
      ),
    };
  }
  return logo;
};

export const getRewardsPaymentStatus = createSelector(
  employerSelector,
  (employer) => {
    if (!employer) {
      return false;
    }
    return _.get(employer, ['accounts', 'rewards', 'isChargeable'], false);
  },
);

export const getSlackIntegrationSelector = (state) =>
  _.get(state, ['admin', 'employer', 'integrations', 'slack_v2'], null);

export const isSlackIntegratedSelector = createSelector(
  getSlackIntegrationSelector,
  (slackInfo) => {
    if (!slackInfo) {
      return false;
    }
    return _.get(slackInfo, ['isAdded'], false);
  },
);

export const getSlackOnboardingStep = (state) => {
  let slackOnboardingStep = null;
  if (
    _.get(
      state,
      ['admin', 'employer', 'integrations', 'slack_v2', 'isAdded'],
      false,
    )
  ) {
    slackOnboardingStep = _.get(
      state,
      ['admin', 'employer', 'integrations', 'slack_v2', 'onboardingStep'],
      null,
    );
  }
  return slackOnboardingStep;
};

export const anniversayAllowanceSelector = (state) =>
  _.get(state, ['admin', 'employer', 'anniversaryAllowance'], null);
