import React from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import themeV2 from '../../theme';

const useStyles = makeStyles({
  label: {
    color: themeV2.palette.skyblue,
    '&:hover': {
      color: '#0092A3 !important',
    },
    '&:focus': {
      color: `${themeV2.palette.skyblue} !important`,
    },
    fontSize: themeV2.typography.fontSize,
    fontFamily: themeV2.typography.fontFamily,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textDecoration: 'underline',
  },
  adminLabel: {
    // fontSize: themeV2.typography.fontSize,
    fontFamily: themeV2.typography.adminFontFamily,
    fontWeight: themeV2.typography.fontWeightSemibold,
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textDecoration: 'none',
    color: themeV2.palette.skyblue,
    '&:hover': {
      color: '#0092A3 !important',
    },
    '&:focus': {
      color: `${themeV2.palette.skyblue} !important`,
    },
  },
});

interface CustomLinkProps {
  children?: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  to?: string;
  ref?: React.Ref<HTMLAnchorElement> | undefined;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
}

interface AdminLinkProps {
  className?: string;
  component?: React.ElementType;
  children: React.ReactNode;
  to?: string;
  ref?: React.Ref<HTMLAnchorElement> | undefined;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
}

interface AnchorLinkProps {
  children?: React.ReactNode;
  className?: string;
  useAdminStyle?: boolean;
  to?: string;
  ref?: React.Ref<HTMLAnchorElement> | undefined;
  target?: string;
}

export const AdminLink = (props: AdminLinkProps) => {
  const { children, className, component, ...rest } = props;
  const classes = useStyles();
  return (
    <Link
      component={component || RouterLink}
      {...rest}
      className={classNames(classes.adminLabel, className)}
    >
      {children}
    </Link>
  );
};

export const AnchorLink = (props: AnchorLinkProps) => {
  const { children, className, useAdminStyle, to, ...rest } = props;
  const classes = useStyles();

  const linkStyleName = useAdminStyle ? classes.adminLabel : classes.label;
  return (
    <a href={to} {...rest} className={classNames(linkStyleName, className)}>
      {children}
    </a>
  );
};

const CustomLink = (props: CustomLinkProps) => {
  const { children, className, component, to, ...rest } = props;
  const classes = useStyles();

  return (
    <Link
      to={to}
      component={component || RouterLink}
      {...rest}
      className={classNames(classes.label, className)}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
