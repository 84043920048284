import uniq from 'lodash/uniq';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';
import { isValidEmail } from './validation';

const PLACEHOLDER_EMAIL = [
  'tylersellers@joinassembly.com',
  'racheljones@joinassembly.com',
];

// https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;

const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

const download = (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const writeToXLS = (
  data: any[][],
  sheetName: string,
  fileName: string,
) => {
  const XLSX = window.XLSX;
  if (!XLSX) {
    return;
  }
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary',
  });
  const url = window.URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
  );
  download(url, fileName);
};

export const importEmails = (
  file: File,
  successCallback: { (emailsUploaded: any): void; (arg0: unknown[]): void },
) => {
  const fileType = file.name.slice(
    ((file.name.lastIndexOf('.') - 1) >>> 0) + 2,
  );
  let emails: string[] = [];

  switch (file.name.length > 0) {
    case fileType === 'csv':
      Papa.parse(file, {
        transform: (data) => {
          return data.trim();
        },
        complete: (results) => {
          const array = results.data.flat() as string[];
          emails = array.filter((email) => {
            if (PLACEHOLDER_EMAIL.includes(email)) {
              return false;
            }
            return isValidEmail(email);
          });
          emails = uniq(emails);
          successCallback(emails);
        },
      });
      break;
    case fileType === 'xlsx':
      readXlsxFile(file).then((mails) => {
        // TO DO: Sumedha - Check if this logic is valid
        const emailsUploaded = [...mails] as unknown as string[];
        emails = emailsUploaded.filter((email) => {
          if (PLACEHOLDER_EMAIL.includes(email)) {
            return false;
          }
          return isValidEmail(email);
        });
        emails = uniq(emails);
        successCallback(emails);
      });
      break;
    default:
      break;
  }
  return emails;
};
