export const V2_HOME = `/home`;

export const SIGN_IN = `/signin`;
export const USER_ACCOUNT_SETUP = '/user/account-setup';
export const ONBOARDING_SETUP = '/onboarding-setup';

export const FLOWS = '/flows';
export const ANSWER_FLOW = '/answer';

export const SIGN_IN_PATH = '/signin';
export const KIOSK_PAGE_PATH = '/kiosk';

export const AUTH_SAML = '/auth/saml';

export const GOOGLE_ONE_TAP_AUTH = '/auth/gOneTap';
