import { flashMessage, flashErrorMessage } from 'redux-flash';

const showSuccessMessage =
  (message: string) => (dispatch: (arg: any) => void) => {
    dispatch(flashMessage(message, { props: { type: 'success' } }));
  };

const showErrorMessage =
  (message: string | JSX.Element) => (dispatch: (arg: any) => void) => {
    dispatch(flashErrorMessage(message));
  };

const showInfoMessage = (message: string) => (dispatch: (arg: any) => void) => {
  dispatch(flashMessage(message, { props: { type: 'info' } }));
};

export { showSuccessMessage, showErrorMessage, showInfoMessage };
