import axios from 'axios';
import { API_URL_WITH_V2 } from '../../../config';
import { AuthHeaders } from '../../shared/HeaderToken';
import { adminProcessError, adminActions } from '../../Admin';
import {
  showSuccessMessage,
  showInfoMessage,
} from '../../../Utils/flashHandler';

const apiEndpoints = {
  BIRTHDAY: `${API_URL_WITH_V2}/employer/rewards/birthday`,
  BIRTHDAY_DEACTIVATE: `${API_URL_WITH_V2}/employer/rewards/birthday/deactivate`,
  ANNIVERSARY: `${API_URL_WITH_V2}/employer/rewards/anniversary`,
  ANNIVERSARY_DEACTIVATE: `${API_URL_WITH_V2}/employer/rewards/anniversary/deactivate`,
} as const;

export const ACTIVATION_STATUS = {
  BLANK: 'blank',
  ACTIVATING: 'activating',
};

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_BIRTHDAY_SUCCESS = 'FETCH_BIRTHDAY_SUCCESS';
export const ACTIVATE_BIRTHDAY_SUCCESS = 'ACTIVATE_BIRTHDAY_SUCCESS';
export const ACTIVATE_ANNIVERSARY_SUCCESS = 'ACTIVATE_ANNIVERSARY_SUCCESS';
export const ANNIVERSARY_REQUEST_FAILED = 'ANNIVERSARY_REQUEST_FAILED';
export const ANNIVERSARY_ACTIVIATION_STATUS = 'ANNIVERSARY_ACTIVIATION_STATUS';

// ------------------------------------
// Actions
// ------------------------------------
const fetchBirthdaySuccess = (birthdayAllowance) => ({
  type: FETCH_BIRTHDAY_SUCCESS,
  birthdayAllowance,
});

const activateBithdaySuccess = () => ({
  type: ACTIVATE_BIRTHDAY_SUCCESS,
});

const activateAnniversarySuccess = (reward) => ({
  type: ACTIVATE_ANNIVERSARY_SUCCESS,
  reward,
});

const updateActivationStatus = (status) => ({
  type: ANNIVERSARY_ACTIVIATION_STATUS,
  status,
});

const requestFailed = (errorMessage) => ({
  type: ANNIVERSARY_REQUEST_FAILED,
  error: errorMessage,
});

export const anniversaryActions = {
  fetchBirthdaySuccess,
  activateBithdaySuccess,
  activateAnniversarySuccess,
  updateActivationStatus,
  requestFailed,
};

// ------------------------------------
// API Wrapper
// ------------------------------------
export const fetchBirthday = () => async (dispatch) => {
  try {
    const res = await axios.get(apiEndpoints.BIRTHDAY, AuthHeaders());
    const result = res.data;
    if (result.success) {
      const { employer } = result.data;
      dispatch(
        anniversaryActions.fetchBirthdaySuccess(
          employer.birthdayAllowance || null,
        ),
      );
    }
  } catch (error) {
    adminProcessError(error, dispatch, anniversaryActions);
  }
};

export const activateBirthday =
  (props, isUpdate = false) =>
  async (dispatch) => {
    try {
      const message = isUpdate
        ? 'Updating changes...'
        : 'Activating birthday allowance...';
      dispatch(showInfoMessage(message));
      dispatch(
        anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.ACTIVATING),
      );
      const res = await axios.post(apiEndpoints.BIRTHDAY, props, AuthHeaders());
      const result = res.data;
      if (result.success) {
        const { employer } = result.data;

        dispatch(adminActions.gotMyEmployer(employer));
        // dispatch(anniversaryActions.fetchBirthdaySuccess(employer.birthdayAllowance || null));
        // dispatch(fetchBirthday());
        const successMessage = isUpdate
          ? 'Changes saved'
          : 'Birthday allowance activated.';
        dispatch(showSuccessMessage(successMessage));
      }
      dispatch(
        anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
      );
    } catch (error) {
      adminProcessError(error, dispatch, anniversaryActions);
      dispatch(
        anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
      );
    }
  };

export const deactivateBirthday = () => async (dispatch) => {
  try {
    dispatch(showInfoMessage('Deactivating birthday allowance...'));
    dispatch(
      anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.ACTIVATING),
    );
    const res = await axios.post(
      apiEndpoints.BIRTHDAY_DEACTIVATE,
      {},
      AuthHeaders(),
    );
    const result = res.data;
    dispatch(
      anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
    );
    if (result.success) {
      const { employer } = result.data;
      dispatch(adminActions.gotMyEmployer(employer));
      dispatch(
        anniversaryActions.fetchBirthdaySuccess(
          employer.birthdayAllowance || null,
        ),
      );
      dispatch(showSuccessMessage('Birthday allowance has been deactivated.'));
    }
  } catch (error) {
    adminProcessError(error, dispatch, anniversaryActions);
    dispatch(
      anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
    );
  }
};

export const activateAnniversary =
  (props, isUpdate = false) =>
  async (dispatch) => {
    try {
      const message = isUpdate
        ? 'Updating changes...'
        : 'Activating anniversary allowance...';
      dispatch(showInfoMessage(message));
      dispatch(
        anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.ACTIVATING),
      );

      const body = props;

      const res = await axios.post(
        apiEndpoints.ANNIVERSARY,
        body,
        AuthHeaders(),
      );

      const result = res.data;
      if (result.success) {
        const { employer } = result.data;
        dispatch(adminActions.gotMyEmployer(employer));
        const successMessage = isUpdate
          ? 'Changes saved'
          : 'Anniversary allowance activated.';
        dispatch(showSuccessMessage(successMessage));
      }
      dispatch(
        anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
      );
    } catch (error) {
      adminProcessError(error, dispatch, anniversaryActions);
      dispatch(
        anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
      );
    }
  };

export const deactivateAnniversary = () => async (dispatch) => {
  try {
    dispatch(showInfoMessage('Deactivating anniversary allowance...'));
    dispatch(
      anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.ACTIVATING),
    );
    const res = await axios.post(
      apiEndpoints.ANNIVERSARY_DEACTIVATE,
      {},
      AuthHeaders(),
    );
    const result = res.data;
    dispatch(
      anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
    );
    if (result.success) {
      const { employer } = result.data;
      dispatch(adminActions.gotMyEmployer(employer));
      dispatch(
        showSuccessMessage('Anniversary allowance has been deactivated.'),
      );
    }
  } catch (error) {
    adminProcessError(error, dispatch, anniversaryActions);
    dispatch(
      anniversaryActions.updateActivationStatus(ACTIVATION_STATUS.BLANK),
    );
  }
};

// ------------------------------------
// Reducers
// ------------------------------------

export default (
  state = { activationStatus: ACTIVATION_STATUS.BLANK },
  action,
) => {
  switch (action.type) {
    case FETCH_BIRTHDAY_SUCCESS:
      return { ...state, birthday: action.birthdayAllowance };
    case ACTIVATE_BIRTHDAY_SUCCESS:
      return { ...state };
    case ANNIVERSARY_ACTIVIATION_STATUS:
      return { ...state, activationStatus: action.status };
    default:
      return state;
  }
};
