import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Clickable from '../../atoms/Clickable';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../componentsV2/theme';

const useStyles = makeStyles({
  root: ({
    color: { backgroundColor, text },
    isActive,
  }: {
    color: { backgroundColor: string; text: string };
    isActive?: boolean;
  }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    padding: '4px 16px',
    borderRadius: '2px',
    transition: '0.2s ease all',
    '& div:first-of-type': {
      color: isActive ? text : ThemeV2.palette.gray6,
    },
    '& svg > path:last-of-type': {
      fill: isActive ? text : ThemeV2.palette.gray6,
    },
    '&:hover': {
      background: backgroundColor,
      '& div:first-of-type': {
        color: text,
      },
      '& svg > path:last-of-type': {
        fill: text,
      },
    },
  }),
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleText: {
    marginRight: '12px',
  },
});

interface ChartToggleProps {
  id: string;
  text: string;
  color: { backgroundColor: string; text: string };
  onClick: (toggleId: string) => void;
  isActive?: boolean;
  className?: string;
}

const ChartToggle = (props: ChartToggleProps) => {
  const { text, id, isActive, onClick, className: parentClassName } = props;
  const [mouseEntered, setMouseEntered] = useState(false);
  const toggleIcon = useMemo(() => {
    if (isActive) {
      return mouseEntered ? 'minus-rounded' : 'check-rounded';
    }
    return 'union-rounded';
  }, [isActive, mouseEntered]);
  const classes = useStyles(props);
  const styles = `${classes.root} ${
    !isActive && 'toggleOff'
  } ${parentClassName}`;

  const handleMouseEnter = useCallback(() => {
    setMouseEntered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setMouseEntered(false);
  }, []);

  const handleOnClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Clickable
      classes={{ root: styles }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      <Body variant="body2Medium" className={classes.toggleText}>
        {text}
      </Body>
      <div className={classes.iconWrapper}>
        <SVGIcon icon={toggleIcon} size="16px" />
      </div>
    </Clickable>
  );
};

export default ChartToggle;
