import React from 'react';
import classnames from 'classnames';
import themeV2 from '../../theme';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
    height: 1,
    width: '100%',
    fontSize: 18,
    fontFamily: themeV2.typography.adminFontFamily,
    fontWeight: themeV2.typography.fontWeightSemibold,
    '& span': {
      position: 'absolute',
      left: 'auto',
      right: 'auto',
      top: -13,
      background: '#fff',
      padding: '0 20px',
      fontSize: 'inherit',
      display: 'block',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
    },
    '&.small': {
      fontSize: '12px',
      fontWeight: 'normal',
      '& span': {
        top: -8,
        padding: '0 8px',
      },
    },
  },
});

const TextDivider = (props: {
  color?: string;
  text: string;
  small?: boolean;
}) => {
  const { text, small, color } = props;
  const renderRootColor = () => {
    if (color) {
      return { background: color };
    } else {
      return {
        background: themeV2.palette.lightGray1,
      };
    }
  };

  const renderTextColor = () => {
    if (color) {
      return { color };
    } else {
      return {
        color: themeV2.palette.lightGray1,
      };
    }
  };
  const classes = useStyles();
  return (
    <div
      className={classnames(classes.root, small && 'small')}
      style={renderRootColor()}
    >
      <span style={renderTextColor()}>{text}</span>
    </div>
  );
};

export default TextDivider;
