import React from 'react';
import Navbar from '../../componentsV2/core/Navbar';
import makeStyles from '@material-ui/styles/makeStyles';

const MARGIN_TOP = 90;
const useStyles = makeStyles({
  root: {
    backgroundColor: '#f6f6f6',
    paddingTop: MARGIN_TOP,
    height: 'calc(100vh)',
  },
});

type OnboardingLayoutProps = {
  children?: React.ReactNode;
  slackOnboardStep?: boolean | string;
};

const OnboardingLayout = (props: OnboardingLayoutProps) => {
  const { children, slackOnboardStep } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar slackOnboardStep={slackOnboardStep} />
      {children}
    </div>
  );
};

export default OnboardingLayout;
