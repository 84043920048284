import titleize from 'titleize';

export const ANALYTICS_EVENTS = {
  ACCOUNT_SIGN_UP: 'Account Setup - Sign Up',
  ACCOUNT_VERIFICATION: 'Account Setup - Verification',
  ACCOUNT_SETUP_COMPANY_NAME: 'Account Setup - Company Name',
  ACCOUNT_SETUP_PROFILE_NAME: 'Account Setup - Profile Name',
  ACCOUNT_SETUP_QUICK_INVITES: 'Account Setup - Quick Invites',
  ACCOUNT_SETUP_COMPLETE: 'Account Setup - Complete',

  PAGE_VIEW: 'page_view',
  CREATE_POST_START: 'feed.post.recognition.compose',
  CREATE_POST_GIVEN: 'feed.post.recognition.given',
  CREATE_POST_GIVE_ERROR: 'feed.post.recognition.give.error',
  RECOGNITION_PARTICIPATION_START: 'recognition.participation.start',
  RECOGNITION_PARTICIPATION_EXIT: 'recognition.participation.exit',
  RECOGNITION_PARTICIPATION_POST: 'recognition.participation.post',
  RECOGNITION_PARTICIPATION_POST_ERROR: 'recognition.participation.post.error',

  SAML_CONFIG_ENABLE_START: 'saml.enable.start',
  SAML_CONFIG_SAVE_TEST_ERROR: 'saml.save.test.error',
  SAML_CONFIG_SAVE_TEST_SUCCESS: 'saml.save.test.success',
};

export const PAGE_NAMES = {
  HOME: 'Home',
  REDEEM: 'Redeem',
  SETTINGS: 'Settings',
  SIGN_OUT: 'Sign out',
};

export const ADMIN_PAGE_NAME = (path: string) => {
  return path
    .substring(1)
    .split('/')
    .map((sub) => titleize(sub))
    .join(' -> ');
};

export const INVITE_MEMBER_EVENTS = {
  INVITE_START: 'inviteStart',
  COPY_INVITE_LINK: 'copyInviteLink',
  INVITE_COMPLETE: 'inviteComplete',
};

export const MANAGE_USERS_EVENTS = {
  INVITE_START: 'inviteStart',
  COPY_INVITE_LINK: 'copyInviteLink',
  INVITE_COMPLETE: 'inviteComplete',
};

export const ACTION_EVENTS = {
  SHOW: 'show',
  ERROR: 'error',
  ACTION: 'action',
};

export const AXOMO_EVENTS = {
  AXOMO_CONNECTED: 'axomoConnected',
  AXOMO_DISCONNECTED: 'axomoDisconnected',
  SWAG_EDITED: 'swagEdited',
  SWAG_TAB_EDITED: 'swagTabEdited',
  SWAG_VIEWED: 'swagViewed',
  SWAG_ORDERED: 'swagOrdered',
  CONNECTION_ERROR: 'connectionError',
  TURN_SWAG_ITEM_ON_ERROR: 'turnSwagItemOnError',
  TURN_SWAG_ITEM_OFF_ERROR: 'turnSwagItemOffError',
  SHOW_SWAG: 'showSwag',
};

export const ACTIVITY_REPORT_EVENTS = {
  ACTIVITY_REPORT_SHOW: 'ActivityReportShow',
  FILTER_APPLIED: 'filterApplied',
  REPORT_DOWNLOADED: 'reportDownloaded',
  COLUMN_SORTED: 'columnSorted',
};

export enum AnalyticsEvents {
  SHOW = 'show',
  ACTION = 'action',
  ERROR = 'error',
}

export const INVITE_MEMBER_EVENT = 'invite.events';
export const AXOMO_EVENT = 'axomo.events';
export const ADMIN_EVENT = 'admin.events';
