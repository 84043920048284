import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isWindows = () => {
  if (window.navigator.platform && window.navigator.platform.includes('Win')) {
    return true;
  }
  return false;
};

export const isMobile = browser.getPlatformType() === 'mobile';
