export const ADMIN_ROLES = ['Owner', 'Admin'];

export const checkIfAdmin = (user) => {
  const { role } = user;
  const hasRole = role.find((rol) => {
    return ADMIN_ROLES.includes(rol);
  });

  return !!hasRole;
};

export const checkIfOwner = (user) => {
  const { role } = user;
  return role.includes('Owner');
};
