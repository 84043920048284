import { REWARD_TYPES } from '../../Utils/constants';
import { GIFT_CARD_TYPES } from '../../Utils/admin/rewards';
import { APP_URL } from '../../config';

export const getCountryAndCurrenyCodes = (giftCards) => {
  const countries: any[] = [];
  const currencyCodes: any[] = [];
  giftCards.forEach((giftCard) => {
    if (giftCard.items.length > 0) {
      giftCard.items[0].countries.forEach((country) => {
        if (countries.indexOf(country) === -1 && country !== 'UK') {
          countries.push(country);
        }
      });
      if (currencyCodes.indexOf(giftCard.items[0].currencyCode) === -1) {
        currencyCodes.push(giftCard.items[0].currencyCode);
      }
    }
  });
  return { countries, currencyCodes };
};

const filterFixedValueDenominations = (denominations, card) => {
  return denominations.filter((denom) => {
    let hasDenom = false;
    card.items.forEach((item) => {
      if (item.faceValue === denom.faceValue && item.utid === denom.utid) {
        hasDenom = true;
      }
    });
    return hasDenom;
  });
};

const filterVariableValueDenominations = (denominations, card) => {
  return denominations.filter((denom) => {
    const item = card.items[0];
    let hasDenom = false;
    if (denom.faceValue >= item.minValue && denom.faceValue <= item.maxValue) {
      hasDenom = true;
    }
    return hasDenom;
  });
};

export const goToMainAppHome = () => {
  window.location.href = `${APP_URL}/home`;
};

export const getGiftCardsAndDonations = (cards) => {
  const giftCards: any[] = [];
  const donations: any[] = [];
  cards.forEach((cardVal) => {
    const card = { ...cardVal };
    if (card.items.length > 0) {
      const { rewardType, valueType } = card.items[0];
      if (valueType === GIFT_CARD_TYPES.FIXED) {
        card.denominations = filterFixedValueDenominations(
          card.denominations,
          card,
        );
      } else if (valueType === GIFT_CARD_TYPES.VARIABLE) {
        card.denominations = filterVariableValueDenominations(
          card.denominations,
          card,
        );
      }

      if (card.denominations.length > 0) {
        if (
          rewardType === REWARD_TYPES.GIFT_CARD ||
          rewardType === REWARD_TYPES.CASH_EQUIVALENT
        ) {
          giftCards.push(card);
        } else if (rewardType === REWARD_TYPES.DONATION) {
          donations.push(card);
        }
      }
    }
  });
  return { giftCards, donations };
};

export const filterDenomsAgainstCardItems = (cards, employerRewards) => {
  const cardsDict = {};
  cards.forEach((card) => {
    if (!cardsDict[card.brandKey]) {
      cardsDict[card.brandKey] = card;
    }
  });
  return employerRewards.map((employerReward) => {
    const card = cardsDict[employerReward.brandKey];
    if (card) {
      const { valueType } = card.items[0];
      let { denominations } = employerReward;
      if (valueType === GIFT_CARD_TYPES.FIXED) {
        denominations = filterFixedValueDenominations(denominations, card);
      } else if (valueType === GIFT_CARD_TYPES.VARIABLE) {
        denominations = filterVariableValueDenominations(denominations, card);
      }
      return { ...employerReward, denominations };
    }
    return employerReward;
  });
};
