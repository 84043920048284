import _ from 'lodash';

export const ReduxActionTypes = {
  API_CALL_BEGIN: '@api/API_CALL_BEGIN',
  API_CALL_SUCCESS: '@api/API_CALL_SUCCESS',
  API_CALL_ERROR: '@api/API_CALL_ERROR',
  API_CALL_CLEAR: '@api/API_CALL_CLEAR',
};

export const apiCallAction = ({
  reduxKey,
  url,
  method,
  params,
  data,
  headers,
}) => ({
  type: ReduxActionTypes.API_CALL_BEGIN,
  payload: {
    reduxKey,
    requestParams: {
      url,
      method,
      params,
      data,
      headers,
    },
  },
});

export const apiCallSuccessAction = ({ reduxKey, response }) => ({
  type: ReduxActionTypes.API_CALL_SUCCESS,
  payload: {
    reduxKey,
    response,
  },
});

export const apiCallErrorAction = ({ reduxKey, error }) => ({
  type: ReduxActionTypes.API_CALL_ERROR,
  payload: {
    reduxKey,
    error,
  },
});

export const apiCallClearAction = ({ reduxKey }) => ({
  type: ReduxActionTypes.API_CALL_CLEAR,
  payload: {
    reduxKey,
  },
});

const apiCallReducers = (state = {}, { type, payload }) => {
  switch (type) {
    case ReduxActionTypes.API_CALL_BEGIN: {
      const { reduxKey, requestParams } = payload;
      const newApi = {
        requestParams,
      };
      if (state[reduxKey]) {
        const { response, error } = state[reduxKey];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        newApi.response = response;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        newApi.error = error;
      }
      return { ...state, [reduxKey]: newApi };
    }
    case ReduxActionTypes.API_CALL_SUCCESS: {
      const { reduxKey, response } = payload;
      const api = state[reduxKey];
      // Check if key present, it might have got removed
      // due to API_CALL_CLEAR action
      if (api) {
        const { requestParams } = api;
        const newApi = {
          requestParams,
          response,
        };
        return { ...state, [reduxKey]: newApi };
      }
      return state;
    }
    case ReduxActionTypes.API_CALL_ERROR: {
      const { reduxKey, error } = payload;
      const api = state[reduxKey];
      // Check if key present, it might have got removed
      // due to API_CALL_CLEAR action
      if (api) {
        const { requestParams } = api;
        const newApi = {
          requestParams,
          error,
        };
        return { ...state, [reduxKey]: newApi };
      }
      return state;
    }
    case ReduxActionTypes.API_CALL_CLEAR: {
      const { reduxKey } = payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const { [reduxKey]: blah, ...rest } = state;
      return { ...rest };
    }
    default:
      return state;
  }
};

export const getApiCallResponse = (state, reduxKey) =>
  _.get(state, ['api', reduxKey, 'response'], null);
export const getApiCallError = (state, reduxKey) =>
  _.get(state, ['api', reduxKey, 'error'], null);

export default apiCallReducers;
