import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import titleize from 'titleize';

const MENTION_REGEX = /@__(.*?)__@/;
const DOMAIN_REGEX =
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
export const NUMBERS_REGEX = /^[0-9\b]+$/;

export const getProfileFullName = (user) => {
  if (user && user.profile) {
    return `${user.profile.firstName} ${user.profile.lastName}`;
  }
  return '';
};

export const isValidDomain = (value) => {
  if (value.match(DOMAIN_REGEX)) {
    return true;
  }
  return false;
};

export const capitalizeFirstLetter = (name) => {
  if (!name) {
    return '';
  }
  return titleize(name);
};

export const getTaggedUsersFromMessage = (
  message,
  taggedUsers,
  isNotification = false,
) => {
  let replacedText = message;
  if (taggedUsers?.length > 0) {
    replacedText = reactStringReplace(replacedText, MENTION_REGEX, (match) => {
      const filteredEmployeeArr = taggedUsers
        .filter((employee) => employee._id === match)
        .map((employee) => {
          if (isNotification) {
            return employee.isDeleted
              ? 'Deactivated User'
              : getProfileFullName(employee);
          }
          return getProfileFullName(employee);
        });
      return filteredEmployeeArr;
    });
  }
  if (typeof replacedText === 'object') {
    return replacedText.join('');
  }
  return replacedText;
};

export const getFirstLetterOfName = (user) => {
  if (user && user.profile && user.profile.firstName) {
    return `${user.profile.firstName.slice(0, 1).toUpperCase()}`;
  }
  return '';
};

export const getFirstLastName = (fullNameStr) => {
  const fullName = fullNameStr.trim().split(' ');
  let firstName = '';
  let lastName = '';
  for (const index in fullName) {
    if (Number(index) === 0) {
      firstName = fullName[index];
    } else {
      lastName = `${lastName} ${fullName[index]}`;
    }
  }
  lastName = lastName.trim();

  return [firstName, lastName];
};

export const getFullName = (user) => {
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
};

export const replaceAll = function (text, search, replacement) {
  return text.replace(new RegExp(search, 'g'), replacement);
};

export const sortUsingName = (users) =>
  users.sort((a, b) => {
    let aName = '';
    let bName = '';
    if (a.profile) {
      aName = a.profile.firstName.toLowerCase();
    } else if (a.firstName) {
      aName = a.firstName;
    }
    if (b.profile) {
      bName = b.profile.firstName.toLowerCase();
    } else if (b.firstName) {
      bName = b.firstName;
    }
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  });

export function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString
  }...`;
}

export const convertUsersForSearch = (arr) => {
  const keyMap = {
    name: 'displayText',
    username: 'secondaryText',
  };
  return arr.map((obj) => {
    return _.mapKeys(obj, (value, key) => {
      return keyMap[key];
    });
  });
};

export const getCoworkersOptions = (coworkers) =>
  coworkers.map((coworker) => {
    const { _id: id, profile, email } = coworker;
    const { firstName, lastName } = profile;
    return {
      id,
      label: `${firstName} ${lastName} (${email})`,
      tagLabel: `${firstName} ${lastName}`,
    };
  });

export const getIds = (array) => array.map(({ id }) => id);

export const getCoworkersFromIds = (ids, coworkers) => {
  if (ids.length === 0 || coworkers.length === 0) {
    return [];
  }
  return coworkers.reduce((acc, coworker) => {
    const { _id: id } = coworker;
    if (ids.indexOf(id) !== -1) {
      acc.push(coworker);
    }
    return acc;
  }, []);
};

export const filterExceptCurrentUser = (array, currentUserId) =>
  array.filter(({ id }) => id !== currentUserId);
