import React from 'react';
import { number, node, func, shape, string } from 'prop-types';
import useImageUploader from './useImageUploader';
import DisplayImageUploader from './index.presentational';
import 'react-image-crop/dist/ReactCrop.css';
import { ClassNameMap } from '@material-ui/styles';

type ImageUploaderProps = {
  aspectRatio: number;
  classes: ClassNameMap;
  children: React.ReactChild;
  limits: {
    MIN_DIMENSIONS?: { WIDTH: number; HEIGHT: number };
    MAX_DIMENSIONS?: { WIDTH: number; HEIGHT: number };
    FILE_SIZE_LIMIT: number;
  };
  onError: (message: string) => void;
  onImageEditingDone: (src: string, fileName: string) => void;
  overlayIcon: string;
};

const ImageUploader = (props: ImageUploaderProps) => {
  const {
    aspectRatio,
    classes,
    children,
    limits,
    onError,
    onImageEditingDone,
    overlayIcon,
  } = props;
  const returnedHookValues = useImageUploader({
    aspectRatio,
    maxWidth: limits.MAX_DIMENSIONS?.WIDTH,
    maxHeight: limits.MAX_DIMENSIONS?.HEIGHT,
    minWidth: limits.MIN_DIMENSIONS?.WIDTH,
    minHeight: limits.MIN_DIMENSIONS?.HEIGHT,
    fileSizeLimit: limits.FILE_SIZE_LIMIT,
    onError,
    onImageEditingDone,
  });

  return (
    <DisplayImageUploader
      classes={classes}
      overlayIcon={overlayIcon}
      {...returnedHookValues}
    >
      {children}
    </DisplayImageUploader>
  );
};

ImageUploader.propTypes = {
  classes: shape({}),
  children: node.isRequired,
  onImageEditingDone: func.isRequired,
  aspectRatio: number,
  limits: shape({
    MIN_DIMENSIONS: shape({ WIDTH: number, HEIGHT: number }),
    MAX_DIMENSIONS: shape({ WIDTH: number, HEIGHT: number }),
    FILE_SIZE_LIMIT: number,
  }),
  onError: func,
  overlayIcon: string,
};

ImageUploader.defaultProps = {
  classes: {},
  aspectRatio: null,
  onError: null,
  limits: {
    MIN_DIMENSIONS: {},
    MAX_DIMENSIONS: {},
    FILE_SIZE_LIMIT: Infinity,
  },
  overlayIcon: '',
};

export default ImageUploader;
