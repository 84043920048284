import { Component } from 'react';

const Authorization = (allowedRoles) => (WrappedComponent) =>
  class WithAuthorization extends Component {
    constructor(props) {
      super(props);
      const user = localStorage.getItem('user');
      if (user) {
        const me = JSON.parse(user);
        let hasRole = false;
        if (me) {
          const { role } = me;

          hasRole = role.find((rol) => {
            if (allowedRoles.includes(rol)) {
              return true;
            }
            return null;
          });
        }
        if (!hasRole) {
          props.history.push('/404');
        }
      }
    }

    render() {
      const user = localStorage.getItem('user');
      if (user) {
        const me = JSON.parse(user);
        let hasRole = false;
        if (me) {
          const { role } = me;
          hasRole = role.find((rol) => allowedRoles.includes(rol));
        }

        if (hasRole) {
          return <WrappedComponent {...this.props} />;
        }
      }
      return null;
    }
  };

export default Authorization;
