// TODO: Changes are required bases on Google API changes (2023)
//  Note by Vijay
//  https://github.com/anthonyjgrove/react-google-login/issues/473
//  https://developers.googleblog.com/2021/08/gsi-jsweb-deprecation.html

import React, { useCallback, useEffect, useState } from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from 'react-google-login';
import { Helmet } from 'react-helmet-async';

import {
  GET_SSO_OAUTH_CONFIG,
  GET_SSO_SIGNIN_CONFIG,
  GET_SSO_SIGNUP_CONFIG,
} from '../../constants/endpoints';
import useRequest from '../../hooks/useRequest/useRequest';
import {
  ApiResponseError,
  ApiResponseSuccess,
} from '../../interfaces/identityManager/common';
import { SSOOptions } from '../../Utils/data/ssoManager/common';
import { SSOErrorCodes } from '../../Utils/data/ssoManager/error';

export interface GSuiteSSOChildrenProps {
  signIn: () => void;
  loaded: boolean;
  initError?: string;
}

interface GSuiteSSOControllerProps {
  flow: GOOGLE_SSO_FLOW;
  children: React.FunctionComponent<GSuiteSSOChildrenProps>;
  onSuccess: (code: string) => void;
  onFailure: (error: ApiResponseError) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum GOOGLE_SSO_FLOW {
  AUTHORIZE = 'AUTHORIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
}

interface HookComponentProps {
  clientId: string;
  scope: string;
  onSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => void;
  onFailure: (error: ApiResponseError) => void;
  children: React.FunctionComponent<GSuiteSSOChildrenProps>;
}
const HookComponent = ({
  clientId,
  scope,
  onSuccess,
  onFailure,
  children,
}: HookComponentProps) => {
  const responseType = 'code';
  const [initError, setInitError] = useState<string | undefined>(undefined);
  const handleFailure = useCallback(
    (error) => {
      const { error: errorCode } = error;
      if (errorCode === SSOErrorCodes.COOKIES_NOT_ALLOWED) {
        setInitError(errorCode);
      } else {
        onFailure(error);
      }
    },
    [onFailure],
  );
  const { loaded } = useGoogleLogin({
    clientId,
    scope,
    responseType,
    onSuccess,
    onFailure: handleFailure,
  });
  // A Hack to required additional scopes, ideally to be done inside library
  // https://developers.google.com/identity/sign-in/web/incremental-auth
  // https://developers.google.com/identity/sign-in/web/reference#googleusergrantofflineaccessoptions
  const signIn = useCallback(() => {
    if (loaded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .grantOfflineAccess({
          scope,
        })
        .then(onSuccess, handleFailure);
    }
  }, [loaded, handleFailure, onSuccess, scope]);
  return React.createElement(children, {
    signIn,
    loaded,
    initError,
  });
};

const GSuiteSSOController = ({
  flow,
  children,
  onSuccess,
  onFailure,
}: GSuiteSSOControllerProps) => {
  const sso = SSOOptions.GOOGLE;
  const [clientId, setClientId] = useState<string>('');
  const [scope, setScope] = useState<string>('');
  let request = GET_SSO_OAUTH_CONFIG;
  switch (flow) {
    case GOOGLE_SSO_FLOW.AUTHORIZE:
      request = GET_SSO_OAUTH_CONFIG;
      break;
    case GOOGLE_SSO_FLOW.SIGN_IN:
      request = GET_SSO_SIGNIN_CONFIG;
      break;
    case GOOGLE_SSO_FLOW.SIGN_UP:
      request = GET_SSO_SIGNUP_CONFIG;
      break;
    default:
      request = GET_SSO_OAUTH_CONFIG;
      break;
  }

  const onRequestSuccess = useCallback((response: ApiResponseSuccess) => {
    const { data } = response;
    setClientId(data.clientId);
    setScope(data.oauthScopes.join(' '));
  }, []);

  const { fireRequest, isLoading } = useRequest(request, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    onSuccess: onRequestSuccess,
    initialLoadingState: true,
  });

  useEffect(() => {
    fireRequest({
      substitutions: { sso },
    });
  }, [fireRequest, sso]);

  const handleSuccess = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (response.code) {
        onSuccess(response.code);
      }
    },
    [onSuccess],
  );

  const emptyFunction = useCallback(() => {}, []);

  if (isLoading) {
    return React.createElement(children, {
      loaded: !isLoading,
      signIn: emptyFunction,
    });
  }
  return (
    <>
      <Helmet>
        <meta name="google-signin-client_id" content={clientId} />
      </Helmet>
      <HookComponent
        clientId={clientId}
        scope={scope}
        onSuccess={handleSuccess}
        onFailure={onFailure}
      >
        {children}
      </HookComponent>
    </>
  );
};

export default GSuiteSSOController;
