import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';

import useFetchIMConfig from '../../../interactionLayer/useFetchIMConfig/useFetchIMConfig';
import useVerifyIMCode from '../../../interactionLayer/useVerifyIMCode/useVerifyIMCode';
import { SSOOptions } from '../../../Utils/data/ssoManager/common';

import {
  getSSOIDByParam,
  IdentitySSO,
} from '../../../Utils/identity/allowedSSOs';
import Body from '../../atoms/Body';
import LoadingPulse from '../../atoms/LoadingPulse';

interface IdentityManagerAuthorizeParams {
  sso: SSOOptions;
}

const IdentityManagerAuthorize = () => {
  const { sso } = useParams<IdentityManagerAuthorizeParams>();
  useVerifyIMCode(sso);
  const { models } = useFetchIMConfig(sso);
  const ssoId = getSSOIDByParam(sso);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const { displayName } = IdentitySSO[ssoId];

  const connectionBody = models.isConnectingIM
    ? `Connecting to ${displayName}`
    : `Verifying ${displayName}`;

  // Starting of Presentation Component
  // TODO: Split Dumb & Smart Components

  return (
    <Grid container justify="center" style={{ marginTop: 90 }}>
      <Body variant="body2Bold">{connectionBody}</Body>
      <LoadingPulse isLoading />
    </Grid>
  );
};

export default IdentityManagerAuthorize;
